import { forEach } from "lodash";

import { Entity, Update, Workflow, WorkflowStep } from "@api";

import { pushDirty } from "@utils/array";
import { toPointDate } from "@utils/date-fp";
import { newID, typeFromId } from "@utils/id";
import { Maybe } from "@utils/maybe";
import { now } from "@utils/now";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { toScopedRef } from "@utils/property-refs";

import { isFinished } from "./utils";

export const toFinishWorkflowUpdates = (
  workflow: Maybe<Workflow>,
  steps: Maybe<WorkflowStep[]>
): Update<Entity>[] => {
  if (!workflow) {
    return [];
  }

  const transaction = newID();

  const updates = [
    asUpdate(
      workflow,
      asMutation({ field: "status", type: "status" }, { id: "FNS" }),
      transaction
    ),
  ];

  forEach(steps, (s) => {
    if (!isFinished(s)) {
      pushDirty(
        updates,
        asUpdate(
          s,
          asMutation({ field: "status", type: "status" }, { id: "SKP" }),
          transaction
        )
      );
    }

    // Archive all tasks when completing the workflow
    forEach(s.refs?.created, (r) => {
      // Don't archive non-tasks
      if (typeFromId(r.id) !== "task") {
        return;
      }

      pushDirty(
        updates,
        asUpdate(
          toScopedRef(r, s.source.scope),
          asMutation({ field: "archivedAt", type: "date" }, toPointDate(now())),
          transaction
        )
      );
    });
  });

  return updates;
};
