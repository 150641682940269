import { isEqual, isString } from "lodash";
import { ReactNode, useCallback, useMemo, useRef } from "react";
import {
  BaseEdge,
  ConnectionLineComponentProps,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  getNodesBounds,
  getSmoothStepPath,
  getStraightPath,
  Handle as FlowHandle,
  HandleProps,
  MarkerType,
  NodeProps,
  Position,
  useStore,
} from "reactflow";

import { Entity, EntityType, ID, PropertyRef } from "@api";

import { useUpdateEntity } from "@state/generic";

import { cx } from "@utils/class-names";
import { getEdgeParams } from "@utils/flow";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import { Primitive } from "@utils/types";

import { Card } from "@ui/card";
import { Container } from "@ui/container";
import { render, useEngine } from "@ui/engine";
import { FillSpace, SpaceBetween, VStack } from "@ui/flex";
import { GoToButton } from "@ui/go-to-button";
import { Icon, PlusIcon } from "@ui/icon";
import { TextLarge } from "@ui/text";
import { WithViewingWithin } from "@ui/viewing-within";

import styles from "./styles.module.css";

export const NODE_TYPES = {
  group: GroupNode,
  "entity-type": EntityTypeNode,
  entity: EntityNode,
  "entity-card": EntityCardNode,
};
export const EDGE_TYPES = {
  step: VStepEdge,
  vstep: VStepEdge,
  hstep: HStepEdge,
  hvstep: HVStepEdge,
  floating: FloatingEdge,
};

export const DEFAULT_EDGE_OPTIONS = {
  style: { strokeWidth: 2, stroke: "var(--color-border)" },
  type: "floating",
  data: {},
  markerEnd: {
    type: MarkerType.Arrow,
    color: "var(--color-border)",
  },
};

export function EntityTypeNode({
  data: { label },
}: NodeProps<{ label: string; subTitle?: string; entity: EntityType }>) {
  return (
    <Card className={styles.entity}>
      <Handle
        type="target"
        isConnectableStart={false}
        position={Position.Top}
      />
      <VStack fit="container" gap={0}>
        <TextLarge bold>{label}</TextLarge>
      </VStack>
      <Handle
        type="source"
        isConnectableEnd={false}
        position={Position.Bottom}
      />
    </Card>
  );
}

export function EntityNode({
  data: { entity, parentId, direction },
}: NodeProps<{
  label: string;
  entity: Entity;
  parentId?: ID;
  selected?: boolean;
  direction: "horizontal" | "vertical";
}>) {
  const pushTo = useGoTo();
  const engine = useEngine(entity.source.type);

  if (!entity) {
    return <></>;
  }

  return (
    <Card className={styles.entity} onDoubleClick={() => pushTo(entity)}>
      <Handle
        type="target"
        position={direction === "horizontal" ? Position.Left : Position.Top}
      />
      <SpaceBetween>
        <FillSpace direction="horizontal">
          <WithViewingWithin scope={parentId || ""}>
            {render(engine.asMenuItem, {
              key: entity.id,
              item: entity,
              disabled: true,
              className: styles.clip,
            })}
          </WithViewingWithin>
        </FillSpace>
        <GoToButton item={entity} />
      </SpaceBetween>
      <Handle
        type="source"
        position={direction === "horizontal" ? Position.Right : Position.Bottom}
      />
    </Card>
  );
}

export function EntityCardNode({
  data: { entity, showProps, parentId, direction },
}: NodeProps<{
  label: string;
  entity: Entity;
  parentId?: ID;
  selected?: boolean;
  direction: "horizontal" | "vertical";
  showProps?: PropertyRef[];
}>) {
  const pushTo = usePushTo();
  const engine = useEngine(entity.source.type);
  const mutate = useUpdateEntity(entity.id);

  if (!entity) {
    return <></>;
  }

  return (
    <div className={styles.entityCard} onDoubleClick={() => pushTo(entity)}>
      <Handle
        className={styles.handle}
        type="target"
        position={direction === "horizontal" ? Position.Left : Position.Top}
      />
      <WithViewingWithin scope={parentId || ""}>
        {render(engine.asListCard, {
          key: entity.id,
          item: entity,
          onChange: mutate,
          showProps: showProps,
          className: styles.clip,
        })}
      </WithViewingWithin>
      <Handle
        type="source"
        position={direction === "horizontal" ? Position.Right : Position.Bottom}
      />
    </div>
  );
}

export function FloatingEdge({
  id,
  label,
  source,
  target,
  markerEnd,
  markerStart,
}: EdgeProps) {
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerStart={markerStart}
        markerEnd={markerEnd}
        style={{
          strokeWidth: "2px",
          stroke: "var(--color-border)",
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(round(-50%, 1px), round(-50%, 1px)) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
          className={cx("nodrag", "nopan", styles.label)}
        >
          {label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export function VStepEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  selected,
  data,
  markerEnd,
  markerStart,
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX: sourceX,
    sourceY: sourceY,
    targetX: targetX,
    targetY: targetY,
    borderRadius: 20,
    sourcePosition: Position.Bottom,
    targetPosition: Position.Top,
  });

  const color = useEdgeColor(data, selected);

  return (
    <>
      <defs>
        <marker
          id={`${id}-edge`}
          viewBox="0 0 14 8"
          refX="7"
          refY="5"
          markerWidth="7"
          markerHeight="4"
          fill="none"
        >
          <path
            d="M1.27034 1.27042L6.99992 7L12.7295 1.27042"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </marker>
      </defs>

      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={data.edge !== false ? `url(#${id}-edge)` : undefined}
        style={{ strokeWidth: "2px", stroke: color }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(round(-50%, 1px), round(-50%, 1px)) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
          className={cx("nodrag", "nopan", styles.label)}
        >
          {label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export function HStepEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  markerEnd,
  markerStart,
}: EdgeProps) {
  const [edgePath, labelX, labelY] =
    Math.abs(sourceY - targetY) < 10
      ? getStraightPath({
          sourceX: sourceX,
          sourceY: targetY, // Make them look straight
          targetY: targetY, // Make them look straight
          targetX: targetX,
        })
      : getSmoothStepPath({
          sourceX: sourceX,
          sourceY: sourceY,
          targetX: targetX,
          targetY: targetY,
          borderRadius: 20,
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
        });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerStart={markerStart}
        markerEnd={markerEnd}
        style={{
          strokeWidth: "2px",
          stroke: "var(--color-border)",
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(round(-50%, 1px), round(-50%, 1px)) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
          className={cx("nodrag", "nopan", styles.label)}
        >
          {label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export function HVStepEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  selected,
  data,
  markerEnd,
  markerStart,
}: EdgeProps) {
  // Calculate the path with a horizontal segment first, then vertical

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX: sourceX,
    sourceY: sourceY,
    targetX: targetX,
    targetY: targetY,
    borderRadius: 20,
    sourcePosition: Position.Right,
    targetPosition: Position.Top,
  });

  const color = useEdgeColor(data, selected);

  return (
    <>
      <defs>
        <marker
          id={`${id}-edge`}
          viewBox="0 0 14 8"
          refX="7"
          refY="5"
          markerWidth="7"
          markerHeight="4"
          fill="none"
        >
          <path
            d="M1.27034 1.27042L6.99992 7L12.7295 1.27042"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </marker>
      </defs>

      <BaseEdge
        id={id}
        path={edgePath}
        markerStart={markerStart}
        markerEnd={`url(#${id}-edge)`}
        style={{
          strokeWidth: "2px",
          stroke: color,
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
          className={cx("nodrag", "nopan", styles.label)}
        >
          {label || data.label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export function GroupNode({ id, data }: NodeProps) {
  const PADDING = 10;
  const headerRef = useRef<HTMLParagraphElement>(null);
  const { minWidth, minHeight } = useStore((store) => {
    const parent = store.nodeInternals.get(id);
    const childNodes = Array.from(store.nodeInternals.values()).filter(
      (n) => n.parentNode === id
    );
    const rect = getNodesBounds(childNodes);

    if (!childNodes.length) {
      return { minHeight: 20, minWidth: 20, hasChildNodes: false };
    }

    return {
      minWidth: rect.x - (parent?.position.x || 0) + rect.width + PADDING * 2,
      minHeight: rect.y - (parent?.position.y || 0) + rect.height + PADDING * 2,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  return (
    <Container
      padding="none"
      className={styles.group}
      style={{
        minWidth: Math.max(minWidth, 200),
        minHeight: Math.max(minHeight, 100),
      }}
    >
      <Container
        padding="none"
        className={styles.outline}
        style={{
          top: `-${PADDING + (headerRef?.current?.offsetHeight || 0)}px`,
          left: `-${PADDING}px`,
          /* Offset the padding required for groups to grow */
          width: `calc(100% + 10px - ${PADDING}px)`,
          height: `calc(100% + 10px - ${PADDING}px + ${
            headerRef?.current?.offsetHeight || 0
          }px)`,
        }}
      >
        <Handle type="target" position={Position.Top} />
        <div ref={headerRef}>
          <TextLarge bold subtle className={styles.heading}>
            {data.name || data.label || data.title || "Group"}
          </TextLarge>
        </div>
      </Container>

      {/* <Handle
        className={styles.handle}
        type="source"
        position={Position.Bottom}
      /> */}
    </Container>
  );
}

export const InvisibleHandle = ({
  className,
  ...rest
}: HandleProps & { className?: string; children?: ReactNode }) => (
  <FlowHandle className={cx(styles.invisibleHandle, className)} {...rest} />
);

export const Handle = ({
  className,
  ...rest
}: HandleProps & { className?: string; children?: ReactNode }) => (
  <FlowHandle
    className={cx(styles.handle, styles[rest.position], className)}
    {...rest}
  />
);

export const ButtonHandle = ({
  className,
  onClick,
  ...rest
}: HandleProps & {
  className?: string;
  onClick?: Fn<React.MouseEvent, void>;
}) => (
  <div onClick={onClick}>
    <FlowHandle
      className={cx(styles.addButton, styles[rest.position], className)}
      {...rest}
    >
      <Icon icon={PlusIcon} />
    </FlowHandle>
  </div>
);

export function useEdgeColor(
  data: Maybe<Record<string, Primitive>>,
  selected: Maybe<boolean>
) {
  return useMemo(() => {
    const color = data?.color;
    if (selected) {
      return "var(--color-primary)";
    }

    if (color && isString(color)) {
      return `var(--color-user-${color.replace("_", "-")})`;
    }

    if (data?.inverse) {
      return "var(--color-text)";
    }

    return "var(--color-border)";
  }, [selected, data?.color]);
}

export function LiveConnectingLine({
  fromX,
  fromY,
  toX,
  toY,
  fromPosition,
  toPosition,
}: ConnectionLineComponentProps) {
  const [edgePath] = getSmoothStepPath({
    sourceX: fromX,
    sourceY: fromY,
    targetX: toX,
    targetY: toY,
    borderRadius: 20,
    sourcePosition: fromPosition,
    targetPosition: toPosition,
  });
  return (
    <g>
      <path
        fill="none"
        stroke="var(--color-border)"
        strokeWidth="2px"
        className="animated"
        d={edgePath}
      />
      <circle
        cx={toX}
        cy={toY}
        fill="#fff"
        r={6}
        stroke="var(--color-border)"
        strokeWidth="4px"
      />
    </g>
  );
}
