import { ReactFlowProvider } from "reactflow";

export function withFlowProvider<T>(Component: React.FC<T>) {
  return (props: T) => (
    <ReactFlowProvider>
      {/* @ts-ignore */}
      <Component {...props} />
    </ReactFlowProvider>
  );
}
