import * as api from "@api";
import { Job } from "@api";

import { cachedFuncByWorkspace } from "@state/workspace";

import { Fn } from "@utils/fn";
import { toMilliSeconds } from "@utils/time";

export const getJobQueueLoader = cachedFuncByWorkspace(
  () =>
    async (onceCallback?: Fn<Job[], void>): Promise<Job[]> => {
      const res = await api.getJobQueue();
      onceCallback?.(res);
      return res;
    },
  toMilliSeconds("500 milliseconds"),
  ([_cb]) => "global"
);
