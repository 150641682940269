import { find, flatMap, map } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { HasRefs, hasRefs, ID, Ref, Workflow } from "@api";

import { useLazyEntities, useLazyEntity } from "@state/generic";
import { useRelatedEntities } from "@state/settings";
import { useShowRunning } from "@state/workflow";

import { Fn } from "@utils/fn";
import { useShowMore } from "@utils/hooks";
import { minus } from "@utils/math";
import { safeAs, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { uniqRefs, withoutRef } from "@utils/relation-ref";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { render, useEngine } from "@ui/engine";
import { HStack, VStack } from "@ui/flex";
import { CheckIcon, Play } from "@ui/icon";
import { Label } from "@ui/label";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { EmptyMenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { PaneHeader } from "@ui/pane-header";
import { useSyncPaneCount } from "@ui/pane-manager";
import { Sheet } from "@ui/sheet-layout";
import { RunWorkflowModal } from "@ui/workflow-run-modal";

import styles from "./secondary-pane.module.css";

interface Props {
  id: ID;
  onOpen?: Fn<Workflow, void>;
}

export const RelatedWorkflowsPane = ({ id, onOpen }: Props) => {
  const goTo = useGoTo();
  const [includeRelated, setIncludeRelated] = useState(true);
  const [starting, setStarting] = useState(false);
  const entity = useLazyEntity(id);
  const related = useRelatedEntities(includeRelated ? id : undefined);
  const allWorkflowRefs = useMemo(
    () =>
      withoutRef(
        includeRelated
          ? uniqRefs(
              flatMap(
                related,
                (e) => safeAs<HasRefs>(e)?.refs?.fromWorkflow || []
              )
            )
          : safeAs<HasRefs>(entity)?.refs?.fromWorkflow || [],
        { id }
      ),
    [includeRelated, safeAs<HasRefs>(entity)?.refs, related]
  );
  const allWorkflows = useLazyEntities<"workflow">(allWorkflowRefs || []);
  const workflows = useShowRunning(allWorkflows);
  const engine = useEngine("workflow");

  const onSelected = useCallback(
    (r: Ref) => when(find(allWorkflows, { id: r.id }), onOpen || goTo),
    [onOpen]
  );

  // Sync the count with the pane manager
  useSyncPaneCount(allWorkflows?.length);

  return (
    <Sheet size="secondary" height="content" className={styles.pane}>
      <PaneHeader title="Workflows">
        <Label
          subtle
          fit="content"
          iconRight={<CheckIcon checked={includeRelated} />}
          onClick={() => setIncludeRelated(!includeRelated)}
        >
          Include related
        </Label>
      </PaneHeader>

      {starting && entity && (
        <RunWorkflowModal entity={entity} onClose={() => setStarting(false)} />
      )}

      <Container stack="vertical" gap={20} className={styles.paneContainer}>
        <Menu>
          <MenuGroup>
            <VStack>
              {!!workflows.visible?.length &&
                map(workflows.visible, (r) =>
                  render(engine.asListCard, {
                    key: r.id,
                    item: r,
                    onOpen: onSelected,
                  })
                )}

              {workflows.hasMore && (
                <ShowMoreMenuItem
                  count={workflows.moreCount}
                  onClick={() => workflows.setShowAll(!workflows.showAll)}
                  text={workflows.showAll ? "Hide completed" : "Show completed"}
                />
              )}

              {!allWorkflows?.length && (
                <EmptyMenuItem>
                  No running workflows
                  {includeRelated && " here or on all related work"}.
                </EmptyMenuItem>
              )}
            </VStack>
          </MenuGroup>
        </Menu>
      </Container>

      <Container stack="horizontal" className={styles.paneActions} inset="left">
        <Button
          size="small"
          subtle
          icon={Play}
          onClick={() => setStarting(true)}
        >
          Start Workflow
        </Button>
      </Container>
    </Sheet>
  );
};
