import { atomFamily } from "recoil";

import { ID, Sprint } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type SprintStoreState = StoreState<Sprint>;

export const WorkspaceSprintStoreAtom = atomFamily<SprintStoreState, ID>({
  key: "WorkspaceSprintStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "sprint" },
  effects: (wid) => [indexedDBStorageForStore(wid, "sprint")],
});

export const SprintStoreAtom = WorkspaceWrappedAtom(
  "SprintStoreAtom",
  WorkspaceSprintStoreAtom
);
