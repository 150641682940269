import { find, groupBy, map, orderBy, take, without } from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import {
  Entity,
  hasArchivedAt,
  hasCode,
  HasTemplate,
  isConvertable,
  isTeam,
  PropertyDef,
  PropertyType,
  PropertyValue,
  Status,
} from "@api";

import {
  AppCommandsAtom,
  AppPageAtom,
  editPropertyInCmdK,
  setCommandsAction,
  useDelete,
} from "@state/app";
import { useGetEditableProperties } from "@state/databases";
import { useGetItemFromAnyStore, useQueueUpdates } from "@state/generic";
import { useActionWorkflows, useRunWorkflowState } from "@state/workflows";

import { use } from "@utils/fn";
import { isTemplateId } from "@utils/id";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { asMutation, asUpdate } from "@utils/property-mutations";
import {
  asPropertyValueRef,
  toFieldName,
  toKey,
  toLabel,
} from "@utils/property-refs";
import { ComponentOrNode } from "@utils/react";
import {
  isMultiSelected,
  isSelected,
  SelectionState,
  selectOne,
  selectOnly,
  SetSelectionState,
} from "@utils/selectable";
import { toArray } from "@utils/set";

import { useAppPageContext } from "@ui/app-page";
import { copyCodes, copyLinks, copyMarkdown } from "@ui/clipboard";
import {
  ContextDivider,
  ContextItem,
  ContextMenu,
  ContextSubMenu,
} from "@ui/context-menu";
import {
  Archive,
  ArchiveOpen,
  ArrowUpRight,
  BoltAlt,
  CopyIcon,
  EllipsisH,
  Home,
  LinkAlt,
  MarkdownIcon,
  NumberIcon,
  RelationIcon,
  StatusConvert,
  StatusIcon,
  TrashAlt,
} from "@ui/icon";
import { showSuccess } from "@ui/notifications";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { useSuggestedActions } from "@ui/suggested-actions";
import { useSuggestedProps } from "@ui/suggested-props";
import { Tag } from "@ui/tag";
import { WorkflowCollectDialog } from "@ui/workflow-collect-dialog";

interface Props {
  entity: Entity;
  selection?: SelectionState;
  setSelection?: SetSelectionState;
  children: ReactNode;
}

const canMove = (entity: Entity) =>
  entity.source.type !== "view" && !(entity as Maybe<HasTemplate>)?.template;

const canConvert = (entity: Entity) => isConvertable(entity.source.type);

const canDelete = (entity: Entity) => !isTemplateId(entity.id);

export const EntityContextActions = ({
  entity,
  selection,
  setSelection,
}: Omit<Props, "children">) => {
  const pageId = useAppPageContext();
  const goTo = useGoTo();
  const getItem = useGetItemFromAnyStore();
  const multiSelection = (selection?.selected?.size || 0) > 1;
  const setPage = useSetRecoilState(AppPageAtom(pageId || ""));
  const setAppCommands = useSetRecoilState(AppCommandsAtom);
  const allProps = useGetEditableProperties(
    entity.source,
    (p) => !["text", "rich_text"].includes(p.type)
  );
  const suggestedProps = useSuggestedProps();
  const suggestedContextActions = useSuggestedActions();
  const onDelete = useDelete(entity, selection);
  const { fields, relations } = useMemo(
    () =>
      groupBy(
        orderBy(
          allProps, // Preferred group by field types, sorts left to right here
          (p) =>
            ["assigned", "owner"]?.includes(p.field)
              ? `0`
              : `${[
                  "status",
                  "multi_select",
                  "select",
                  "date",
                  "checklist",
                  "relation",
                  "relations",
                ].indexOf(p.type)}.${p.order}`,
          "asc"
        ),
        (p) => (p.options?.hierarchy === "parent" ? "relations" : "fields")
      ),
    [allProps]
  );

  const recommendedEditables = useMemo(() => {
    const suggestedFields = map(suggestedProps, (s) => s.field);
    const { suggested, rest } = groupBy(fields, (p) =>
      suggestedFields.includes(p.field) ? "suggested" : "rest"
    );
    return take([...(suggested || []), ...(rest || [])], 3);
  }, [fields, suggestedProps]);

  const visible = useMemo(
    () => without(fields, ...recommendedEditables),
    [fields, recommendedEditables]
  );
  // const detatchParent = useDetatchSubtask(pageId);
  // const { run: autoFillProps } = useAutoFillTaskProperties(task?.id);
  const mutate = useQueueUpdates(pageId);

  const onDuplicate = useCallback(() => {
    setAppCommands(
      setCommandsAction(
        "duplicate",
        true,
        !selection || !isSelected(selection, entity.id) ? entity : undefined
      )
    );
  }, [entity, selection]);

  const onArchive = useCallback(() => {
    setAppCommands(
      setCommandsAction(
        "archive",
        true,
        !selection || !isSelected(selection, entity.id) ? entity : undefined
      )
    );
  }, [entity, selection]);

  const onUnarchive = useCallback(() => {
    setAppCommands(
      setCommandsAction(
        "unarchive",
        true,
        !selection || !isSelected(selection, entity.id) ? entity : undefined
      )
    );
  }, [entity, selection]);

  const onMove = useCallback(() => {
    setAppCommands(
      setCommandsAction(
        "move_location",
        true,
        !selection || !isSelected(selection, entity.id) ? entity : undefined
      )
    );
  }, [entity, selection]);

  const onConvert = useCallback(() => {
    setAppCommands(
      setCommandsAction(
        "convert_work",
        true,
        !selection || !isSelected(selection, entity.id) ? entity : undefined
      )
    );
  }, [entity, selection]);

  const onEditProperty = useCallback(
    <T extends PropertyType>(
      p: PropertyDef<Entity, T>,
      value?: PropertyValue[T]
    ) => {
      const isMulti = selection && isSelected(selection, entity.id);
      if (value && !isMulti) {
        mutate(asUpdate(entity, [asMutation(p, value)]));
      } else if (value && isMulti) {
        const changes = [asMutation(p, value)];
        mutate(
          maybeMap(toArray(selection.selected), (id) =>
            when(getItem(id), (e) => asUpdate(e, changes))
          )
        );
      } else if (isMulti) {
        setAppCommands(editPropertyInCmdK(p));
      } else {
        setSelection?.(selectOnly(entity.id));
        setAppCommands(
          editPropertyInCmdK(p, {
            id: entity.id,
            source: entity.source,
          })
        );
      }
    },
    [entity, selection, setSelection, setPage]
  );

  return (
    <>
      {!multiSelection && !!suggestedContextActions?.length && (
        <>
          {map(suggestedContextActions, (item) => (
            <ContextItem
              key={item.id}
              icon={item.icon}
              onClick={() => item.onClick(entity)}
            >
              {item.label}
            </ContextItem>
          ))}

          <ContextDivider />
        </>
      )}

      <ContextItem
        icon={ArrowUpRight}
        onClick={() => goTo(entity, {}, { newTab: true })}
      >
        Open in new tab
      </ContextItem>
      <ContextSubMenu text="Copy..." icon={LinkAlt}>
        <ContextItem
          icon={LinkAlt}
          onClick={() => {
            copyLinks([entity]);
            showSuccess("Copied to clipboard.");
          }}
        >
          Copy link
        </ContextItem>
        {hasCode(entity) && (
          <ContextItem
            icon={NumberIcon}
            onClick={() => {
              copyCodes([entity]);
              showSuccess("Copied to clipboard.");
            }}
          >
            Copy code
          </ContextItem>
        )}
        <ContextItem
          icon={MarkdownIcon}
          onClick={() => {
            copyMarkdown([entity]);
            showSuccess("Copied to clipboard.");
          }}
        >
          Copy markdown
        </ContextItem>
      </ContextSubMenu>

      <ContextDivider />

      {entity && !isMultiSelected(selection) && (
        <WorkflowContextItem
          entity={entity}
          selection={selection}
          setSelection={setSelection}
        />
      )}

      {canMove(entity) && (
        <ContextItem icon={Home} onClick={onMove}>
          Move to new location...
        </ContextItem>
      )}

      {canConvert(entity) && (
        <ContextItem icon={StatusConvert} onClick={onConvert}>
          Convert to...
        </ContextItem>
      )}

      <ContextDivider />
      {map(recommendedEditables, (p) => (
        <EditPropertyContextItem
          key={p.field}
          prop={p}
          onEdit={onEditProperty}
        />
      ))}

      {!!visible?.length && (
        <ContextSubMenu text="More fields" icon={EllipsisH}>
          {map(visible, (p) => (
            <EditPropertyContextItem
              key={p.field}
              prop={p}
              onEdit={onEditProperty}
            />
          ))}
        </ContextSubMenu>
      )}

      {!!relations?.length && (
        <ContextSubMenu text="Add to..." icon={RelationIcon}>
          {map(relations, (p) => (
            <EditPropertyContextItem
              key={p.field}
              prop={p}
              icon={RelationIcon}
              onEdit={onEditProperty}
            />
          ))}
        </ContextSubMenu>
      )}

      <ContextDivider />
      {!isTeam(entity) && !multiSelection && (
        <ContextItem icon={CopyIcon} onClick={onDuplicate}>
          Duplicate
        </ContextItem>
      )}
      {hasArchivedAt(entity) && !entity.archivedAt && (
        <>
          <ContextDivider />
          <ContextItem icon={Archive} onClick={onArchive}>
            Archive
          </ContextItem>
        </>
      )}
      {hasArchivedAt(entity) && !!entity.archivedAt && (
        <>
          <ContextDivider />
          <ContextItem icon={ArchiveOpen} onClick={onUnarchive}>
            Restore from archives
          </ContextItem>
        </>
      )}
      <ContextDivider />
      {canDelete(entity) && (
        <ContextItem icon={TrashAlt} onClick={onDelete}>
          Delete
        </ContextItem>
      )}
    </>
  );
};

export const SimpleContextActions = ({
  entity,
  selection,
}: Omit<Props, "children">) => {
  const goTo = useGoTo();
  const onDelete = useDelete(entity, selection);

  return (
    <>
      <ContextItem icon={ArrowUpRight} onClick={() => goTo(entity)}>
        Open
      </ContextItem>

      <ContextDivider />

      <ContextItem icon={TrashAlt} onClick={onDelete}>
        Delete
      </ContextItem>
    </>
  );
};

export const EntityContextMenu = ({ children, entity, ...props }: Props) => {
  const pageId = useAppPageContext();
  const [page, setPage] = useRecoilState(AppPageAtom(pageId || ""));
  const [open, setOpen] = useState(false);
  const actions = useMemo(
    () => (!open ? false : <EntityContextActions entity={entity} {...props} />),
    [entity, props]
  );

  useEffect(() => {
    if (open && !!props.selection && !isSelected(props.selection, entity.id)) {
      props.setSelection?.(selectOne(entity.id));
    }
  }, [open]);

  return (
    <>
      <ContextMenu onOpen={setOpen} actions={actions}>
        {children}
      </ContextMenu>
    </>
  );
};

const EditPropertyContextItem = <E extends Entity, T extends PropertyType>({
  prop: p,
  icon,
  onEdit,
}: {
  prop: PropertyDef<E, T>;
  icon?: ComponentOrNode;
  onEdit: (p: PropertyDef<E, T>, value?: PropertyValue[T]) => void;
}) => {
  if (["select", "status"]?.includes(p.type)) {
    return (
      <ContextSubMenu
        text={toFieldName(p)}
        icon={<PropertyTypeIcon field={String(p.field)} type={p.type} />}
      >
        {map(p.values?.[p.type], (v) =>
          use(asPropertyValueRef(p, { [p.type]: v }), (vRef) => (
            <ContextItem
              key={String(toKey(vRef))}
              icon={
                icon ??
                (p.type === "status" ? (
                  <StatusIcon status={v as Status} />
                ) : undefined)
              }
              onClick={() => onEdit(p, v as PropertyValue[T])}
            >
              {p.type === "status" ? (
                toLabel(vRef)
              ) : (
                <Tag color={vRef?.value.select?.color}>{toLabel(vRef)}</Tag>
              )}
            </ContextItem>
          ))
        )}
      </ContextSubMenu>
    );
  }

  return (
    <ContextItem
      icon={
        icon ?? (
          <PropertyTypeIcon
            field={String(p.field)}
            type={p.type}
            options={p.options}
          />
        )
      }
      onClick={() => onEdit(p)}
    >
      {toFieldName(p)}
    </ContextItem>
  );
};

type WorkflowContextItemProps = Omit<Props, "children">;

const WorkflowContextItem = ({
  entity,
  selection,
}: WorkflowContextItemProps) => {
  const [workflowActions, actionData] = useActionWorkflows(entity);
  const {
    run: onRun,
    collecting,
    cancelCollecting,
    context,
  } = useRunWorkflowState(entity.source.type, actionData);

  const primaryAction = useMemo(
    () => find(workflowActions, (a) => !a.collect),
    [workflowActions]
  );

  return (
    <>
      {collecting && actionData && context && (
        <WorkflowCollectDialog
          action={collecting}
          context={context}
          data={actionData}
          source={entity.source}
          onCollected={onRun}
          onCancel={cancelCollecting}
        />
      )}

      {primaryAction && (
        <ContextItem
          icon={primaryAction.icon || BoltAlt}
          onClick={() => onRun(primaryAction)}
        >
          {primaryAction.title}
        </ContextItem>
      )}
    </>
  );
};
