import { isArray, isFunction, map } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { DatabaseID, Entity, PropertyValueRef, Update } from "@api";

import {
  WorkflowAction,
  WorkflowContext,
  WorkflowData,
  WorkflowSuggestion,
} from "@state/workflows";

import { replace } from "@utils/array";
import { asValue } from "@utils/property-refs";

import { Button } from "@ui/button";
import { Dialog } from "@ui/dialog";
import { FormField } from "@ui/engine/form/fields";

import { VStack } from "./flex";

type Props<
  T extends Entity,
  A extends WorkflowAction<T> | WorkflowSuggestion<T>
> = {
  action: A;
  context: WorkflowContext<T>;
  source: DatabaseID;
  onCollected: (action: A, collected: PropertyValueRef<T>[]) => void;
  onCancel: () => void;
} & (A extends WorkflowSuggestion<T>
  ? { data: WorkflowData<T> & { update: Update<T> } }
  : { data: WorkflowData<T> });

export const WorkflowCollectDialog = <
  T extends Entity,
  A extends WorkflowAction<T> | WorkflowSuggestion<T>
>({
  action,
  data,
  source,
  context,
  onCollected,
  onCancel,
}: Props<T, A>) => {
  const [values, setValues] = useState<PropertyValueRef<T>[]>([]);
  const toCollect = useMemo(
    () => (isArray(action.collect) ? action.collect : []),
    [action]
  );
  const CustomComponent = useMemo(
    () => (isFunction(action.collect) ? action.collect : undefined),
    [action]
  );

  useEffect(() => {
    if (isFunction(action.collect)) {
      return;
    }

    if (!action.collect?.length) {
      onCollected?.(action, []);
    } else {
      setValues(
        map(toCollect, (r) => ({ ...r, value: { [r.type]: undefined } }))
      );
    }
  }, [action.collect]);

  if (CustomComponent) {
    return (
      <CustomComponent
        data={data as WorkflowData<T> & { update: Update<T> }}
        context={context}
        onCollected={(vs) => onCollected(action, vs)}
        onCancelled={onCancel}
      />
    );
  }

  return (
    <CollectDialog
      title={action.title}
      description={action.description}
      collect={values}
      source={source}
      onCollected={(vs) => onCollected(action, vs)}
      onCancel={onCancel}
    />
  );
};

type CollectProps<T extends Entity> = {
  title?: string;
  description?: string;
  submitLabel?: string;
  collect: PropertyValueRef<T>[];
  source: DatabaseID;
  onCollected: (collected: PropertyValueRef<T>[]) => void;
  onCancel: () => void;
  onClose?: () => void;
};

export const CollectDialog = <T extends Entity = Entity>({
  title,
  description,
  collect,
  source,
  onCollected,
  onCancel,
  onClose = onCancel,
  submitLabel,
}: CollectProps<T>) => {
  const [submitted, setSubmitted] = useState(false);
  const [values, setValues] = useState<PropertyValueRef<T>[]>(collect);

  useEffect(() => {
    setValues(collect);
  }, [collect?.length]);

  return (
    <Dialog
      onDismiss={onClose}
      title={title || "Information needed"}
      description={description}
      actions={
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            variant="primary"
            onClick={() => {
              onCollected(values);
              setSubmitted(true);
            }}
            loading={submitted}
          >
            {submitLabel || "Done"}
          </Button>
        </>
      }
    >
      <VStack gap={20}>
        {map(values, (value, i) => (
          <FormField
            key={String(value.field)}
            def={value.def}
            prop={value as PropertyValueRef}
            scope={source.scope}
            value={value.value}
            onChange={(change) => {
              const newValue = { ...value, value: asValue(value.type, change) };
              setValues(replace(values, i, newValue));
            }}
          />
        ))}
      </VStack>
    </Dialog>
  );
};
