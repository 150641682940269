import { Task } from "@api";

import { UIEngine } from "../types";
import { TaskListCard } from "./list-card";
import { TaskListItem } from "./list-item";
import { TaskMenuItem } from "./menu-item";
import { TaskPane } from "./pane";

export const TaskEngine: UIEngine<Task> = {
  asMenuItem: TaskMenuItem,
  asListItem: TaskListItem,
  asListCard: TaskListCard,
  asPrimaryPane: TaskPane,
};
