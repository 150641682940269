import { filter, find, map } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { PropertyType, VariableDef, Vars } from "@api";

import { ensureMany } from "@utils/array";
import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { isAnyRelation, toPropertyTypeLabel } from "@utils/property-refs";
import { withoutStar } from "@utils/wildcards";

import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { Dialog } from "@ui/dialog";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { TrashAlt } from "@ui/icon";
import { Field, TextInput } from "@ui/input";
import { AddMenuItem } from "@ui/menu-item";
import { useMutate } from "@ui/mutate";
import { showError } from "@ui/notifications";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { Select } from "@ui/select";
import { EntityTypeMultiSelect } from "@ui/select/entity-type";
import { Tag } from "@ui/tag";
import { TextMedium, TextSmall } from "@ui/text";

import styles from "./variable-list.module.css";

export const VariableList = ({
  variables,
  scope,
  onChanged,
}: {
  variables: Maybe<Vars>;
  scope: string;
  onChanged: Fn<Vars, void>;
}) => {
  const mutate = useMutate();
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState<VariableDef>();

  return (
    <VStack>
      {map(variables, (v) => (
        <ContextMenu
          key={v.field}
          actions={
            <ContextItem
              icon={TrashAlt}
              text="Delete"
              onClick={() => onChanged(filter(variables || [], (i) => i !== v))}
            />
          }
        >
          <Card onClick={() => setEditing(v)}>
            <SpaceBetween>
              <HStack align="baseline" gap={4}>
                <TextMedium>{v.label}</TextMedium>
                <TextSmall subtle>{`{${v.field}}`}</TextSmall>
              </HStack>
              <Tag>{v.type}</Tag>
            </SpaceBetween>
            <TextSmall subtle>{v.description}</TextSmall>
          </Card>
        </ContextMenu>
      ))}

      <AddMenuItem
        title="Add Variable"
        subtle
        onClick={() => setAdding(true)}
      />

      {adding && (
        <EditVariableModal
          scope={scope}
          onSaved={(v) => onChanged([...(variables || []), v])}
          onClose={() => setAdding(false)}
        />
      )}

      {editing && (
        <EditVariableModal
          variable={editing}
          scope={scope}
          onSaved={(v) =>
            onChanged(map(variables, (i) => (i === editing ? v : i)))
          }
          onClose={() => setEditing(undefined)}
        />
      )}
    </VStack>
  );
};

const VARIABLE_PROP_TYPES: PropertyType[] = [
  // "select",
  // "multi_select",
  // "status",
  "text",
  "rich_text",
  "number",
  "date",
  "boolean",
  "relation",
  "relations",
];

export const EditVariableModal = ({
  variable: _variable,
  scope,
  onSaved,
  onClose,
}: {
  variable?: VariableDef;
  scope: string;
  onSaved: Fn<VariableDef, void>;
  onClose: Fn<void, void>;
}) => {
  const [variable, setVariable] = useState<VariableDef>(
    () =>
      _variable || {
        field: "",
        type: "relation",
        value: {},
        options: { references: [] },
      }
  );
  const handleChange = useCallback((v: Partial<VariableDef>) => {
    setVariable((prev) => ({ ...prev, ...v }));
  }, []);
  const available = useMemo(
    () =>
      map(VARIABLE_PROP_TYPES, (t) => ({
        id: t,
        name: toPropertyTypeLabel(t),
      })),
    []
  );
  const selectedType = useMemo(
    () => find(available, { id: variable.type }),
    [variable.type]
  );

  const handleAdd = useCallback(() => {
    if (!variable.field || !variable.type) {
      showError("Handle and type is required.");
      return;
    }

    onSaved?.(variable);
    onClose?.();
  }, [variable]);

  return (
    <Dialog
      autoPosition={true}
      title={!!_variable ? "Add variable" : "Edit variable"}
      onDismiss={() => onClose?.()}
      actions={
        <>
          <Button onClick={() => onClose?.()}>Cancel</Button>
          <Button variant="primary" onClick={handleAdd}>
            Save Variable
          </Button>
        </>
      }
    >
      <VStack gap={20}>
        <SpaceBetween gap={6}>
          <Field label="Name">
            <TextInput
              value={variable.label || ""}
              onChange={(n) => {
                if (!variable.field) {
                  handleChange({
                    label: n,
                    field: n.toLowerCase().replace(/\s/g, "_"),
                  });
                } else {
                  handleChange({ label: n });
                }
              }}
              updateOn="blur"
              autoFocus
            />
          </Field>
          <Field label="Handle">
            <TextInput
              value={`@` + variable.field}
              onChange={(v) => handleChange({ field: v.replace(/^@/, "") })}
              updateOn="change"
            />
          </Field>
        </SpaceBetween>

        <Field label="Help Text">
          <TextInput
            value={variable.description || ""}
            placeholder="What this variable is for..."
            onChange={(v) => handleChange({ description: v })}
            updateOn="change"
          />
        </Field>

        <Field label="Type">
          <Select
            className={{ trigger: styles.control }}
            searchable={false}
            options={available}
            value={selectedType}
            portal={true}
            toIcon={(v) => <PropertyTypeIcon type={v.id as PropertyType} />}
            onChange={(v) => v && handleChange({ type: v.id })}
          />
        </Field>

        {isAnyRelation(variable) && (
          <Field label="Allowed Work Types">
            <EntityTypeMultiSelect
              className={{ trigger: styles.control }}
              value={when(variable.options?.references, (r) =>
                ensureMany(withoutStar(r))
              )}
              additional={["person", "team"]}
              scope={scope}
              onChange={(v) =>
                handleChange({
                  options: { references: v },
                })
              }
            />
          </Field>
        )}
      </VStack>
    </Dialog>
  );
};
