import { filter, find, first, flatMap, some, uniqBy } from "lodash";
import { useEffect } from "react";

import {
  CreateOrUpdate,
  Entity,
  VariableDef,
  Workflow,
  WorkflowStep,
} from "@api";

import { isCreateOrUpdate } from "@state/store";
import {
  WorkflowAction,
  WorkflowDefinition,
  WorkflowDefinitionConfig,
  WorkflowTrigger,
} from "@state/workflows";

import { ensureArray } from "@utils/array";
import { isPersonId } from "@utils/id";
import { equalsAny } from "@utils/logic";
import { safeAs } from "@utils/maybe";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import { toStamp } from "@utils/stamp";

import { ArchiveDialog } from "@ui/archive-dialog";
import { Archive, StopCircle } from "@ui/icon";
import { useMutate } from "@ui/mutate";

import { toFinishWorkflowUpdates } from "./actions";
import { useWorkflowSteps } from "./effects";

export const inputsToWorkflowFollowers: WorkflowDefinition<Workflow> = {
  id: "inputsToWorkflowFollowers",
  trigger: "WILL_UPDATE",
  type: "workflow",

  // Is updating the owner on step
  allowed: ({ entity, update }, context) =>
    isCreateOrUpdate(update) &&
    some(
      update.changes,
      (c) =>
        c.field === "inputs" &&
        some(
          safeAs<VariableDef[]>(c.value?.json),
          (v) =>
            isPersonId(v.value?.relation?.id) ||
            some(v.value?.relations, (r) => isPersonId(r.id))
        )
    ),

  execute: ({ entity, update }) => {
    const persons = flatMap(safeAs<CreateOrUpdate>(update)?.changes, (c) =>
      c.field === "inputs"
        ? flatMap(safeAs<VariableDef[]>(c.value?.json), (v) =>
            filter(v.value?.relations || ensureArray(v.value?.relation), (r) =>
              isPersonId(r.id)
            )
          )
        : []
    );

    if (!persons.length) {
      return [];
    }

    return [
      asUpdate(
        entity,
        asAppendMutation(
          { field: "refs.followers", type: "relations" },
          uniqBy(persons, (p) => p.id)
        )
      ),
    ];
  },
};

export const workflowStepsAutoFollowWorkflows: WorkflowDefinition<WorkflowStep> =
  {
    id: "workflowStepsAutoFollowWorkflows",
    trigger: "WILL_UPDATE",
    type: "workflow_step",

    // Is updating the owner on step
    allowed: ({ entity, update }, context) =>
      isCreateOrUpdate(update) &&
      some(update.changes, (c) => c.field === "owner"),

    execute: ({ entity, update }) => {
      const workflowRef = first(entity.refs.workflow);
      const owner = find(
        safeAs<CreateOrUpdate>(update)?.changes,
        (c) => c.field === "owner"
      )?.value?.relation;

      if (!workflowRef || !owner) {
        return [];
      }

      return [
        asUpdate(
          {
            id: workflowRef?.id,
            source: { type: "workflow", scope: entity.source.scope },
          },
          asAppendMutation({ field: "refs.followers", type: "relations" }, [
            owner,
          ])
        ),
      ];
    },
  };

export const markWorkflowAsDirty: WorkflowDefinition<WorkflowStep> = {
  id: "markWorkflowAsDirty",
  trigger: "WILL_UPDATE",
  type: "workflow_step",

  // Whenever a step is updated, mark the workflow as dirty
  allowed: ({ entity, update }, context) =>
    !entity.template &&
    isCreateOrUpdate(update) &&
    some(
      update.changes,
      (c) =>
        c.field === "status" && equalsAny(c.value.status?.id, ["FNS", "SKP"])
    ),

  execute: ({ entity, update }, context) => {
    const workflowRef = first(entity.refs.workflow);

    if (!workflowRef) {
      return [];
    }

    return [
      asUpdate(
        {
          id: workflowRef?.id,
          source: { type: "workflow", scope: entity.source.scope },
        },
        asMutation(
          { field: "stamps.dirty", type: "stamp" },
          toStamp(context.session.user)
        )
      ),
    ];
  },
};

export const endWorkflow: WorkflowDefinition<Workflow> = {
  id: "endWorkflow",
  trigger: "ACTION",
  type: "workflow",
  icon: StopCircle,
  title: "End Workflow",
  variant: "danger",

  allowed: ({ entity }, _context) =>
    !entity.template && entity?.status?.group === "in-progress",

  collect: ({ data: { entity: workflow }, onCollected, onCancelled }) => {
    const steps = useWorkflowSteps(workflow);
    const mutate = useMutate();

    useEffect(() => {
      if (!workflow?.refs?.steps?.length) {
        onCollected([]);
      }

      if (!steps?.length) {
        return;
      }

      mutate(toFinishWorkflowUpdates(workflow, steps));

      onCollected([]);
    }, [steps]);

    return <></>;
  },

  execute: ({ entity, collected }, context) => [],
};

export const archiveWorkflow: WorkflowDefinition<Workflow> = {
  id: "archiveWorkflow",
  trigger: "ACTION",
  type: "workflow",
  icon: Archive,
  title: "Archive",

  allowed: ({ entity }, _context) =>
    entity?.status?.group === "done" && !entity.archivedAt,

  collect: ({ data: { entity: workflow }, onCollected, onCancelled }) =>
    workflow && (
      <ArchiveDialog
        targets={[workflow]}
        onCancel={onCancelled}
        onComplete={() => onCollected([])}
      />
    ),
  execute: ({ entity, collected }, {}) => [],
};

export const definitions: WorkflowDefinitionConfig<Entity> = {
  triggers: [
    inputsToWorkflowFollowers,
    workflowStepsAutoFollowWorkflows,
    markWorkflowAsDirty,
  ] as WorkflowTrigger<Entity>[],
  suggestions: [],
  actions: [endWorkflow, archiveWorkflow] as WorkflowAction<Entity>[],
};

export default definitions;
