import {
  Dialog,
  DialogClose,
  DialogContent,
  Portal,
} from "@radix-ui/react-dialog";
import {
  CSSProperties,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { cx } from "@utils/class-names";
import { useShortcut } from "@utils/event";
import { Fn } from "@utils/fn";
import { useClickAway } from "@utils/hooks";
import { Maybe } from "@utils/maybe";
import { useSelectableIgnoreClicks } from "@utils/selectable";

import { NoSelectable } from "@ui/selectable-items";

import { Button } from "./button";
import { Container, Props as ContainerProps } from "./container";
import { DragContext, Draggable, DragMoveEvent } from "./draggable";
import { TimesIcon } from "./icon";

import styles from "./modal.module.css";

export const ModalClose = () => (
  <DialogClose asChild>
    <Button variant="icon-only" aria-label="Close" icon={TimesIcon} />
  </DialogClose>
);

export type Props = {
  open?: boolean;
  mode?: "blocking" | "passive";
  size?: "primary" | "secondary";
  autoPosition?: boolean;
  onOpenChanged?: Fn<boolean, void>;
  onAutoFocus?: (event: Event) => void;
  closeOnEscape?: boolean;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
} & Omit<ContainerProps, "size">;

export const Modal = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      mode = "blocking",
      style,
      size,
      className,
      autoPosition = false,
      open,
      closeOnEscape = false,
      onOpenChanged,
      onAutoFocus,
      ...rest
    },
    _ref
  ) => {
    const localRef = useRef<HTMLDivElement>(null);
    const [transform, setTransform] = useState<
      Maybe<{
        curr: { x: number; y: number };
        delta: { x: number; y: number };
      }>
    >(() => {
      if (!autoPosition) {
        return;
      }

      const lastHover = [...document.querySelectorAll(":hover")].pop();
      if (lastHover) {
        const rect = lastHover.getBoundingClientRect();
        // Set the delta to be the distance from the center of the screen to the center of the hovered element
        const x = (rect.left + rect.width / 2 - window.innerWidth / 2) * 0.5;
        const y = (rect.top + rect.height / 2 - window.innerHeight / 2) * 0.5;

        return {
          curr: { x, y },
          delta: { x: 0, y: 0 },
        };
      }
    });

    const ref = (_ref || localRef) as React.MutableRefObject<HTMLDivElement>;
    const [entering, setEntering] = useState(true);
    useShortcut("Escape", () => onOpenChanged?.(false), [onOpenChanged]);
    const selectableProps = useSelectableIgnoreClicks();

    const css = useMemo(
      () =>
        transform
          ? {
              ...style,
              transition: "transform 0.1s var(--snappy-bezier)",
              transform: `translate(round(calc(50% + ${Math.round(
                transform.curr.x + transform.delta.x
              )}px), 1px), round(calc(50% + ${Math.round(
                transform.curr.y + transform.delta.y
              )}px), 1px))`,
              transformOrigin: "top center",
            }
          : style,
      [style, transform]
    );

    // Update delta only
    const handleDragMove = useCallback(
      (e: DragMoveEvent) =>
        setTransform((t) => ({
          curr: t?.curr || { x: 0, y: 0 },
          delta: e.delta,
        })),
      []
    );

    // Save current position + delta
    const handleDragEnd = useCallback(
      () =>
        setTransform(
          (t) =>
            t && {
              curr: {
                x: t.curr.x + t.delta.x,
                y: t.curr.y + t.delta.y,
              },
              delta: { x: 0, y: 0 },
            }
        ),
      []
    );

    useShortcut(
      "Escape",
      [() => closeOnEscape, () => onOpenChanged?.(false)],
      [onOpenChanged, closeOnEscape]
    );

    useEffect(() => {
      setTimeout(() => setEntering(false), 300);
    }, []);

    useClickAway(
      ref,
      () => onOpenChanged?.(false),
      open && mode === "blocking",
      [onOpenChanged]
    );

    return (
      <Dialog open={open} modal={false}>
        <Portal data-ignore-auto-close>
          <DialogContent
            ref={ref}
            style={css}
            {...selectableProps}
            onOpenAutoFocus={onAutoFocus}
            className={cx(
              styles.container,
              size && styles[size],
              entering && !autoPosition && styles.entering,
              mode === "blocking" ? styles.center : styles.bottomRight,
              className
            )}
          >
            <NoSelectable>
              <DragContext
                distance={0}
                onDragMove={handleDragMove}
                onDragEnd={handleDragEnd}
              >
                {mode === "blocking" && (
                  <Draggable id={"dialog-move"}>
                    <div
                      className={cx(styles.grip, transform && styles.visible)}
                    />
                  </Draggable>
                )}
                <Container {...rest}>{children}</Container>
              </DragContext>
            </NoSelectable>
          </DialogContent>
        </Portal>
      </Dialog>
    );
  }
);
