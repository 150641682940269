import * as Progress from "@radix-ui/react-progress";
import { first, map, reduce, slice } from "lodash";
import { useMemo } from "react";

import { Color } from "@api";

import { ensureArray, OneOrMany } from "@utils/array";
import { cx } from "@utils/class-names";
import { fromColor, toColorVar } from "@utils/color";

import { SpaceBetween, VStack } from "@ui/flex";

import { TextSmall } from "./text";

import styles from "./progress-bar.module.css";

interface Props {
  percent: OneOrMany<number>;
  color?: Color;
  thickness?: number;
  showPercents?: boolean;
  label?: OneOrMany<string>;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const ProgressBar = ({
  label,
  onClick,
  thickness,
  color = "green",
  showPercents = true,
  percent,
  className,
}: Props) => {
  const [base, shade] = useMemo(() => fromColor(color), [color]);
  // Order by descending so that we render from largest -> smallest in z axis
  const percents = useMemo(() => ensureArray(percent), [percent]);
  const labels = useMemo(() => ensureArray(label), [label]);

  return (
    <VStack gap={2} fit="container" onClick={onClick} className={className}>
      <SpaceBetween>
        {map(labels, (l, i) => (
          <TextSmall
            key={l}
            subtle
            className={styles.text}
            style={{
              width: labels?.length === 1 ? "100%" : `${percents[i]}%`,
            }}
          >
            {l}
            {showPercents ? ` (${Math.round(percents[i])}%)` : ""}
          </TextSmall>
        ))}
      </SpaceBetween>

      <Progress.Root
        className={cx(styles.root)}
        value={first(percents)}
        style={{ height: thickness }}
      >
        {map(percents, (progress, i) => {
          const runningTotal = reduce(
            slice(percents, 0, i),
            (a, b) => a + b,
            0
          );

          return (
            <Progress.Indicator
              key={i}
              className={cx(
                styles.indicator,
                // Don't use right border on the last indicator when going to 100
                runningTotal + progress > 98 && styles.clipped
              )}
              // Stack all indicators ontop of each other
              style={{
                width: `${progress}%`,
                background: toColorVar(base, Math.min(i, 5)),
                transform: `translate(${(runningTotal / progress) * 100}%, -${
                  i * 100
                }%)`,
              }}
            />
          );
        })}
      </Progress.Root>
    </VStack>
  );
};

export default ProgressBar;
