import { ReactNode } from "react";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";

import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Modal, Props as ModalProps } from "@ui/modal";
import { Text, TextXLarge } from "@ui/text";

import { Button } from "./button";
import { TimesIcon } from "./icon";

import styles from "./dialog.module.css";

interface Props {
  title: string;
  description?: string;
  actions: ReactNode;
  autoPosition?: boolean;
  onDismiss?: Fn<void, void>;
  onAutoFocus?: ModalProps["onAutoFocus"];
  children?: ReactNode;
}

export const Dialog = ({
  children,
  title,
  description,
  autoPosition,
  onAutoFocus,
  actions,
  onDismiss,
}: Props) => {
  return (
    <Modal
      autoPosition={autoPosition}
      open={true}
      onOpenChanged={(open) => !open && onDismiss?.()}
      className={cx(styles.dialog, styles.fullWidth)}
      onAutoFocus={onAutoFocus}
    >
      <HStack className={styles.container}>
        <VStack fit="container">
          <VStack gap={4} fit="container">
            <SpaceBetween>
              <TextXLarge bold={true}>{title}</TextXLarge>
              <HStack gap={0}>
                <Button subtle icon={TimesIcon} onClick={() => onDismiss?.()} />
              </HStack>
            </SpaceBetween>
            {description && <Text subtle>{description}</Text>}
          </VStack>
          <div className={cx(styles.content, styles.fullWidth)}>{children}</div>
          <HStack gap={12} justify="flex-end" className={cx(styles.fullWidth)}>
            {actions}
          </HStack>
        </VStack>
      </HStack>
    </Modal>
  );
};
