import { Outcome } from "@api";

import { UIEngine } from "../types";
import { OutcomeListCard } from "./list-card";
import { OutcomeListItem } from "./list-item";
import { OutcomeMenuItem } from "./menu-item";
import { OutcomePane } from "./pane";

export { OutcomePane } from "./pane";

export const OutcomeEngine: UIEngine<Outcome> = {
  asMenuItem: OutcomeMenuItem,
  asListItem: OutcomeListItem,
  asListCard: OutcomeListCard,
  asPrimaryPane: OutcomePane,
};
