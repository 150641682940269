import { atomFamily } from "recoil";

import { ID, Page } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type PageStoreState = StoreState<Page>;

export const WorkspacePageStoreAtom = atomFamily<PageStoreState, ID>({
  key: "WorkspacePageStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "page" },
  effects: (wid) => [indexedDBStorageForStore(wid, "page")],
});

export const PageStoreAtom = WorkspaceWrappedAtom(
  "PageStoreAtom",
  WorkspacePageStoreAtom
);
