import { atomFamily } from "recoil";

import { ID, Workflow, WorkflowStep } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type WorkflowStoreState = StoreState<Workflow>;

export const WorkspaceWorkflowStoreAtom = atomFamily<WorkflowStoreState, ID>({
  key: "WorkspaceWorkflowStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "workflow" },
  effects: (wid) => [indexedDBStorageForStore(wid, "workflow")],
});

export const WorkflowStoreAtom = WorkspaceWrappedAtom(
  "WorkflowStoreAtom",
  WorkspaceWorkflowStoreAtom
);

export type WorkflowStepStoreState = StoreState<WorkflowStep>;

export const WorkspaceWorkflowStepStoreAtom = atomFamily<
  WorkflowStepStoreState,
  ID
>({
  key: "WorkspaceWorkflowStepStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "workflow_step" },
  effects: (wid) => [indexedDBStorageForStore(wid, "workflow_step")],
});

export const WorkflowStepStoreAtom = WorkspaceWrappedAtom(
  "WorkflowStepStoreAtom",
  WorkspaceWorkflowStepStoreAtom
);
