import * as dagre from "dagre";
import { flatMap, groupBy, map, reduce } from "lodash";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  applyEdgeChanges,
  applyNodeChanges,
  Edge,
  EdgeChange,
  EdgeTypes,
  FitViewOptions,
  Node,
  NodeChange,
  NodeTypes,
  Position,
  useReactFlow,
  XYPosition,
} from "reactflow";

import { pushDirty } from "./array";
import { Fn, isFunc } from "./fn";
import { Maybe, maybeMap } from "./maybe";

const rand = () => Math.round(Math.random() * 400);

export interface FlowLayoutOptions {
  orientation?: "horizontal" | "vertical";
  onPosition?: (id: string, position: XYPosition) => void;
}

export type LayoutStrategy<N = any, E = N> = (
  nodes: Node<N>[],
  edges: Edge<E>[],
  opts?: FlowLayoutOptions
) => { nodes: Node<N>[]; edges: Edge<E>[] };

export type FlowDefinition<T, N, E> = {
  layout: LayoutStrategy<N, E>;
  toNode: Fn<T, Maybe<Omit<Node<N>, "position">>>;
  toEdges: Fn<T, Edge<E>[]>;
  fitView?: FitViewOptions;
  nodeTypes?: NodeTypes;
  edgeTypes?: EdgeTypes;
};

export const randomLayout = (): LayoutStrategy => (nodes, edges, opts) => ({
  nodes: map(nodes, (n) => ({ ...n, position: { x: rand(), y: rand() } })),
  edges: map(edges, (e) => e),
});

export const treeLayout =
  (
    sizes: { width: number; height: number; hSpace?: number; vSpace?: number },
    opts: FlowLayoutOptions
  ): LayoutStrategy =>
  (nodes, edges) => {
    const parentGroup = groupBy(nodes, (n) => n.parentNode);
    const parents = Object.keys(parentGroup);

    const allNodes = flatMap(parents, (p) => {
      const inner = parentGroup[p];
      const dagreGraph = new dagre.graphlib.Graph({
        compound: true,
        directed: true,
        multigraph: true,
      });
      dagreGraph.setDefaultEdgeLabel(() => ({}));

      const isHorizontal = opts?.orientation !== "vertical";

      dagreGraph.setGraph({
        rankdir: isHorizontal ? "LR" : "TB",
        ranksep: 10 * (sizes.vSpace || 1),
        nodesep: 10 * (sizes.hSpace || 1),
      });

      // Find the parent node if it exists and has a custom position
      const parentNode = inner.find((node) => node.id === p);
      const parentPosition = parentNode?.position;

      inner.forEach((node) => {
        // If the node is the parent and has a position, set it explicitly
        if (node.id === p && parentPosition) {
          dagreGraph.setNode(node.id, {
            width: sizes.width,
            height: sizes.height,
            x: parentPosition.x + sizes.width / 2,
            y: parentPosition.y + sizes.height / 2,
          });
        }
        // For other nodes, set up normally
        else if (node.position) {
          dagreGraph.setNode(node.id, {
            width: sizes.width,
            height: sizes.height,
            x: node.position.x + sizes.width / 2,
            y: node.position.y + sizes.height / 2,
          });
        } else {
          dagreGraph.setNode(node.id, {
            width: sizes.width,
            height: sizes.height,
          });
        }
      });

      // Set up edges, using parent position as reference if exists
      edges.forEach((edge) => {
        // Ensure the edge is within this group of nodes
        if (
          inner.some((node) => node.id === edge.source) &&
          inner.some((node) => node.id === edge.target)
        ) {
          dagreGraph.setEdge(edge.source, edge.target);
        }
      });

      dagre.layout(dagreGraph);

      return map(inner, (node) => {
        // If node already has a position, return it unchanged
        if (node.position && node.id !== p) {
          return node;
        }

        const ogPosition = dagreGraph.node(node.id);
        const position = {
          // We are shifting the dagre node position (anchor=center center) to the top left
          // so it matches the React Flow node anchor point (top left).
          x: Math.floor(ogPosition.x - sizes.width / 2),
          y: Math.floor(ogPosition.y - sizes.height / 2),
        };

        opts.onPosition?.(node?.id, position);

        return {
          ...node,
          targetPosition: isHorizontal ? Position.Left : Position.Top,
          sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
          position,
        };
      });
    });

    return {
      nodes: allNodes,
      edges,
    };
  };

export type ReactFlowState<N, E = N> = { nodes: Node<N>[]; edges: Edge<E>[] };

export function useLayoutStrategy<T>(strategy: LayoutStrategy) {
  const [cachedPositions, setCachedPositions] =
    useState<Record<string, Position>>();

  const caluclatePositions = useCallback(
    (nodes: Omit<Node, "position">[], edges: Edge[]) => {
      return { nodes: nodes as Node[], edges };
    },
    [cachedPositions, strategy]
  );

  const toPosition = useCallback(
    (item: T) => ({ x: 0, y: 0 }),
    [cachedPositions]
  );
  const withPositions = useCallback(
    ({ nodes, edges }: { nodes: Omit<Node, "position">[]; edges: Edge[] }) =>
      caluclatePositions(nodes, edges),
    [cachedPositions]
  );

  return { withPositions, toPosition };
}

// Old approach tro layout strategies (gross)
export function useLayoutStrategyDeprecated<T>(
  strategy: LayoutStrategy,
  _data?: ReactFlowState<T>
) {
  const { fitView } = useReactFlow();
  const [data, _setData] = useState<ReactFlowState<T>>(
    () =>
      _data || {
        nodes: [],
        edges: [],
      }
  );

  const setData = useCallback<Dispatch<SetStateAction<ReactFlowState<T>>>>(
    (newV) => {
      _setData((data) => {
        const selected = maybeMap(data.nodes, (n) =>
          n.selected ? n.id : undefined
        );
        const newData = isFunc(newV) ? newV(data) : newV;

        const { nodes, edges } = strategy(
          newData.nodes || data.nodes,
          newData.edges || data.edges
        );

        setTimeout(
          () => fitView({ duration: 100, padding: 0.1, maxZoom: 1 }),
          200
        );

        return {
          nodes: map(nodes, (n) => ({
            ...n,
            selected: n.selected || selected.includes(n.id),
          })),
          edges: edges,
        };
      });
    },
    [_setData, fitView, strategy]
  );

  const onNodesChange = useCallback(
    (changes: NodeChange[]) =>
      _setData((d) => ({ ...d, nodes: applyNodeChanges(changes, d.nodes) })),
    [_setData]
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) =>
      _setData((d) => ({ ...d, edges: applyEdgeChanges(changes, d.edges) })),
    [_setData]
  );

  useEffect(() => {
    if (_data) {
      setData(_data);
    }
  }, [_data]);

  return {
    nodes: data.nodes,
    edges: data.edges,
    setData,
    onNodesChange,
    onEdgesChange,
  };
}

export const useFlowData = <T, N, E>(
  items: Maybe<T[]>,
  strategy: FlowDefinition<T, N, E>
) => {
  const { fitView } = useReactFlow();
  const [data, setData] = useState<ReactFlowState<N, E>>(() => ({
    nodes: [],
    edges: [],
  }));

  const generateData = useCallback(
    (items: Maybe<T[]>) => {
      setData(() => {
        const { nodes, edges } = reduce(
          items,
          (acc, item) => {
            const node = strategy.toNode(item);
            const edges = strategy.toEdges(item);

            if (node) {
              pushDirty(acc.nodes, node);
            }
            pushDirty(acc.edges, ...edges);

            return acc;
          },
          { nodes: [], edges: [] }
        );

        return strategy.layout(nodes, edges);
      });
    },
    [setData, fitView, strategy]
  );

  const fit = useCallback(() => {
    fitView(strategy.fitView || { duration: 100, padding: 0.1, maxZoom: 1 });
  }, [fitView, strategy.fitView]);

  const onNodesChange = useCallback(
    (changes: NodeChange[]) =>
      setData((d) => ({ ...d, nodes: applyNodeChanges(changes, d.nodes) })),
    [setData]
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) =>
      setData((d) => ({ ...d, edges: applyEdgeChanges(changes, d.edges) })),
    [setData]
  );

  useEffect(() => generateData(items), [items, generateData]);

  useLayoutEffect(() => {
    setTimeout(fit, 50);
    setTimeout(fit, 200);
  }, [!!data?.nodes?.length]);

  return {
    nodes: data.nodes,
    edges: data.edges,
    fitView: !!strategy.fitView,
    fitViewOptions: strategy.fitView
      ? { ...strategy.fitView, duration: 0 }
      : undefined,
    toPosition: () => ({ x: 0, y: 0 }),
    onNodesChange,
    onEdgesChange,
  };
};

export const toFlowDefinition = <T, N, E>(
  def: FlowDefinition<T, N, E>
): FlowDefinition<T, N, E> => def;
