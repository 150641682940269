import { CSSProperties, useCallback, useMemo, useState } from "react";

import { RichText } from "@api";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { useDebouncedCallback } from "@utils/hooks";
import { Maybe } from "@utils/maybe";
import { toMarkdown } from "@utils/rich-text";

import { PlainText, ReadonlyPlainText } from "./rich-text";

import styles from "./editable-text.module.css";

interface Props {
  text: Maybe<string>;
  placeholder?: string;
  className?: string;
  onChange?: (curr: string, old: string) => void;
  updateOn?: "change" | "blur";
  textSize?: "default" | "small" | "medium";
  highlight?: boolean;
  allowNewLines?: boolean;
  selectOnFocus?: boolean;
  clearOnEscape?: boolean;
  blurOnEnter?: boolean;
  subtle?: boolean;
  disabled?: boolean;
  autoFocus?: boolean | undefined;
  style?: CSSProperties;
  onFocus?: Fn<void, void>;
  onBlur?: Fn<Maybe<string>, void>;
}

export function EditableText({
  text: defaultText,
  placeholder,
  updateOn = "change",
  textSize = "default",
  allowNewLines,
  subtle,
  highlight = true,
  autoFocus = false,
  disabled = false,
  className,
  onBlur,
  onChange,
}: Props) {
  const original = useMemo(() => ({ markdown: defaultText }), [defaultText]);
  const [editing, setEditing] = useState(autoFocus);

  const debouncedChange = useDebouncedCallback(
    (newText: string, oldText: string) => onChange?.(newText, oldText),
    800
  );

  const handleChanged = useCallback(
    (rt: RichText) => {
      if (updateOn === "change") {
        debouncedChange(toMarkdown(rt), defaultText || "");
      }
    },
    [onChange, original, debouncedChange]
  );

  const handleBlurred = useCallback(
    (rt: RichText) => {
      const text = toMarkdown(rt);
      if (updateOn === "blur") {
        onChange?.(text, defaultText || "");
      }
      // setEditing(false);
      onBlur?.(text);
    },
    [onChange, onBlur]
  );

  if (disabled || !editing) {
    return (
      <ReadonlyPlainText
        key="readonly-plain-text"
        text={original}
        onClick={() => setEditing(true)}
        placeholder={placeholder}
        className={cx(
          styles.editable,
          !allowNewLines && styles.singleLine,
          highlight && styles.highlight,
          subtle && styles.subtle,
          textSize && styles[textSize],
          disabled && styles.disabled,
          className
        )}
      />
    );
  }

  return (
    <PlainText
      key="editable-plain-text"
      updateOn={updateOn}
      focus={true}
      // disabled={disabled}
      className={cx(
        styles.editable,
        !allowNewLines && styles.singleLine,
        subtle && styles.subtle,
        highlight && styles.highlight,
        textSize && styles[textSize],
        className,
        disabled && styles.disabled
      )}
      // style={style}
      placeholder={placeholder}
      text={original}
      onFocus={() => setEditing(true)}
      onChanged={handleChanged}
      onBlur={handleBlurred}
    />
  );
}
