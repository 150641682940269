import { flatMap, values } from "lodash";

import {
  PropertyMutation,
  PropertyType,
  PropertyValue,
  VariableDef,
  Vars,
  Workflow,
  WorkflowStep,
} from "@api";

import { maybeLookup, maybeMap } from "@utils/array";
import { formatHuman } from "@utils/date";
import { useISODate } from "@utils/date-fp";
import { Fn, isFunc } from "@utils/fn";
import { extractVarReference, isFormula } from "@utils/formula";
import { equalsAny, switchEnum } from "@utils/logic";
import { Maybe } from "@utils/maybe";
import { asRelationValue } from "@utils/property-refs";
import { replaceVariables } from "@utils/variables";

export const isFinished = (step: WorkflowStep | Workflow) =>
  equalsAny(step.status?.id, ["FNS", "SKP", "ERR"]);

export const toVariables = (
  workflow: Maybe<Workflow>,
  steps?: Maybe<WorkflowStep>[]
) => [
  ...(workflow?.inputs || []),
  ...flatMap(steps, (step) => step?.outputs || []),
];

export const evalFormula = (
  formula: string,
  asType: PropertyType,
  vars: Vars | Fn<string, Maybe<VariableDef>>
) => {
  const getter = isFunc(vars) ? vars : maybeLookup(vars, (v) => v.field);
  const handle = extractVarReference(formula);
  const value = getter(handle)?.value;

  // TODO: Need to validate that the formula returns the right thing (date/ref/number/etc)
  return switchEnum<PropertyType, Maybe<PropertyValue>>(asType, {
    relation: () =>
      asRelationValue("relation", value?.relation || value?.relations),
    relations: () =>
      asRelationValue("relations", value?.relations || value?.relation),

    // Soft type coercion
    text: () => ({ text: String(values(value)[0]) }),

    // Must match expected type
    else: () => undefined,
  });
};

export const evalFormulas = (overrides: PropertyMutation[], vars: Vars) => {
  const getVar = maybeLookup(vars, (v) => v.field);
  return maybeMap(overrides, (o) => {
    const { formula } = o.value;
    if (!!formula && isFormula(formula)) {
      // TODO: Need to validate that the formula returns the right thing (date/ref/number/etc)
      return {
        ...o,
        value: evalFormula(formula, o.type, getVar) || {
          [o.type]: getVar(extractVarReference(formula))?.value?.[o.type],
        },
      };
    }

    if (o.type === "rich_text") {
      return {
        ...o,
        value: {
          rich_text: replaceVariables(o.value.rich_text, vars),
        },
      };
    }

    return o;
  });
};

export const toStepMessage = (step: WorkflowStep, running?: boolean) => {
  if (step.status?.id === "RUN" || running) {
    return switchEnum(step.action || "", {
      create: () => "Creating work...",
      update: () => "Updating work...",
      ai: () => "Asking the AI overlords...",
      message: () => "Sending messages...",
      condition: () => "Analyzing results...",
      control: () => "Optimizing paths...",
      run_workflow: () => "Running other workflows...",
      wait: () => "Waiting...",
      else: () => "Optimizing work...",
    });
  }

  if (isFinished(step)) {
    return switchEnum(step.action || "", {
      create: () =>
        `Created work at ${useISODate(step.createdAt, formatHuman)}`,
      else: () => "Ran successfully.",
    });
  }

  return switchEnum(step.action || "", {
    else: () => "Not started.",
  });
};
