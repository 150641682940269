import { atomFamily } from "recoil";

import { Form, ID, PropertyType, PropertyValue } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { SafeRecord } from "@utils/types";

export type FormData = SafeRecord<string, PropertyValue[PropertyType]>;

export type FormStoreState = StoreState<Form>;

export const WorkspaceFormStoreAtom = atomFamily<FormStoreState, ID>({
  key: "WorkspaceFormStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "form" },
  effects: (wid) => [indexedDBStorageForStore(wid, "form")],
});

export const FormStoreAtom = WorkspaceWrappedAtom(
  "FormStoreAtom",
  WorkspaceFormStoreAtom
);
