import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { useMemo } from "react";

import { HasTimestamps } from "@api";

import * as epch from "@utils/epoch-date";
import * as iso from "@utils/iso-date";
import { when } from "@utils/maybe";
import { now } from "@utils/now";

export const useRunningTime = (item: HasTimestamps) => {
  return useMemo(() => {
    // TODO: Convert code-base to us new iso-date from old date-fp
    const started = iso.toJSDate(item.createdAt as iso.ISODate, "utc");
    const ended = when(item.stamps?.status_done?.at, epch.toJSDate) || now();
    const mins = differenceInMinutes(ended, started);
    return {
      mins: mins,
      hours: Math.floor(mins / 60),
      days: differenceInDays(ended, started),
    };
  }, [item.stamps]);
};
