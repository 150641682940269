import { atomFamily, selectorFamily } from "recoil";

import { ID, Resource } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  setItemPure,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type { Resource };
export type ResourceStoreState = StoreState<Resource>;

export const WorkspaceResourceStoreAtom = atomFamily<ResourceStoreState, ID>({
  key: "WorkspaceResourceStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "resource" },
  effects: (wid) => [indexedDBStorageForStore(wid, "resource")],
});

export const ResourceStoreAtom = WorkspaceWrappedAtom(
  "ResourceStoreAtom",
  WorkspaceResourceStoreAtom
);

export const ResourceAtom = selectorFamily({
  key: "ResourceAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(ResourceStoreAtom), id) || undefined,
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(ResourceStoreAtom, setItemPure(newValue));
      }
    },
});
