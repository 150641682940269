import { addMinutes, startOfDay } from "date-fns";
import { filter, last } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Entity,
  EntityType,
  ID,
  isWorkflow,
  Meeting,
  Period,
  PropertyMutation,
  Schedule,
} from "@api";

import {
  CreateTemplateOpts,
  useCreateEntity,
  useCreateFromObject,
  useCreateFromTemplate,
  useEntitySource,
  useLazyEntity,
} from "@state/generic";
import { useSetting } from "@state/settings";
import { useActiveWorkspaceId } from "@state/workspace";

import { formatShort, formatYear } from "@utils/date";
import {
  convertToPointDate,
  now,
  toCalDate,
  useCalDate,
  useISODate,
} from "@utils/date-fp";
import { debug } from "@utils/debug";
import { Fn } from "@utils/fn";
import { typeFromId } from "@utils/id";
import { equalsAny } from "@utils/logic";
import { Maybe, required, safeAs, when } from "@utils/maybe";
import {
  asMutation,
  asUpdate,
  flattenChanges,
} from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { toLocation } from "@utils/scope";
import { toISOTime, toTime } from "@utils/time";
import { asTimezone } from "@utils/timezone";

import { useMutate } from "@ui/mutate";

import { toDayOfPeriod, toNextDate } from "./utils";
import { useStartWorkflow } from "@state/workflow";

export const useScheduleEntityType = (schedule: Maybe<Schedule>) => {
  return useMemo(
    () =>
      schedule?.entity ||
      when(
        schedule?.useTemplate?.id || last(schedule?.instances)?.id,
        typeFromId<EntityType>
      ),
    [schedule?.id, schedule?.entity, schedule?.useTemplate?.id]
  );
};

export const useCreateNextForSchedule = (
  scheduleId: ID,
  onCompleted?: Fn<void, void>
) => {
  const mutate = useMutate();

  const schedule = useLazyEntity<"schedule">(scheduleId);
  const childType = useScheduleEntityType(schedule);
  const childSource = useEntitySource(childType, schedule?.source);
  const fallbackTimezone = useSetting<string>(scheduleId, "settings.timezone");
  const template = useLazyEntity(schedule?.useTemplate?.id);
  const createNew = useCreateEntity(
    childType || "task",
    childSource?.scope || schedule?.source.scope || ""
  );

  // Next date to create for
  const next = useMemo(() => {
    if (!schedule || (schedule.useTemplate?.id && !template)) {
      return undefined;
    }

    return schedule.next || toNextDate(schedule);
  }, [schedule, template]);

  const instanceDate = useMemo(() => {
    if (!next) {
      return undefined;
    }

    const needsPointDate = equalsAny(childType, ["meeting", "event", "deal"]);

    if (!needsPointDate) {
      return next;
    }

    const timezone = schedule?.timeZone || fallbackTimezone || "UTC";

    // Hackies thing ever. This needs to be cleaned up big time :'(
    // Need to store dates in the DB as ISODates to prevent any weird shit happening
    return convertToPointDate(
      useISODate(next, (d) => asTimezone(d, timezone)),
      "local"
    );
  }, [next, schedule?.timeZone]);

  // Lol refactor this whole thing, it's a mess
  const localOpts = useMemo((): Maybe<CreateTemplateOpts> => {
    if (!instanceDate) {
      return undefined;
    }

    return {
      defaults: {
        // For now since subtasks have no way of referencing future dates
        // Can remove in the future when relative dates in templates is supported
        "*": { end: instanceDate },
      },
      overrides: {
        // The thing itself
        [schedule?.useTemplate?.id || template?.id || "*"]: flattenChanges([
          ...(childType === "content"
            ? [asMutation({ field: "publish", type: "date" }, instanceDate)]
            : childType === "meeting"
            ? [
                asMutation({ field: "start", type: "date" }, instanceDate),
                asMutation(
                  { field: "end", type: "date" },
                  useISODate(instanceDate, (d) =>
                    addMinutes(d, safeAs<Meeting>(template)?.duration || 60)
                  )
                ),
              ]
            : [
                asMutation({ field: "start", type: "date" }, instanceDate),
                asMutation({ field: "end", type: "date" }, instanceDate),
              ]),

          // Blank out schedules field which is used for the template only
          asMutation({ field: "refs.schedules", type: "relations" }, []),

          // Replace with the repeat field which is used for instances
          asMutation(
            { field: "refs.repeat", type: "relation" },
            toRef(schedule)
          ),

          ...((schedule?.overrides as Maybe<PropertyMutation[]>) || []),
        ] as PropertyMutation<Entity>[]),
      },
    };
  }, [
    next,
    schedule?.id,
    template?.id,
    schedule?.useTemplate?.id,
    schedule?.timeZone,
  ]);

  const bumpScheduleNext = useCallback(
    // Update the next date on the schedule
    () => {
      if (!schedule) {
        throw new Error("Schedule should be here.");
      }

      mutate(
        asUpdate(schedule, [
          // Update from to be the previous next (held in reference)
          asMutation({ field: "last", type: "date" }, next),

          // Pre-calculate next date for the schedule
          asMutation(
            { field: "next", type: "date" },
            toNextDate({ ...schedule, last: next })
          ),
        ])
      );

      // All done
      onCompleted?.();
    },
    [schedule?.id, next]
  );

  const createWorkflow = useStartWorkflow(schedule, bumpScheduleNext);

  const createTemplate = useCreateFromTemplate(
    childSource,
    bumpScheduleNext,
    useMemo(
      () =>
        when(localOpts, (d) => ({
          // Append the date to the schedule name
          appendName:
            !!next && childSource?.type !== "meeting"
              ? ` (${useISODate(next, (d) =>
                  schedule?.period === "year" ? formatYear(d) : formatShort(d)
                )})`
              : false,
          ...localOpts,
        })),
      [localOpts, next]
    )
  );

  const ready = useMemo(() => {
    if (!schedule) {
      return false;
    }

    // Creating from template
    if (schedule?.useTemplate?.id) {
      return (
        !!childSource &&
        !!template &&
        (isWorkflow(template) ? createWorkflow.ready : createTemplate.ready)
      );
    }

    // Creating from new
    return !!createNew && !!childSource && !!localOpts;
  }, [
    schedule,
    createTemplate.ready,
    createWorkflow.ready,
    createNew,
    childSource,
    template,
    localOpts,
  ]);

  const handleCreate = useCallback(
    (opts?: CreateTemplateOpts) => {
      if (!ready || !instanceDate) {
        debug("Not ready to create next schedule job.");
        return;
      }
      const templateId = schedule?.useTemplate?.id;
      if (templateId && isWorkflow(template)) {
        createWorkflow.start(template);
      } else if (templateId) {
        required(
          createTemplate.create,
          () => "Expecting create to be defined."
        )(
          required(template, () => "Expecting template to be defined."),
          opts
        );
      } else {
        // Create new work without a template, just applying overrides from schedule
        createNew([
          // Lol refactor this whole thing, it's a mess
          ...(childType === "content"
            ? [asMutation({ field: "publish", type: "date" }, instanceDate)]
            : childType === "meeting"
            ? [
                asMutation({ field: "start", type: "date" }, instanceDate),
                asMutation(
                  { field: "end", type: "date" },
                  useISODate(instanceDate, (d) =>
                    addMinutes(d, safeAs<Meeting>(template)?.duration || 60)
                  )
                ),
              ]
            : [
                asMutation({ field: "start", type: "date" }, instanceDate),
                asMutation({ field: "end", type: "date" }, instanceDate),
              ]),

          // Reference this schedule in the repeat field
          asMutation(
            { field: "refs.repeat", type: "relation" },
            toRef(schedule)
          ),

          // Remove any date values from overrides
          ...filter(
            (schedule?.overrides as Maybe<PropertyMutation[]>) || [],
            (c) => !equalsAny(c.field, ["start", "end", "publish"])
          ),
        ]);

        bumpScheduleNext();
      }
    },
    [ready, createTemplate.create, createNew, template, schedule, instanceDate]
  );

  return useMemo(
    () => ({
      create: handleCreate,
      ready,
      next,
      loading: createTemplate.loading,
    }),
    [handleCreate, ready, next, createTemplate.loading]
  );
};

export const useTempSchedule = (
  defaults: Maybe<Partial<Schedule>>,
  pageId?: string
) => {
  const wID = useActiveWorkspaceId();
  const defaultTZ = useSetting<string>(wID, "timezone");
  const scope = useMemo(
    () => defaults?.source?.scope || defaults?.location || wID,
    [defaults?.source?.scope]
  );
  const [scheduleId, setScheduleId] = useState<ID>();
  const schedule = useLazyEntity<"schedule">(scheduleId);

  const createTempSchedule = useCreateFromObject(
    "schedule",
    scope,
    pageId,
    true
  );

  // Create immediately
  useEffect(() => {
    if (!createTempSchedule) {
      return;
    }

    const entity = defaults?.entity || "task";
    const from = defaults?.from || now();
    const [s] =
      useCalDate(from, (fromDate) =>
        createTempSchedule?.([
          {
            period: Period.Week,
            frequency: 1,
            precreate: 1,
            timeOfDay: undefined,
            timeZone: undefined,
            name: undefined,
            useTemplate: undefined,

            // Meetings carry time
            ...(entity === "meeting"
              ? { timeOfDay: toISOTime(toTime(fromDate)), timeZone: defaultTZ }
              : {}),

            ...defaults,

            entity,
            from: toCalDate(startOfDay(fromDate), "local"),
            daysOfPeriod: [toDayOfPeriod(from, Period.Week)],
            instances: [],
            location: toLocation(scope),
            source: { type: "schedule", scope },
          },
        ])
      ) || [];

    setScheduleId(s?.id);
  }, [!!createTempSchedule]);

  return schedule;
};
