import { find, flatMap, map } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import {
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  Edge,
  MarkerType,
  Node,
} from "reactflow";

import { Entity, PropertyRef, toTitleOrName } from "@api";

import {
  useEntitySource,
  useGetItemFromAnyStore,
  useQueueUpdates,
} from "@state/generic";
import { ID } from "@state/types";
import {
  useAddToView,
  useDefaultsForView,
  useLazyGetView,
  useLazyItemsForView,
  useReorderItemsInView,
} from "@state/views";

import { maybeMap } from "@utils/array";
import { treeLayout, useLayoutStrategyDeprecated } from "@utils/chart";
import { Maybe, when } from "@utils/maybe";
import { mapAll } from "@utils/promise";
import { asAppendMutation, asUpdate } from "@utils/property-mutations";
import {
  getPropertyValue,
  toPropertyValueRef,
  toRef,
} from "@utils/property-refs";

import { AddEntityInput } from "@ui/add-entity-input";
import { usePageId } from "@ui/app-page";
import { Flow, withFlowProvider } from "@ui/flow";
import { showError } from "@ui/notifications";

import styles from "./canvas.module.css";

interface Props {
  id: ID;
  variant?: "preview" | "editable";
}

export const CanvasLayout = withFlowProvider(
  ({ id, variant = "editable" }: Props) => {
    const view = useLazyGetView(id);
    const pageId = usePageId();
    const mutate = useQueueUpdates(pageId);
    const { items } = useLazyItemsForView(id);
    const getItem = useGetItemFromAnyStore();
    const itemsSource = useEntitySource(view?.entity || "task", view?.source);
    const onAdded = useAddToView(id);
    const defaults = useDefaultsForView(id);
    const reorderItems = useReorderItemsInView(view, pageId);
    const canvasBy = useMemo(
      () =>
        view &&
        (getPropertyValue(view, {
          field: "settings.canvasBy",
          type: "property",
        })?.property as Maybe<PropertyRef<Entity, "relations">>),
      [view?.settings]
    );

    if (!view) {
      return <></>;
    }

    const strat = useMemo(
      () =>
        treeLayout(
          {
            width: 200,
            height: view.showProps?.length ? 120 : 80,
            hSpace: 5,
            vSpace: 10,
          },
          { orientation: "horizontal" }
        ),
      [view.showProps]
    );

    const { nodes, edges, setData, ...flowProps } =
      useLayoutStrategyDeprecated<{
        label: string;
        entity?: Entity;
        selected?: boolean;
      }>(strat);

    const handleConnect = useCallback(
      async (edge: Edge | Connection) => {
        if (!canvasBy) {
          return;
        }

        const from = when(edge.source, getItem);
        const to = when(edge.target, getItem);

        if (!from || !to) {
          showError("Could not find items to connect.");
          return;
        }

        reorderItems([{ entity: to.id }], {
          entity: from.id,
          position: "after",
        });

        mutate(asUpdate(to, asAppendMutation(canvasBy, [toRef(from)])));
      },
      [setData, getItem]
    );

    const handleDelete = useCallback(
      async (connection: Edge[]) => {
        if (!canvasBy) {
          return;
        }

        await mapAll(connection, async (edge) => {
          const blocker = when(edge.source, getItem);
          const blocked = when(edge.target, getItem);

          if (!blocked || !blocker) {
            showError("Could not find blockeds to disconnect.");
            return;
          }

          mutate(
            asUpdate(
              blocked,
              asAppendMutation(canvasBy, [toRef(blocker)], "remove")
            )
          );
        });
      },
      [setData, getItem]
    );

    const onNodeClicked = useCallback((event: React.MouseEvent, node: Node) => {
      if (event?.defaultPrevented) {
        return;
      }
    }, []);

    useEffect(() => {
      if (!canvasBy) {
        return;
      }

      setData({
        nodes: maybeMap(items.sorted || items.all || [], (item) => ({
          id: item.id,
          position: find(nodes, { id: item.id })?.position || { x: 0, y: 0 }, // Fallback to zero
          draggable: true,
          data: {
            label: toTitleOrName(item),
            entity: item,
            parentId: view.for?.id,
            direction: "horizontal",
            showProps: view.showProps,
          },
          type: "entity-card",
        })),

        edges: flatMap(items.sorted || items.all || [], (item) => {
          const blockedBy = toPropertyValueRef(item, canvasBy).value.relations;

          if (!item || !item.id) {
            return [];
          }

          return map(blockedBy, (blocker) => ({
            id: `${blocker.id}-${item.id}`,
            source: blocker.id,
            target: item.id,
            type: "hstep",
            data: {
              label: "",
              direction: "horizontal",
            },
            markerEnd: {
              type: MarkerType.Arrow,
              color: "var(--color-primary)",
            },
          }));
        }),
      });
    }, [items]);

    if (!view || !canvasBy) {
      return <></>;
    }

    return (
      <Flow
        nodes={nodes}
        edges={edges}
        {...flowProps}
        {...(variant === "preview" && {
          zoomOnScroll: false,
          showControls: false,
        })}
        onConnect={handleConnect}
        onEdgesDelete={handleDelete}
        onNodeClick={onNodeClicked}
      >
        {variant === "preview" && (
          <>
            <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
          </>
        )}
        {variant === "editable" && (
          <>
            <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
            <Controls showInteractive={false} />

            <AddEntityInput
              className={styles.floating}
              source={itemsSource}
              defaults={defaults}
              onAdded={onAdded}
            />
          </>
        )}
      </Flow>
    );
  }
);
