import { map, pick } from "lodash";
import { useMemo } from "react";

import {
  DatabaseID,
  FilterQuery,
  PropertyDef,
  PropertyRef,
  SingleFilterQuery,
} from "@api";

import { replace } from "@utils/array";
import { cx } from "@utils/class-names";
import {
  defaultOpForProp,
  defaultValueForFilter,
  isAnd,
  isOr,
} from "@utils/filtering";
import { composel, Fn, isFunc } from "@utils/fn";
import { equalsAny } from "@utils/logic";
import { Maybe, whenNotEmpty } from "@utils/maybe";

import { ContextItem, ContextMenu } from "@ui/context-menu";
import { PlusIcon } from "@ui/icon";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem } from "@ui/menu-item";
import PropertyFilterCheckbox from "@ui/property-filter/checkbox";
import PropertyFilterDate from "@ui/property-filter/date";
import PropertyFilterInput from "@ui/property-filter/input";
import PropertyFilterRelation from "@ui/property-filter/relation";
import PropertyFilterSelect from "@ui/property-filter/select";
import PropertyFilterStatus from "@ui/property-filter/status";
import { ScopedPropertySelect } from "@ui/select/property";
import { Text } from "@ui/text";

import styles from "./property-filter.module.css";

type Props = {
  filter: SingleFilterQuery;
  definition: PropertyDef | Fn<string, Maybe<PropertyDef>>;
  onChanged?: Fn<Maybe<SingleFilterQuery>, void>;
  source: Maybe<DatabaseID>;
  editing?: boolean;
};

type NestedProps = {
  filter: FilterQuery;
  parent?: FilterQuery;
  definition: PropertyDef | Fn<string, Maybe<PropertyDef>>;
  onChanged?: Fn<Maybe<FilterQuery>, void>;
  source: Maybe<DatabaseID>;
  editing?: boolean;
  className?: string;
};

const toNewFilter = (ref?: PropertyRef) => {
  if (!ref) {
    return;
  }

  // Calculate default op and value
  return composel(
    (ref) => ({
      ...ref,
      op: defaultOpForProp(ref),
    }),
    (f) => ({
      ...f,
      value: { [f.type]: defaultValueForFilter(f) },
    })
  )(pick(ref, "field", "type", "op"));
};

export const PropertyFilter = (props: Props) => {
  const def = useMemo(
    () =>
      isFunc(props.definition)
        ? props.definition(props.filter.field)
        : props.definition,
    [props.definition, props.filter.field]
  );

  switch (def?.type) {
    case "status":
      return <PropertyFilterStatus {...props} definition={def} />;

    case "select":
    case "multi_select":
      return <PropertyFilterSelect {...props} definition={def} />;

    case "boolean":
      return <PropertyFilterCheckbox {...props} definition={def} />;

    case "relation":
    case "relations":
      return <PropertyFilterRelation {...props} definition={def} />;

    case "date":
      return <PropertyFilterDate {...props} definition={def} />;

    case "person": {
      throw new Error("Deprecated person property type.");
    }

    default:
      return def ? <PropertyFilterInput {...props} definition={def} /> : <></>;
  }
};

export const NestedPropertyFilter = ({
  filter,
  onChanged,
  parent,
  className,
  ...props
}: NestedProps) => {
  if (isAnd(filter)) {
    return (
      <MenuGroup
        className={cx(
          !!parent && styles.group,
          !parent && styles.rootGroup,
          className
        )}
        label={!!parent || filter.and.length > 1 ? "And" : undefined}
        onLabelClicked={() => onChanged?.({ or: filter.and })}
      >
        {map(filter.and, (f, i) => (
          <NestedPropertyFilter
            key={i}
            {...props}
            filter={f}
            parent={filter}
            onChanged={(f2) =>
              onChanged?.(
                whenNotEmpty(replace(filter.and, i, f2), (and) => ({ and }))
              )
            }
          />
        ))}

        <ContextMenu
          actions={
            <ContextItem
              icon={PlusIcon}
              onClick={() => onChanged?.({ and: [...filter.and, { and: [] }] })}
            >
              <Text subtle>Add group</Text>
            </ContextItem>
          }
        >
          {props.source && (
            <ScopedPropertySelect
              type={props.source.type}
              scope={props.source.scope}
              showReadonly={true}
              portal={true}
              blacklist={(f) =>
                equalsAny(f.type, ["property", "properties", "json"])
              }
              onChanged={(f) =>
                f && onChanged?.({ and: [...filter.and, toNewFilter(f)] })
              }
            >
              <MenuItem icon={PlusIcon}>
                <Text subtle>Add filter</Text>
              </MenuItem>
            </ScopedPropertySelect>
          )}
        </ContextMenu>
      </MenuGroup>
    );
  }

  if (isOr(filter)) {
    return (
      <MenuGroup
        className={cx(
          !!parent && styles.group,
          !parent && styles.rootGroup,
          className
        )}
        label={!!parent || filter.or.length > 1 ? "Or" : undefined}
        onLabelClicked={() => onChanged?.({ and: filter.or })}
      >
        {map(filter.or, (f, i) => (
          <NestedPropertyFilter
            key={i}
            {...props}
            filter={f}
            parent={filter}
            onChanged={(f2) =>
              onChanged?.(
                whenNotEmpty(replace(filter.or, i, f2), (or) => ({ or }))
              )
            }
          />
        ))}

        <ContextMenu
          actions={
            <ContextItem
              icon={PlusIcon}
              onClick={() => onChanged?.({ or: [...filter.or, { and: [] }] })}
            >
              <Text subtle>Add group</Text>
            </ContextItem>
          }
        >
          {props.source && (
            <ScopedPropertySelect
              portal={true}
              type={props.source.type}
              scope={props.source.scope}
              blacklist={(f) =>
                equalsAny(f.type, ["property", "properties", "json"])
              }
              showReadonly={true}
              onChanged={(f) =>
                f && onChanged?.({ or: [...filter.or, toNewFilter(f)] })
              }
            >
              <MenuItem icon={PlusIcon}>
                <Text subtle>Add filter</Text>
              </MenuItem>
            </ScopedPropertySelect>
          )}
        </ContextMenu>
      </MenuGroup>
    );
  }

  return <PropertyFilter {...props} filter={filter} onChanged={onChanged} />;
};
