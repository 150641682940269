import { filter as loFilter, find, isString, map } from "lodash";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { GroupBase } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";

import {
  getChannels,
  getMessageLink,
  getThreads,
} from "@api/integrations/slack";

import { cx } from "@utils/class-names";
import { useAsyncEffect } from "@utils/effects";
import { withHardHandle } from "@utils/event";
import { isDefined, Maybe, maybeMap, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { fuzzyMatch } from "@utils/search";
import { toSlackUrl } from "@utils/slack";

import { Button } from "@ui/button";
import { Ellipsis } from "@ui/ellipsis";
import { SpaceBetween } from "@ui/flex";
import { ArrowUpRight, Hash, PlusIcon, SlackColor } from "@ui/icon";
import { Label } from "@ui/label";
import { Menu } from "@ui/menu";
import { MenuItem } from "@ui/menu-item";
import { showError } from "@ui/notifications";
import { Text, TextSmall } from "@ui/text";

import { SplitMenu } from "./comps";
import { Option, Select, SelectProps } from "./single-select";

import styles from "./select.module.css";

type SlackOption = Option & {
  type: "channel" | "thread";
  access: boolean;
};

type Props = Omit<
  SelectProps<SlackOption>,
  "value" | "options" | "onChange"
> & {
  channel: Maybe<string>;
  thread?: Maybe<string>;
  mode?: "channel" | "thread";
  label?: string;
  portal?: boolean;
  placeholder?: string;
  onChange: (channel: Maybe<string>, thread: Maybe<string>) => void;
  children?: ReactNode;
  className?: string | { select?: string; popover?: string; trigger?: string };
};

const NEW_THREAD_ID = "new";

const isFilterMatch = (filter: string, selected?: string) => (o: SlackOption) =>
  o.id === selected || o.id == NEW_THREAD_ID || fuzzyMatch(filter, o.name);

export const SlackSelect = ({
  className,
  placeholder = "Search slack",
  label,
  mode = "channel",
  channel: _channel,
  thread: _thread,
  portal = true,
  children,
  onChange,
  open: _open,
  setOpen: _setOpen,
  ...props
}: Props) => {
  const goTo = useGoTo();
  const _localOpen = useState(false);
  const [open, setOpen] = isDefined(_open || _setOpen)
    ? [_open, _setOpen]
    : _localOpen;
  const [_channels, setChannels] = useState<SlackOption[]>([]);
  const [_threads, setThreads] = useState<SlackOption[]>([]);
  const [filter, setFilter] = useState("");
  const channels = useMemo(
    () =>
      filter ? loFilter(_channels, isFilterMatch(filter, _channel)) : _channels,
    [_channels, _channel, filter]
  );
  const threads = useMemo(
    () =>
      filter ? loFilter(_threads, isFilterMatch(filter, _thread)) : _threads,
    [_threads, _thread, filter]
  );
  const channel = useMemo(
    () => find(channels, (o) => o.id === _channel),
    [_channel, channels]
  );
  const thread = useMemo(
    () => find(threads, (o) => o.id === _thread),
    [_thread, threads]
  );

  const openInSlack = useCallback(async () => {
    const url =
      _channel && _thread
        ? await getMessageLink(_channel, _thread)
        : toSlackUrl(_channel);
    when(url, goTo);
  }, [_channel, _thread]);

  const handleSelected = useCallback(
    (v: Maybe<SlackOption>) => {
      setFilter("");

      if (v?.id === NEW_THREAD_ID) {
        onChange(_channel, undefined);
        return;
      }

      if (v?.type === "channel") {
        onChange(v.id, undefined);
      } else {
        onChange(_channel, v?.id);
      }
    },
    [onChange]
  );

  useAsyncEffect(async () => {
    if (open || _channel) {
      const channels = await getChannels();
      setChannels(
        maybeMap(channels || [], (p) =>
          !p.is_archived
            ? when(p.id, (id) => ({
                id: id,
                name: p.name || p.name_normalized || "",
                type: "channel",
                access: (p.is_member || p.is_shared) ?? false,
              }))
            : undefined
        )
      );
    }
  }, [open, _channel]);

  useAsyncEffect(async () => {
    // Don't do anything if no channel selected
    if (!_channel) {
      return;
    }

    // Only fetch if open or need thread info
    if (!open && !_thread) {
      return;
    }

    try {
      const threads = await getThreads(_channel);

      // Load threads whenever selected channel changes..
      setThreads([
        {
          id: NEW_THREAD_ID,
          name: "Create new thread",
          type: "thread",
        },
        ...maybeMap(threads || [], (p) => ({
          id: p.thread_ts || p.ts,
          name: p.text?.substring(0, 50) || "",
          type: "thread",
        })),
      ] as SlackOption[]);
    } catch (err) {
      showError("Traction doesn't have access to this channel.");
      setThreads([]);
    }
  }, [open, _channel]);

  const trigger = children ?? (
    <Button
      className={cx(
        styles.button,
        isString(className) ? className : className?.trigger
      )}
      subtle
      size="small"
    >
      <SpaceBetween gap={4}>
        <Label icon={SlackColor}>
          <Text subtle={!channel}>
            #{_channel ? channel?.name || _channel : placeholder}
          </Text>
          {mode === "thread" && (
            <Text subtle={!_thread}>
              {" "}
              / {thread?.name || _thread || "Create new thread"}
            </Text>
          )}
        </Label>
        {channel && (
          <Button
            size="tiny"
            icon={ArrowUpRight}
            onClick={withHardHandle(openInSlack)}
          />
        )}
      </SpaceBetween>
      {!!label && <TextSmall subtle>{label}</TextSmall>}
    </Button>
  );

  // Simpple channel selector
  if (mode === "channel") {
    return (
      <Select
        portal={portal}
        value={channel}
        options={channels}
        open={open}
        setOpen={setOpen}
        placeholder="Search slack..."
        onChange={(o) => onChange(o?.id, undefined)}
        closeOnSelect={true}
        overrides={SLACK_OVERRIDES}
      >
        {trigger}
      </Select>
    );
  }

  const side = useMemo(
    () => (
      <Menu>
        {map(channels, (c) => (
          <MenuItem
            key={c.id}
            icon={Hash}
            text={c.name}
            selected={c.id === _channel}
            onClick={withHardHandle(() => handleSelected(c))}
          />
        ))}
      </Menu>
    ),
    [channels, _channel]
  );

  const overrides = useMemo(
    () => ({
      ...SLACK_OVERRIDES,
      Menu: SplitMenu<SlackOption, false>(undefined, side),
    }),
    [side]
  );

  return (
    <Select
      {...props}
      portal={portal}
      value={thread}
      open={open}
      setOpen={setOpen}
      options={threads}
      placeholder="Search slack..."
      onBlur={() => setFilter("")}
      onSearch={setFilter}
      onChange={handleSelected}
      closeOnSelect={true}
      className={{ popover: styles.splitPopover }}
      overrides={overrides}
    >
      {trigger}
    </Select>
  );
};

const SLACK_OVERRIDES: Partial<
  SelectComponents<SlackOption, false, GroupBase<SlackOption>>
> = {
  Option: ({ innerRef, innerProps, isSelected, isFocused, data }) => {
    const goTo = useGoTo();
    const goToSlack = useCallback((channel?: string) => {
      if (channel) {
        goTo(toSlackUrl(channel));
      }
    }, []);
    return (
      <div ref={innerRef} {...innerProps}>
        <MenuItem
          className={cx(
            styles.menuItem,
            styles.slackItem,
            isFocused && styles.focused
          )}
          icon={data.id === NEW_THREAD_ID ? PlusIcon : SlackColor}
        >
          <SpaceBetween gap={4}>
            <Ellipsis>
              {data.type === "thread" && `${data.name}`}
              {data.type === "channel" && `#${data.name}`}
            </Ellipsis>
            {data.id !== NEW_THREAD_ID && (
              <Button
                size="tiny"
                icon={ArrowUpRight}
                onClick={withHardHandle(() => goToSlack(data.id))}
              />
            )}
          </SpaceBetween>
        </MenuItem>
      </div>
    );
  },
};
