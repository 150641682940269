import { snakeCase } from "change-case";
import {
  filter,
  find,
  first,
  isArray,
  isEmpty,
  isString,
  keys,
  last,
  map,
  omit,
  reduce,
  values,
} from "lodash";

import {
  DatabaseID,
  Entity,
  EntityType,
  Form,
  HasBody,
  HasLocation,
  ID,
  Json,
  JsonArray,
  JsonObject,
  Meeting,
  PropertyDef,
  PropertyValueRef,
  Ref,
  RichText,
  SelectOption,
  Status,
  Task,
  Team,
  toTitleOrName,
  Workflow,
  WorkflowStep,
} from "@api";

import {
  getPropertyDef,
  isPropInScope,
  propertiesForEntity,
} from "@state/databases";
import { FormData } from "@state/form";
import { toStore } from "@state/generic";
import {
  getEntitiesLoader,
  getEntityLoader,
  getItemsNestedWithinLoader,
} from "@state/generic/queries";
import { toEntityLabel } from "@state/settings";
import { getItem, StoreState } from "@state/store";
import { toVariables } from "@state/workflow";

import { toAIField, toAiPropertyDef } from "@utils/ai";
import { justOne } from "@utils/array";
import { maybeTypeFromId, typeFromId } from "@utils/id";
import { tryParse } from "@utils/json";
import { equalsAny, switchEnum } from "@utils/logic";
import { cleanFormattedMarkdown } from "@utils/markdown";
import { Maybe, maybeMap, safeAs, when } from "@utils/maybe";
import { omitEmptyish } from "@utils/object";
import { mapAll, maybeMapAll } from "@utils/promise";
import {
  asPropertyValueRef,
  inflateProperty,
  isAnyRelation,
  toFieldName,
  toId,
  toPropertyValueRef,
} from "@utils/property-refs";
import { extractMentions, toHtml, toMarkdown } from "@utils/rich-text";
import { extractTeam, fromScope } from "@utils/scope";
import { plural } from "@utils/string";
import { extractVariables } from "@utils/variables";
import { withoutStar } from "@utils/wildcards";

import { AiCaseContext, asAiUseCase } from "./types";
import { asJsonObject, parseJsonResponse } from "./utils";

const stringify = (obj: any) => JSON.stringify(omitEmptyish<any, any>(obj));

const embedData = (data: any) => "```json\n" + stringify(data) + "\n```";

const validatePropertyValue = (
  prop: PropertyDef<Task>,
  input: Maybe<string>
): Maybe<PropertyValueRef> => {
  const value = find(prop.values?.[prop.type], (v) =>
    switchEnum(prop.type, {
      status: () => (v as Status)?.id === input,
      select: () => (v as SelectOption)?.id === input,
      multi_select: () => (v as SelectOption)?.id === input,
      relation: () => (v as Ref)?.id === input,
      relations: () => (v as Ref)?.id === input,
      else: () => false,
    })
  );

  if (!value) {
    return undefined;
  }

  return asPropertyValueRef(prop, {
    [prop.type]: value,
  });
};

const getItemFromAnyStore = (id: ID, stores: AiCaseContext["stores"]) =>
  getItem<Entity>(toStore(stores, typeFromId<EntityType>(id)), id);

export const taskPropertiesAutoFill = asAiUseCase({
  prepareData: async ({
    entity,
    props,
  }: {
    entity: Entity;
    props: PropertyDef[];
  }) => {
    return { entity, props };
  },
  toPrompt: ({ entity, props }) => `
  You are an AI team operations expert that automates the classification of work for a team.

  Your goal is to provide helpful metadata for the ${
    entity.source.type
  } below to make it easier to find and organise.

  This is all the information we have for the ${entity.source.type}:
  ${stringify(entity)}

  You have been provided with a list of possible options for each field in the metadata. You need to choose the correct value for each field based on the information provided.

  This metadata is comprised of the fields in the following JSON object, with the key as the "fieldId" and the value as the "fieldLabel":
   ${stringify(
     reduce(
       props,
       (r, p) => {
         return {
           ...r,
           [p.field]: toAIField(p),
         };
       },
       {}
     )
   )}

  For each "fieldID" above, the JSON object below contains the list of possible options with the key as the "optionId" and the value as the "optionLabel":
    ${stringify(
      toAiPropertyDef(
        filter(
          props,
          (p) =>
            p.visibility !== "hidden" &&
            isEmpty(toPropertyValueRef(entity, p)?.value[p.type])
        )
      )
    )}
  
  Using the infromation above, choose a value for each field from its list of possible options. If you're unsure of the correct value for a field, choose the "null" option.

  Return your choices as a JSON object with the "fieldId" as the key and your chosen "optionId" as the value (NOT the "optionLabel"). Please return only valid JSON and no other text.
`,
  parseCompletion: (response, { entity, props }) => {
    const suggested = isString(response)
      ? (tryParse(response) as JsonObject)
      : isArray(response)
      ? (response[0] as JsonObject)
      : response;

    if (!suggested) {
      return [];
    }

    return maybeMap(keys(suggested), (key) => {
      const prop = find(props, (p) => p.field === key || toAIField(p) === key);
      const value = when(prop, (p) =>
        // Don't use the value if it's already set
        isEmpty(toPropertyValueRef(entity, p)?.value[p.type])
          ? // Check what came back from AI was valid
            validatePropertyValue(
              p,
              toId(suggested[key] as Maybe<string | { id?: string }>)
            )
          : undefined
      );
      return !!(prop && value) ? value : undefined;
    });
  },
});

export const extractTasks = asAiUseCase({
  // Generate prompt
  toPrompt: ({ text, props }: { text: string; props: PropertyDef<Task>[] }) => `
Your goal is to create tasks from any document.
You are very good at extracting the most important tasks from a document.
You are very good at being concise but effective at communicating what work needs to be done.

Return a concise task title in, for example: {"title":"Something that needs to be done"}
Summarize what needs to be done in the summary field : {"summary": "This is more details about what needs to be done for the task with any [links in markdown](http://google.com)."}
Assign the task to a person or team or leave it blank: {"assigned": "DKIE"} or {"team": "DKIE"} {"team": null, "assigned": null} 

The allowed values for assigned and team are:
  ${stringify(toAiPropertyDef(props))}

Create one or more tasks from the below document. Respond in the format [{"title":"", "summary": ""}]

Only respond in JSON, I need to parse the results so anything other than valid JSON will be ignored. Please only valid JSON response, any other text will break. Only JSON.

----
${text}
`,
  parseCompletion: (response, { text, props }) => {
    const suggestions = isString(response)
      ? (tryParse(response) as JsonArray)
      : (response as JsonArray);

    if (!suggestions) {
      return [];
    }

    return maybeMap(suggestions as JsonObject[], (suggested) =>
      // Filter out any empty suggestions or incorrectly formatted
      isString(suggested?.title) && !!suggested.title
        ? {
            title: when(suggested?.title, String),
            body: when(suggested?.summary, (summary) => ({
              markdown: String(summary),
            })),
          }
        : undefined
    );
  },
});

export const bulkCreate = asAiUseCase({
  prepareData: async (
    {
      location,
      type,
      dump,
    }: { location: string; type: EntityType; dump: RichText },
    { stores }
  ) => {
    const parents = maybeMap(fromScope(location), (id) =>
      getItem<Entity>(toStore(stores, typeFromId<EntityType>(id)), id)
    );
    const props = propertiesForEntity(stores.props, type);
    const team = find(parents, (p) => p.source?.type === "team") as Maybe<Team>;
    const people = team
      ? maybeMap(team?.people, (r) => getItem(toStore(stores, "person"), r.id))
      : [];

    return {
      location: location,
      props,
      parents,
      type,
      people,
      dump: when(dump, toMarkdown),
    };
  },
  toPrompt: ({ location, people, parents, props, type, dump }) => `
    You are a work manager that creates and organises new work for teams.
    
    Your goal is to take unstructured information like messages or CSVs and return work to create.

    You should not add information to the work that is not provided to you, so do not make assumptions.

    You should reference properties by their ID rather than their labels. 

    For select, status, multi_select properties, you should only use values that exist in the \`available_properties\`.

    It is important that for any properties you return, they only use the values that match the "field" in the "available_properties".

    Here is all the infromation we have about the ${type} in JSON:
    \`\`\`json
    ${stringify({
      type,
      available_properties: props,
      available_users: people,
      default_status: find(props, (p) => p.field === "status")?.values
        ?.status?.[0],
      location: location,
      parents: parents,
    })}
    \`\`\`

    ----
    The information entered by the user is:
    \`\`\`json
    ${dump}
    \`\`\`

    ----

    If you need to create an ID for a new item you should follow our naming conventions for IDs.
    1. IDs are prefixed with a 1-2 char from their type, e.g. all task IDs start with \`t_\`
    2. Following the prefix, IDs should be 8 characters long and alphanumeric e.g. \`t_ErKgv4pt\`
    3. New IDs that are not saved to the database should be wrapped in \`[]\` e.g. \`[t_ErKgv4pt]\`

    These are all of the mappings from type of work to the prefix:
    
    \`\`\`json
    {
      t: "task",
      o: "outcome",
      tm: "team",
      ca: "calendar",
      cm: "campaign",
      c: "content",
      u: "person",
      bl: "backlog",
      sc: "schema",
      sp: "sprint",
      sd: "schedule",
      tj: "job",
      uw: "person_workspace",
      w: "workspace",
      p: "project",
      r: "resource",
      rm: "roadmap",
      n: "note",
      v: "view",
    }
    \`\`\`

    ----
    You should respond with a JSON array. Each object should use the \`property.field\` as the key above and should reference the ID of any select or tag properties. 

    Do not include properties that you don't have any value for.

    Every item should have either a title or name.

    If it looks like work has a dependency on another piece of work, then you should use the refs.blockedBy property to reference the ID of the work that is blocking it.

    If you know who the work should be assigned to then lookup the user's ID from \`available_users\` and use their ID in the \`assigned\` or \`owner\` property.

    You should always provide a status value when it \`status\` is in available_properties.

    Any additional information that did not match the available_properties should be added to the \`body\` as markdown.

    For example:
    \`\`\`
    [{"id": "[t_ErKgv4pt]", "title": "Some title", "status": "some_status_id", "team": "some_team_id", "custom.field": "some_custom_field_id", "refs.projects": ["some_project_id"]}]
    \`\`\`

    Respond now with the objects to create from this data.
  `,

  parseCompletion: (response) =>
    isString(response)
      ? (tryParse(response) as JsonArray)
      : (response as JsonArray),
});

export const autoUpdate = asAiUseCase({
  prepareData: async ({ entity }: { entity: Entity }, { stores }) => {
    const withStatus = inflateProperty(
      entity,
      getPropertyDef(stores.props, entity.source, {
        field: "status",
        type: "status",
      })
    );
    const props = filter(
      values(stores.props.lookup),
      (p) => !!p && isPropInScope(p, entity.source)
    );
    const type = maybeTypeFromId(entity?.id) || "task";

    const location = map(
      fromScope(safeAs<HasLocation>(entity)?.location),
      (id) =>
        getItem<Entity>(
          stores[typeFromId<EntityType>(id)] as StoreState<Entity>,
          id
        )
    ) as Entity[];

    const nested: Entity[] = [];

    await mapAll(props, async (p) => {
      const referenceType = justOne(withoutStar(p?.options?.references));
      if (!!p && isAnyRelation(p) && referenceType && !!p.options?.hierarchy) {
        return getItemsNestedWithinLoader(
          entity.id,
          { type: referenceType, scope: entity.source?.scope },
          undefined,
          (vs) => nested.push(...vs)
        );
      }
    });

    return {
      type,
      entity: withStatus,
      location: location,
      nested: nested,
    };
  },
  toPrompt: ({ type, entity, location, nested }) => `
    You are a work manager that gives updates to the team about the status of any work.
    
    Your goal is to generate a helpful and concise summary for any work. The summary should be sized relative to the amount of important things to update.

    So if there isn't much to report, then keep it short (1 sentence). If there is a lot to report, then give a more detailed update (3-5 sentences).

    You should use markdown syntax for links for example \`[Google](https://google.com)\`

    You can link to anything that has an \`id\` field with the url structure e.g. \`https://traction.team/:id\`

    You should try not to reference codes, but rather use natural language.

    You should be concise and sucinct and NOT list out information that is given to you. Instead you should try aggregate and summarize information in a more useful way.

    Given a set of information about a ${type}, return a summary in markdown. Do not include a title or any heading, just write the update.

    Here is all the infromation we have about the ${type} in JSON:
    \`\`\`
    ${stringify({
      [type]: entity,
      location: location,
      nested: nested,
    })}
    \`\`\`

    Respond with the summary for this ${type}.
    `,
  parseCompletion: (response) => response,
});

export const chooseEmoji = asAiUseCase({
  prepareData: async ({ entity }: { entity: Entity }, { stores }) => {
    const type = entity.source.type || maybeTypeFromId(entity?.id) || "task";

    return { type, entity: entity };
  },
  toPrompt: ({ type, entity }) => `
    Pick the best emoji for this ${type}: ${toTitleOrName(entity)}.
    
    Here is all the information we have about this ${type} in JSON:
    \`\`\`
    ${stringify({
      [type]: omit(entity, "icon"),
    })}
    \`\`\`

    Respond the emoji you think best represents this ${type}. Do not include any other text, just the emoji.
  `,
  parseCompletion: (response) => String(response),
});

export const autoName = asAiUseCase({
  prepareData: async (
    { entity, additional }: { entity: Partial<Entity>; additional?: Json },
    { stores }
  ) => {
    const teamId = when(
      safeAs<HasLocation>(entity)?.location || entity.source?.scope,
      extractTeam
    );
    const aliasedType = teamId
      ? toEntityLabel(
          entity.source?.type,
          { plural: false, case: "lower" },
          getItem(stores?.team, teamId)?.settings || {}
        )
      : undefined;

    return {
      data: entity,
      additional,
      type:
        aliasedType ||
        entity.source?.type ||
        when(entity?.id, maybeTypeFromId) ||
        "work",
      purpose: safeAs<Meeting>(entity)?.purpose,
    };
  },
  toPrompt: ({ data, additional, type, purpose }) => `
    <app_context>
      This is running inside a work management platform called Traction. 
      Traction stores all the information about work in a structured way. 
      This AI is designed to automatically suggest ${type} names when people are creating a ${type}.
  
      Good ${type} names are short (1-3 words ideally), clear, and concise. They avoid jargon terms and use natural language.
    
      Bad ${type} names are long (over 6 words) and have fluffy meaningless words in them.
    </app_context>

    ${
      additional &&
      `<context_data>
        \`\`\`json
          ${stringify(additional)}
        \`\`\`
      </context_data>`
    }

    <prompt_instructions>
      Suggest a clear and concise name for this ${type} that is about: ${purpose}
      
      Here is all the information we have about this ${type} in JSON:
      \`\`\`json
      ${stringify(data)}
      \`\`\`

      If you don't have enough information to choose a useful name, return an empty string.
      
      Respond the ${type} name you think is best. Do not include any other text or quote marks, just the name.
    </prompt_instructions>
  `,
  parseCompletion: (response) => String(response)?.replaceAll('"', ""),
});

export const formSubmissionName = asAiUseCase({
  prepareData: async (
    { form, data }: { form: Form; data: FormData },
    { stores }
  ) => {
    const teamId = when(
      safeAs<HasLocation>(form)?.location || form.source?.scope,
      extractTeam
    );
    const aliasedType = teamId
      ? toEntityLabel(
          form?.entity ||
            when(form.useTemplate?.id, maybeTypeFromId<EntityType>) ||
            "form",
          { plural: false, case: "lower" },
          getItem(stores?.team, teamId)?.settings || {}
        )
      : undefined;

    return {
      data: data,
      form: form,
      type:
        aliasedType ||
        form?.entity ||
        when(form.useTemplate?.id, maybeTypeFromId) ||
        "work",
    };
  },
  toPrompt: ({ data, form, type }) => `
    <app_context>
      This is running inside a work management platform called Traction. 
      Traction stores all the information about work in a structured way. 
      This AI is designed to create names for ${plural(
        type
      )} based on the information provided in a form submission.
  
      Good ${type} names are short (1-3 words ideally), clear, and concise. They avoid jargon terms and use natural language.
    
      Bad ${type} names are long (over 6 words) and have fluffy meaningless words in them.
    </app_context>

    <prompt_instructions>
      Suggest a clear and concise name for this ${type} base on the information below.

      Form being submitted:
      \`\`\`json
        ${stringify(form)}
      \`\`\`
      
      Form data that was inputted by the user.
      \`\`\`json
      ${stringify(data)}
      \`\`\`

      If you don't have enough information to choose a useful name, return an empty string.
      
      Respond with the ${type} name you think is best. Do not include any other text or quote marks, just the name.
    </prompt_instructions>
  `,
  parseCompletion: (response) => String(response)?.replaceAll('"', ""),
});

export const workflowStep = asAiUseCase({
  prepareData: async (
    {
      workflow,
      step,
      steps,
    }: { workflow: Workflow; step: WorkflowStep; steps: WorkflowStep[] },
    context
  ) => {
    const vars = toVariables(workflow, steps);
    const prompt = toHtml(safeAs<RichText>(step.options?.prompt));
    const mentionedVars = map(
      extractVariables({ html: prompt }),
      (r) => r.field
    );
    const mentionedWork = await maybeMapAll(
      extractMentions({ html: prompt }),
      async (r) => await getEntityLoader(r.id)
    );
    const outputs = safeAs<JsonArray>(step.outputs);
    const filteredVars = await maybeMapAll(vars, async (v) => {
      // Filter out if not mentioned in prompt
      if (!equalsAny(v.field, mentionedVars)) {
        return undefined;
      }

      if (v.type === "relation" && v.value?.relation?.id) {
        return {
          ...v,
          value: {
            relation:
              first(await getEntitiesLoader([v.value?.relation.id])) ||
              v.value?.relation,
          },
        };
      }
      if (v.type === "relations" && v.value?.relations?.length) {
        return {
          ...v,
          value: {
            relations: await getEntitiesLoader(map(v.value?.relations, "id")),
          },
        };
      }

      return v;
    });

    return {
      prompt,
      outputs,
      contextData: { workflow, running_step: step },
      promptData: {
        mentioned_variables: filteredVars,
        mentioned_work: mentionedWork,
      },
    };
  },
  toPrompt: ({ promptData, contextData, prompt, outputs }) => `
    <app_context>
      This is running inside a work management platform called Traction. 
      Traction stores all the information about work in a structured way.
      This AI is designed to respond to a prompt that is part of a workflow.
      It will use the information provided in the workflow to generate a response in the format required by the prompt in the outputs html tag.
      You must only return JSON, and it should be formatted as per the outputs tag.
      The user does not nescarily know about the workflow and workflow steps, they just see it as work being moved along, so don't reference the workflow or steps in your response.
    </app_context>

    <training>
      All work in traction can be linked to multiple things but lives in one place. This is specified by the location property (or source.scope) and is a / separated list of IDs.
      E.g. a task that lives within a teams' sprint might have an ID of t_ErKgv4pt and a location of tm_DKIE/sp_ErKgv4pt

      All work in traction can be linked to by simply using the ID.
      E.g. the url for a task with id t_ErKgv4pt would be https://traction.team/t_ErKgv4pt

      Links can also contain the location of the entity (but do not have to).
      E.g. the url for a task with id t_ErKgv4pt and location tm_DKIE/sp_ErKgv4pt could be https://traction.team/tm_DKIE/sp_ErKgv4pt/t_ErKgv4pt
    </training>

    <training>
      Traction rich text (rich_text property type) support two formats. Either html or markdown, however html is preferred. 
      To provide markdown you must return the rich_text property with the markdown key. (e.g. {"rich_text": {"markdown": "This is a markdown string"}})
      To provide html you must return the rich_text property with the html key. (e.g. {"rich_text": {"html": "<p>This is an html string</p>"}})

      Markdown supports common markdown syntax as well as:
      - Links: [link](https://traction.team)
      - Images: ![alt](https://traction.team/image.jpg)
      - Code: \`code\`
      - Code Block: \`\`\`code\`\`\`
      - Quotes: > quote
      - Todo: - [ ] todo
      
      HTML (preferred) supports the following tags:
      - Headers: h1, h2, h3, h4, h5, h6
      - Lists: ul, ol, li
      - Bold: b, strong
      - Italic: i, em
      - Http Links: a
      - Traction Mentions: <a data-mention-id=":id">{optional_text}</a>

      Traction Mentions will show the full name of the work inline, so when referencing work, you should use Traction Mentions rather than adding hyperlinking the text directly.

      If you want to mention a user, you don't need to know their name, just use the ID in the format <a data-mention-id=":id"></a>
    </training>

    <training>
      Outputs are provided as JSON in the following format:
      \`\`\`typscript
        type Output = PropertyValueRef & Partial<InlinePropertyDef>;

        type PropertyValueRef = {
          field: string;
          type: PropertyType;
          value: PropertyValue;
        }

        type PropertyValue = {
          text?: string;
          rich_text?: RichText;
          number?: number;
          date?: ISODate;
          boolean?: boolean;
        };

        type PropertyType = keyof PropertyValue;

        type RichText = {
          text?: string;
          markdown?: string;
          html?: string;
          state?: JsonObject;
        };

        type Ref = {id: string};
      \`\`\`

      Outputs should return an array of PropertyValueRef.

      So if the outputs section contained this:
      \`\`\`json
        [{"type": "rich_text", "field": "summary", "label": "Summary", "value": {}, "options": {"references": []}, "description": "Summary of my tasks."}]
      \`\`\`

      Then you should respond with the value for the "summary" field in the format:
      \`\`\`json
        [{"field": "summary", "type": "rich_text", "value": {"rich_text": {"html": "This is a summary of my tasks."}}]
      \`\`\`
    </training>

    <workflow_context>
      ${embedData(contextData)}
    </workflow_context>

    <user_prompt_data>
      ${embedData(promptData)}
    </user_prompt_data>

    <user_prompt>
      ${prompt}
    </user_prompt>

    <outputs>
      ${embedData(outputs)}
    </outputs>
  `,
  parseCompletion: (response): Maybe<JsonArray> => {
    const result = parseJsonResponse(response);
    return isArray(result) ? result : safeAs<JsonArray>(result?.outputs);
  },
});

export const summariseMeeting = asAiUseCase({
  prepareData: async ({ meeting }: { meeting: Meeting }, { stores }) => {
    const agendas = maybeMap(meeting.refs.agendas, (ref) =>
      when(getItem(stores.agenda, ref.id), (agenda) => ({
        agenda,
        actions: maybeMap(agenda.refs.actions, (a) =>
          getItem(stores.action, a.id)
        ),
      }))
    );

    const team = getItem<Team>(
      stores.team,
      extractTeam(meeting.location) || ""
    );
    const location = maybeMap(fromScope(meeting.location), (id) =>
      getItem<Entity>(toStore(stores, typeFromId<EntityType>(id)), id)
    );

    return { meeting, agendas, team, location };
  },
  toPrompt: ({ meeting, agendas, team, location }) => `
    <app_context>
      This is running inside a work management platform called Traction. 
      Traction stores all the information about work in a structured way. 
      This AI is designed to automatically summarise meetings based on the meeting agenda, notes, and actions that have been taken.
    </app_context>

    <prompt_instructions>
      Write a short summary for this meeting that: 
      1] generalise what the meeting was about to remind people who attended
      2] provide people who didn't attend with a snippet of what happened to understand if they should look in further

      The summary you write will be shown on a Meeting page that has the meeting title, purpose, agenda, notes, actions, and other information about the meeting already visible. So it should not repeat information that is already there.

      The Summary is shown under a label that says: "In this meeting:" so should start with "We..."

      ## A Good summary:
      - is very short
      - is 1 paragraph long and easy to skimread
      - do not include an introductory sentence such as "In today's meeting"
      - do not include the meeting name
      - uses simple language to explain complex ideas
      - sounds human and not overly formal
      - The first sentence should jump straight into it, without any "this meeting", etc

      ## A Bad summary:
      - starts with "The meeting..." or anything similar.
      - starts with the meeting name in the first sentence
      - lists out information from the meeting that is already visible on the screen
      - includes information that is not in the meeting notes or actions.
      - use corporate jargon or sound overly formal

      Return a summary for this meeting in Markdown format. Do not include a title or any heading, just write the summary.
    </prompt_instructions>

    <meeting_agenda>
      Name: ${meeting.name}
      Purpose: ${meeting.purpose}
      Agenda:
      ${map(
        agendas,
        ({ agenda }) => `
        ### ${agenda.title}
        ${toMarkdown(agenda.body)}
      `
      )}
    </meeting_agenda>

    <meeting_notes_and_actions>
      ${map(
        agendas,
        ({ agenda, actions }) => `
          ### ${agenda.title}
          Notes:
          ${toMarkdown(agenda.notes)}
          Actions:
          ${map(actions, (action) => `- [] ${action.title}`).join("\n")}
        `
      )}
    <meeting_notes_and_actions>
  `,
  parseCompletion: (response) =>
    cleanFormattedMarkdown(String(response))?.replaceAll('"', ""),
});

export const linkName = asAiUseCase({
  toPrompt: ({ link }) => `
    You are an AI that helps to name things. You are very good at coming up with names for things.

    You will be given a link and your goal is to come up with a title for the link.

    If you can't access the link, you should extract any real words from the link and use those to come up with a title. 
    
    If there are no real words but just IDs, then you should look at the url domain name and return a generic title based on where the link is hosted.

    Here is the link:
    ${link}

    Respond in JSON in the format {keywords, title} where keywords are the words you extracted from the link and title is the title you came up with. If you can't access the link, then just return the title based on the domain name.
  `,
  parseCompletion: (response) =>
    safeAs<string>(asJsonObject(parseJsonResponse(response))?.title),
});

interface AutoBriefProps {
  source: DatabaseID;
  prop: PropertyDef;
  instructions: Maybe<RichText>;
  original: Maybe<RichText>;
  existing?: Maybe<RichText>;
}

export const autoBrief = asAiUseCase({
  prepareData: async function (
    { prop, source, instructions, original, existing }: AutoBriefProps,
    { stores, settings }
  ) {
    const toLabel = (t: EntityType) =>
      toEntityLabel(t, { case: "title", plural: false }, settings);
    const type =
      source.type ||
      when(last(fromScope(source.scope)), maybeTypeFromId) ||
      "task";
    const hierarchy = maybeMap(fromScope(source.scope), (id) =>
      getItem<Entity>(
        stores[typeFromId<EntityType>(id)] as StoreState<Entity>,
        id
      )
    );
    const location = map(hierarchy, (e) => ({
      id: e.id,
      type: toLabel(e.source.type || maybeTypeFromId(e.id)),
      name: toTitleOrName(e),
      body: when(safeAs<HasBody>(e)?.body, toMarkdown),
    }));
    const parent = last(hierarchy);
    const team = first(hierarchy);

    return {
      type: toLabel(type),
      team: safeAs<Team>(team),
      location: location,
      // Remove auto-summary (bad data)
      entity: when(parent, (p) => omit(p, prop.field, "summary")) as Entity,
      field: toFieldName(prop),
      instructions: toMarkdown(instructions),
      original: toHtml(original) || "",
      existing: when(existing, toMarkdown) || "",
    };
  },
  // prettier-ignore
  toPrompt: ({ team, entity, field, instructions, location, type, original, existing, ...rest }) => `
    <app_context>
      This is running inside a work management platform called Traction. 
      Traction stores all the information about work in a structured way. 
      This AI is designed to help users create briefs for work items based on the information they provide.
      Traction IDs are in the format \`[type]_[4-20 alphanumeric characters]\` e.g. \`[t_ErKgv4pt]\`. You can link to anything in Traction by using the ID in the URL e.g. \`https://traction.team/[id]\`
      Your response is displayed on the ${type} page, underneath all of the other information about the ${type}.
      Everything in traction is located in a single Location that is a hierarchy of other entities. For example a Task might live in a Sprint which is in a Roadmap which is in a Team. This is represented with a location of Team > Roadmap > Sprint > Task. Locations are stored as an array of parents from top to bottom.
    </app_context>

    <prompt_instructions>
      You are an expert brief writer, skilled in crafting clear, concise, and effective briefs for various work scenarios. Your task is to help users improve their briefs based on the information they provide.

      This brief is for a ${type}: "${toTitleOrName(entity)}"

      You should take ${team?.name} team needs into account when planning how to structure this and what information to include or not.

      Follow these steps:
      1. Analyze the provided information
      2. Identify key information, elements and objectives that need to be communicated
      3.a If there is an \`<user_original_version>\` then use the structure of that to guide your brief
      3.b If there is no \`<user_original_version>\` then structure the brief as you see fit
      4. Ensure alignment with the brief's purpose, team and location

      A good brief is: 
      - Clear, concise, and to the point.
      - Communicates effectively what needs to be done.
      - Includes all the necessary information
      - Is structured in a way that makes it easy to understand.
      - Uses simple language to explain complex ideas. 
    
      A bad brief is: 
      - Overly long or "professional sounding" just for the sake of it 
      - Adds information to the brief that wasn't in the original message.
      - Makes up information, deadlines, or requirements that weren't in the original message.
      - Adds unnecessary complexity to the brief that makes it harder to understand.
      - Creates unnecessary work for the team by adding things that don't need to be done.
      - Adds paragraphs that don't really say much.

      Good formatting is:
      - Return all briefs in markdown format (CRITICAL)
      - For things that need to be completed (such as requirements), use markdown checkboxes
      - Only return the markdown content of the briefs, no other text.
      - The page this is displayed on already has a title, so your brief does not need to include a title, but can if critical to the structure.
      - Uses human readable titles or names for entities, not IDs.
      - Uses multiple H1 (#) throughout the brief for headings.

      Bad formatting is:
      - Starting with a title that repeats the title of the page.
      - List data that is passed in, since all data is already displayed on the screen.
      - Using the IDs of work in the brief.
      - Copies information from \`<${snakeCase(type)}_data>\` that can become out of date when changed.
      - Uses a h1 (#) for the title of the brief.

      Remember to maintain the user's core message while optimizing the brief's effectiveness.

      When you are sent any messages you should reply with a clearly formatted brief. 
    </prompt_instructions>

    <location_data>
      This is provided as contextual information. Do not include this unless it is critical to the brief.
      The location is an array of "parents" for where this ${type} is located. E.g. Team > Roadmap > Sprint > Task.
      \`\`\`json
      (location)}
      \`\`\`
    </location_data>

    <${snakeCase(type)}_data>
      This is provided as contextual information. Do not include this unless it is critical to the brief.
      \`\`\`json
      ${stringify(entity)}
      \`\`\`
    </${snakeCase(type)}_data>

    <app_screen_description>
      Your response will be displayed on a page that has the title: "${when(entity, toTitleOrName) || type}"

      Underneath the title is all of the data from \`<${snakeCase(type)}_data>\`.

      Underneath the data is a section called "${field}" which is where your brief will be displayed.
    </app_screen_description>
    
    <user_original_version>
      \`\`\`html
        ${original}
      \`\`\`
    </user_original_version>

    ${existing && `<gpt_previous_attempt>${existing}</gpt_previous_attempt>`}
    
    <user_instructions>
      ${instructions}
    </user_instructions>
  `,
  parseCompletion: (response) => cleanFormattedMarkdown(String(response)),
});

export const summariseBody = asAiUseCase({
  prepareData: async ({ item }: { item: HasBody }, { stores }) => {
    const team = getItem<Team>(stores.team, extractTeam(item.location) || "");
    const location = maybeMap(fromScope(item.location), (id) =>
      getItem<Entity>(toStore(stores, typeFromId<EntityType>(id)), id)
    );
    const type = item.source.type || maybeTypeFromId(item.id) || "process";

    return { item, team, type, location };
  },
  toPrompt: ({ item, team, type, location }) => `
    <app_context>
      This is running inside a work management platform called Traction. 
      Traction stores all the information about work in a structured way. 
      This AI is designed to automatically summarise documentation based on the information in the document.
    </app_context>

    <prompt_instructions>
      Write a short 1-2 sentence summary for this ${type} that captures the gist of what the document is communicating.
      
      The summary is shown under a the title of the ${type} and should quickly convey the main points of the document.

      ## A Good summary:
      - is 1-2 sentences and easy to skimread
      - summarises key information from the document rather than describing what is in the document.
      - Starts the sentence with the key information
      - does not include the ${type} name
      - sounds human and not overly formal
      
      ## A Bad summary:
      - Starts the sentence with "The document..." or "This ${type}..." or anything that would be repetitive when looking at many in a list.
      - includes information that is not in the document
      - use corporate jargon or sound overly formal

      Return a summary for this ${type} in Markdown format. Do not include a title or any heading, just write the summary.
    </prompt_instructions>

    <good_example>
      Stripe subscriptions are cancelled through admin portal, Google Play subscriptions via the Play Console, and Apple subscriptions cannot be cancelled on behalf of the customer.
    </good_example>

    <context>
      Team: ${team?.name}
      Location: ${map(
        location,
        (l) => `${l.source.type}: ${toTitleOrName(l)}`
      ).join(", ")}
    </context>

    <${type}_data>
      Name: ${toTitleOrName(item)}
      Body: ${toMarkdown(item.body)}
    </${type}_data>
  `,
  parseCompletion: (response) =>
    cleanFormattedMarkdown(String(response))?.replaceAll('"', ""),
});
