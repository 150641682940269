import { find, intersection, isString, map, sortBy, without } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  canCreate,
  DatabaseID,
  Entity,
  EntityType,
  FetchOptions,
  Ref,
  RelationRef,
  toSearchable,
} from "@api";

import { useSmartSearch } from "@state/generic";
import { useInstalledEntities } from "@state/packages";
import { useMe } from "@state/persons";
import { useEntityLabels } from "@state/settings";
import { useLazyAllTeams } from "@state/teams";
import { useActiveWorkspaceId } from "@state/workspace";

import {
  dependencySort,
  ensureMany,
  justOne,
  OneOrMany,
  reverse,
} from "@utils/array";
import { withHardHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { useRefState, useResetableState } from "@utils/hooks";
import { Maybe, when } from "@utils/maybe";
import { omitEmpty } from "@utils/object";
import { extractTeam, toBaseScope } from "@utils/scope";
import { isStar, OrStar, withoutStar } from "@utils/wildcards";

import { Button } from "@ui/button";
import { Divider } from "@ui/divider";
import { useOpenState } from "@ui/dropdown";
import { getEngine, render } from "@ui/engine";
import { EntityEngine } from "@ui/engine/fallback";
import { HStack } from "@ui/flex";
import {
  AngleDownIcon,
  CornerLeftUpAlt,
  Icon,
  PlusAlt,
  TeamIcon,
} from "@ui/icon";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem } from "@ui/menu-item";
import { RelationLabel } from "@ui/relation-label";
import { Text } from "@ui/text";
import { Tooltip } from "@ui/tooltip";

import { SplitControl, SplitMenu } from "./comps";
import {
  MultiRelationSelect,
  MultiRelationSelectProps,
  RelationSelect,
  RelationSelectProps,
} from "./relation";

import styles from "./select.module.css";

export type EntitySelectProps = Omit<RelationSelectProps, "options"> & {
  type: EntityType;
  options?: FetchOptions;
};

export type GlobalEntitySelectProps = Omit<EntitySelectProps, "type"> & {
  type?: EntityType;
  scope?: string;
  allowed?: OrStar<OneOrMany<EntityType>>;
  exclude?: OneOrMany<EntityType>;
  templates?: boolean;
  showTeam?: boolean;
  closeOnTeam?: boolean;
  showOtherTeams?: boolean;
  showNewButton?: boolean;
};

export type MultiEntitySelectProps = Omit<
  MultiRelationSelectProps,
  "options"
> & {
  type: EntityType;
};

export type GlobalMultiEntitySelectProps = Omit<
  MultiEntitySelectProps,
  "type"
> & {
  type?: EntityType;
  scope?: string;
  allowed?: OrStar<OneOrMany<EntityType>>;
  exclude?: OneOrMany<EntityType>;
  templates?: boolean;
  showTeam?: boolean;
  closeOnTeam?: boolean;
  showOtherTeams?: boolean;
};

const toOption = (t: Entity) => ({
  id: t.id,
  name: toSearchable(t),
});

export function EntitySelect({
  type,
  className,
  options: opts,
  open: _open,
  setOpen: _setOpen,
  ...props
}: EntitySelectProps) {
  const [open, setOpen] = useOpenState(_open, _setOpen);
  const { onSearch, options, loading } = useSmartSearch(type, undefined, {
    ...opts,
    toOption,
    empty: open, // Don't search until open
  });

  return (
    <RelationSelect
      {...props}
      className={{
        ...(!isString(className) ? className : {}),
        popover: styles.entityPopover,
      }}
      open={open}
      setOpen={setOpen}
      loading={loading}
      options={options}
      onSearch={onSearch}
    />
  );
}

export function EntityMultiSelect({ type, ...props }: MultiEntitySelectProps) {
  const { onSearch, options, loading } = useSmartSearch(type, undefined, {
    toOption,
    archived: false,
    empty: props.open,
  });

  return (
    <MultiRelationSelect
      {...props}
      loading={loading}
      options={options}
      onSearch={onSearch}
    />
  );
}

export function GlobalEntitySelect({
  open: _open,
  setOpen: _setOpen,
  templates,
  allowed: _allowed,
  exclude,
  showTeam: _showTeam,
  showOtherTeams = true,
  showNewButton = true,
  className,
  ...props
}: GlobalEntitySelectProps) {
  const workspaceId = useActiveWorkspaceId();
  const [open, __setOpen] = useOpenState(_open, _setOpen);

  const allowed = useMemo(
    (): OrStar<EntityType[]> =>
      when(_allowed, (t) => (isStar(t) ? t : ensureMany(t))) ||
      when(props.type, ensureMany) || ["team"],
    [_allowed]
  );
  const showTeam = useMemo(
    () => isStar(allowed) || allowed?.includes("team") || false,
    [allowed]
  );

  const [showCreating, creating, setShowCreating] = useRefState<boolean>(false);
  const setOpen = useCallback((v: boolean) => {
    // Ignore until show creating closed...
    if (creating.current) {
      return;
    }
    __setOpen(v);
  }, []);

  const [scope, setScope] = useResetableState<string>(
    () => props.scope || "",
    [open]
  );
  const scopeAllowed = useAllowedTypes(
    open ? scope : undefined,
    allowed,
    ensureMany(exclude)
  );
  const [filter, setFilter] = useResetableState<DatabaseID>(
    () => ({
      type:
        props.type ||
        find(ensureMany(withoutStar(allowed)), (t) => t !== "team") ||
        scopeAllowed?.[0] ||
        "team",
      scope: scope || "",
    }),
    [open]
  );
  const AsCreateDialog = useMemo(
    () =>
      getEngine(filter.type as EntityType)?.asCreateDialog ||
      EntityEngine.asCreateDialog,
    [filter?.type]
  );
  const { onSearch, options, loading } = useSmartSearch(
    filter?.type,
    filter.type === "team" ? workspaceId : filter?.scope,
    useMemo(
      () => ({
        templates,
        empty: open,
        archived: false,
        toOption,
        limit: filter?.type === "team" ? 100 : undefined,
      }),
      [open, templates]
    )
  );

  const isSwitchingTeam = useMemo(
    () =>
      filter.type === "team" &&
      !(allowed?.length === 1 && allowed[0] === "team"),
    [filter.type, props.type, allowed]
  );

  const handleFilterChanged = useCallback(
    (changes: Partial<DatabaseID>) => {
      setFilter((e) => ({ ...e, ...omitEmpty(changes) }));
      when(changes?.scope, setScope);
    },
    [setFilter]
  );

  const handleValueChanged = useCallback(
    (value: Maybe<Ref>) => {
      if (!value) {
        return props.onChange?.(undefined);
      }

      if (isSwitchingTeam) {
        handleFilterChanged({
          scope: value.id,
          type:
            find(scopeAllowed, (t) => t !== "team") ||
            find(ensureMany(withoutStar(allowed)), (t) => t !== "team") ||
            props.type ||
            "task",
        });
      } else {
        props.onChange?.(value);
      }
    },
    [handleFilterChanged, isSwitchingTeam, scopeAllowed]
  );

  const Control = useMemo(
    () =>
      open && canCreate(filter.type) && showNewButton
        ? SplitControl<RelationRef, false>(
            undefined,
            !!AsCreateDialog ? (
              <Button
                subtle
                icon={PlusAlt}
                size="small"
                onClick={() => {
                  setShowCreating(true);
                }}
              >
                <Text subtle>New</Text>
              </Button>
            ) : undefined
          )
        : undefined,
    [open, filter]
  );

  const Menu = useMemo(
    () =>
      open &&
      filter.type !== "team" &&
      SplitMenu<RelationRef, false>(
        undefined,
        <SideMenu
          {...props}
          showTeam={showTeam}
          showOtherTeams={showOtherTeams}
          open={open}
          setOpen={setOpen}
          allowed={scopeAllowed}
          filter={filter}
          onFilterChanged={handleFilterChanged}
        />
      ),
    [open, filter]
  );

  // When props input changes
  useEffect(() => {
    handleFilterChanged({
      type: props.type,
      scope: props.scope || "",
    });
  }, [props.scope, props.type]);

  return (
    <>
      <RelationSelect
        {...props}
        open={open}
        setOpen={setOpen}
        loading={loading}
        options={options}
        closeOnBlur={false}
        closeOnSelect={isSwitchingTeam ? false : props.closeOnSelect}
        onSearch={onSearch}
        onChange={handleValueChanged}
        className={{
          ...(!isString(className) ? className : { dropdown: className }),
          popover: styles.splitPopover,
        }}
        overrides={{
          ...props.overrides,
          ...(Menu ? { Menu } : {}),
          ...(Control ? { Control } : {}),
        }}
      />
      {showCreating &&
        render(AsCreateDialog, {
          defaults: { location: filter.scope, source: filter },
          onCancel: () => {
            setShowCreating(false);
            setOpen(true);
          },
          onSaved: (value) => {
            setShowCreating(false);
            props.onChange?.(justOne(value));
          },
        })}
    </>
  );
}

export function GlobalMultiEntitySelect({
  open: _open,
  setOpen: _setOpen,
  templates,
  closeOnTeam,
  showOtherTeams = true,
  ...props
}: GlobalMultiEntitySelectProps) {
  const workspaceId = useActiveWorkspaceId();
  const [open, __setOpen] = useOpenState(_open, _setOpen);
  const [showCreating, creating, setShowCreating] = useRefState<boolean>(false);
  const setOpen = useCallback((v: boolean) => {
    // Ignore until show creating closed...
    if (creating.current) {
      return;
    }
    __setOpen(v);
  }, []);

  const [filter, setFilter] = useResetableState<DatabaseID>(
    () => ({
      type: props.type || "task",
      scope: props.scope || "",
    }),
    [open]
  );

  const AsCreateDialog = useMemo(
    () =>
      getEngine(filter.type as EntityType)?.asCreateDialog ||
      EntityEngine.asCreateDialog,
    [filter?.type]
  );

  const { onSearch, options, loading } = useSmartSearch(
    filter?.type,
    filter.type === "team" ? workspaceId : filter?.scope,
    { templates, empty: open, archived: false, toOption }
  );

  const handleScopeChanged = useCallback(
    (changes: Partial<DatabaseID>) =>
      setFilter((e) => ({ ...e, ...omitEmpty(changes) })),
    [setFilter]
  );

  const Control = useMemo(
    () =>
      open &&
      SplitControl<RelationRef, true>(
        showOtherTeams ? (
          <ScopeMenu filter={filter} onFilterChanged={handleScopeChanged} />
        ) : undefined,
        !!AsCreateDialog && canCreate(filter.type) ? (
          <Button
            subtle
            icon={PlusAlt}
            size="small"
            onClick={() => {
              setShowCreating(true);
            }}
          >
            <Text subtle>New</Text>
          </Button>
        ) : undefined
      ),
    [open, filter]
  );

  const Menu = useMemo(
    () =>
      open &&
      SplitMenu<RelationRef, true>(
        undefined,
        <SideMenu
          allowed={ensureMany(withoutStar(props.allowed))}
          showTeam={props.showTeam ?? true}
          showOtherTeams={false}
          open={open}
          setOpen={setOpen}
          closeOnTeam={closeOnTeam}
          filter={filter}
          onFilterChanged={handleScopeChanged}
        />
      ),
    [filter, open, props.showTeam]
  );

  // When props input changes
  useEffect(() => {
    handleScopeChanged({
      type: props.type,
      scope: props.scope || "",
    });
  }, [props.scope, props.type]);

  return (
    <>
      <MultiRelationSelect
        closeOnBlur={false}
        closeOnSelect={false}
        {...props}
        open={open}
        setOpen={setOpen}
        loading={loading}
        options={options}
        onSearch={onSearch}
        className={{ popover: styles.splitPopover }}
        overrides={{
          ...props.overrides,
          ...(Menu ? { Menu } : {}),
          ...(Control ? { Control } : {}),
        }}
      />
      {showCreating &&
        render(AsCreateDialog, {
          defaults: { location: toBaseScope(filter.scope), source: filter },
          onCancel: () => {
            setShowCreating(false);
            setOpen(true);
          },
          onSaved: (value) => {
            setShowCreating(false);
            props.onChange?.([...(props.value || []), ...ensureMany(value)]);
          },
        })}
    </>
  );
}

type SplitMenuProps = Pick<
  GlobalEntitySelectProps,
  | "open"
  | "setOpen"
  | "onChange"
  | "scope"
  | "closeOnTeam"
  | "showTeam"
  | "showOtherTeams"
> & {
  filter: DatabaseID;
  allowed?: EntityType[];
  onFilterChanged: Fn<Partial<DatabaseID>, void>;
};

function ScopeMenu({ filter, onFilterChanged }: SplitMenuProps) {
  const allTeams = useLazyAllTeams();
  const [selecting, setSelecting] = useState(false);
  const selected = useMemo(
    () => find(allTeams, (t) => filter.scope?.includes(t.id)),
    [allTeams]
  );
  const teams = useMemo(
    () => dependencySort(allTeams, (t) => t.parent?.id),
    [allTeams]
  );

  return (
    <>
      <Button
        size="small"
        iconRight={AngleDownIcon}
        onClick={withHardHandle(() => setSelecting(!selecting))}
      >
        {!!selected && (
          <HStack gap={4}>
            <Icon size="small" icon={<TeamIcon team={selected} />} />
            {selected.name}
          </HStack>
        )}

        {!selected && "Workspace"}
      </Button>

      {selecting && (
        <div className={styles.takeover} onClick={withHardHandle(() => {})}>
          {map(teams, (team) => (
            <MenuItem
              key={team.id}
              icon={<TeamIcon team={team} />}
              onClick={withHardHandle(() => {
                onFilterChanged?.({ scope: team.id });
                setSelecting(false);
              })}
            >
              {team.name}
            </MenuItem>
          ))}
        </div>
      )}
    </>
  );
}

export function SideMenu({
  showTeam,
  showOtherTeams,
  allowed,
  scope,
  filter,
  onChange,
  closeOnTeam,
  onFilterChanged,
  setOpen,
}: SplitMenuProps) {
  const wID = useActiveWorkspaceId();

  const toLabel = useEntityLabels(scope);

  const handleSelected = useCallback(
    (type: EntityType) => onFilterChanged({ type }),
    [onFilterChanged, filter]
  );

  const scopeTeam = useMemo(() => extractTeam(filter.scope), [filter.scope]);

  if (!allowed?.length && !showOtherTeams) {
    return <></>;
  }

  return (
    <MenuGroup inset={false}>
      {showOtherTeams && (
        <>
          <Tooltip text="Change team">
            <MenuItem
              icon={CornerLeftUpAlt}
              onClick={withHardHandle(() =>
                onFilterChanged?.({ type: "team", scope: wID })
              )}
            >
              <Text subtle>Teams</Text>
            </MenuItem>
          </Tooltip>

          <Divider className={styles.sideDivider} />
        </>
      )}

      {showTeam && !!scopeTeam && onChange && (
        <Tooltip text="Move directly under team">
          <MenuItem
            onClick={withHardHandle(() => {
              onChange?.({ id: scopeTeam });
              closeOnTeam !== false && setOpen?.(false);
            })}
          >
            <RelationLabel relation={{ id: scopeTeam }} />
          </MenuItem>
        </Tooltip>
      )}

      {map(allowed, (t: EntityType) => (
        <MenuItem
          key={t}
          selected={t === filter.type}
          onClick={withHardHandle(() => handleSelected(t))}
        >
          {toLabel(t, { plural: true, case: "title" })}
        </MenuItem>
      ))}
    </MenuGroup>
  );
}

function useAllowedTypes(
  scope: Maybe<string>,
  whitelist: Maybe<EntityType[] | "*">,
  blacklist?: Maybe<EntityType[]>
): EntityType[] {
  const me = useMe();
  const allEntities = useInstalledEntities(
    scope ? toBaseScope(scope) || me.id : undefined
  );

  return useMemo(() => {
    if (!scope) {
      return [];
    }

    const all = without(
      [...reverse(allEntities), "person"],
      ...(blacklist || [])
    );

    return sortBy(
      (isStar(whitelist) || isStar(whitelist?.[0]) || !whitelist?.length
        ? all
        : intersection(all, whitelist)) as EntityType[],
      (t) =>
        ["task", "content", "request", "form", "agenda", "meeting"]?.includes(t)
          ? `1${t}`
          : `0${t}`
    );
  }, [allEntities, whitelist]);
}
