import { atomFamily } from "recoil";

import { Campaign, ID } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type CampaignStoreState = StoreState<Campaign>;

export const WorkspaceCampaignStoreAtom = atomFamily<CampaignStoreState, ID>({
  key: "WorkspaceCampaignStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "campaign" },
  effects: (wid) => [indexedDBStorageForStore(wid, "campaign")],
});

export const CampaignStoreAtom = WorkspaceWrappedAtom(
  "CampaignStoreAtom",
  WorkspaceCampaignStoreAtom
);
