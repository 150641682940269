import { find, includes, map } from "lodash";
import { useCallback, useMemo } from "react";
import { MarkerType, NodeProps, Position } from "reactflow";

import { ID, VariableDef, WorkflowAction, WorkflowStep } from "@api";
import { Workflow } from "@api";

import { useLazyEntities } from "@state/generic";
import { useLazyEntity } from "@state/generic";

import { omitEmpty } from "@utils/array";
import { toFlowDefinition, treeLayout, useFlowData } from "@utils/chart";
import { cx } from "@utils/class-names";
import { withHardHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { using } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { maybeMap, when } from "@utils/maybe";
import { getPositionOrder, setPositionOrders } from "@utils/ordering";
import {
  asAppendMutation,
  asDeleteUpdate,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import { toLabel } from "@utils/property-refs";
import { containsRef } from "@utils/relation-ref";

import { CenteredOverflow } from "@ui/centered-overflow";
import { WorkflowStepIcon } from "@ui/engine/workflow_step";
import { VStack } from "@ui/flex";
import {
  ButtonHandle,
  Connection,
  Edge,
  Handle,
  InvisibleHandle,
  Node,
} from "@ui/flow";
import { Flow, withFlowProvider } from "@ui/flow";
import { useNodeTypes } from "@ui/flow/effects";
import { Icon } from "@ui/icon";
import { useMutate } from "@ui/mutate";
import { RelationIcon, RelationLabel } from "@ui/relation-label";
import { ReadonlyDocument } from "@ui/rich-text";
import { Text, TextLarge, TextMedium } from "@ui/text";
import { CustomTooltip } from "@ui/tooltip";

import styles from "./workflow-builder-flow.module.css";

interface Props {
  id: ID;
  interactable?: boolean;
  className?: string;
  selected?: string[];
  onAddStep?: (step: WorkflowStep, handle?: string) => void;
  onSelectionChanged?: Fn<ID[], void>;
  onOpenStep?: Fn<WorkflowStep, void>;
}

type NodeData = {
  step: WorkflowStep;
  selected?: boolean;
  workflow: Maybe<Workflow>;
  onAddNext?: Fn<WorkflowStep, void>;
};

type EdgeData = {
  label: Maybe<string>;
  color: Maybe<string>;
};

export const WorkflowBuilderFlow = withFlowProvider(
  ({
    id,
    className,
    interactable = true,
    onOpenStep,
    selected,
    onSelectionChanged,
    onAddStep,
  }: Props) => {
    const workflow = useLazyEntity<"workflow">(id);
    const steps = useLazyEntities<"workflow_step">(workflow?.refs.steps);
    const mutate = useMutate();
    const nodeTypes = useNodeTypes({ "workflow-step": WorkflowStepNode }, []);

    const strat = useMemo(
      () =>
        treeLayout(
          { width: 120, height: 80, hSpace: 1, vSpace: 10 },
          { orientation: "vertical" }
        ),
      []
    );
    const flowDefinition = useMemo(
      () =>
        toFlowDefinition<WorkflowStep, NodeData, EdgeData>({
          layout: strat,
          nodeTypes: nodeTypes,
          fitView: { maxZoom: 1.5, minZoom: 0.5, padding: 0.05, duration: 100 },
          toNode: (step) =>
            using([includes(selected, step.id)], (selected) => ({
              id: step.id,
              type: "workflow-step",
              draggable: interactable,
              position: getPositionOrder(step.orders, "default"),
              selected,
              data: {
                workflow: workflow,
                selected,
                step: step,
                onAddNext: onAddStep,
              },
            })),
          toEdges: (step) =>
            map(step.refs?.blocks || [], (ref) => {
              const isCondition = step.action === "condition";
              const passed =
                !isCondition || step.outputs?.[0]?.value?.boolean || false;
              const isElseFlow =
                isCondition && containsRef(step.refs?.else, ref);

              return {
                id: `${step.id}-${ref.id}`,
                source: step.id,
                sourceHandle: isElseFlow ? "else" : undefined,
                target: ref.id,
                type: isElseFlow ? "hvstep" : "vstep", // Conection type, not workflow-step
                data: {
                  label: "No",
                  color:
                    step?.status?.id === "FNS" &&
                    ((passed && !isElseFlow) || (!passed && isElseFlow))
                      ? "green_3"
                      : undefined,
                },
                markerEnd: MarkerType.Arrow,
              };
            }),
        }),
      [workflow, selected, onAddStep]
    );

    const { nodes, edges, toPosition, ...flowProps } = useFlowData(
      steps,
      flowDefinition
    );

    const setSelected = useCallback(
      (ids: string[]) => {
        // Only update if the selection has changed
        if (JSON.stringify(selected) !== JSON.stringify(ids)) {
          onSelectionChanged?.(ids);
        }
      },
      [selected, onSelectionChanged]
    );

    const handleDelete = useCallback(
      async (nodes: Node[]) => {
        const stepsToDelete = maybeMap(nodes, (n) => find(steps, { id: n.id }));

        if (!stepsToDelete.length) {
          return;
        }

        mutate(map(stepsToDelete, (step) => asDeleteUpdate(step)));
      },
      [steps]
    );

    const handleConnect = useCallback(
      async (edge: Edge | Connection) => {
        const from = find(steps, (s) => s.id === edge.source);
        const to = find(steps, (s) => s.id === edge.target);

        if (!from || !to) {
          return;
        }

        mutate(
          asUpdate(
            from,
            omitEmpty([
              asAppendMutation({ field: "refs.blocks", type: "relations" }, [
                { id: to.id },
              ]),
              edge?.sourceHandle === "else"
                ? asAppendMutation({ field: "refs.else", type: "relations" }, [
                    { id: to.id },
                  ])
                : undefined,
            ])
          )
        );
      },
      [steps]
    );

    const handleDisconnect = useCallback(
      async (connection: Edge[]) => {
        const from = find(steps, (s) => s.id === connection[0].source);
        const to = find(steps, (s) => s.id === connection[0].target);

        if (!from || !to) {
          return;
        }

        mutate([
          asUpdate(from, [
            asAppendMutation(
              { field: "refs.blocks", type: "relations" },
              [{ id: to.id }],
              "remove"
            ),
            asAppendMutation(
              { field: "refs.else", type: "relations" },
              [{ id: to.id }],
              "remove"
            ),
          ]),
          asUpdate(
            to,
            asAppendMutation(
              { field: "refs.blockedBy", type: "relations" },
              [{ id: from.id }],
              "remove"
            )
          ),
        ]);
      },
      [steps]
    );

    const onNodeClicked = useCallback(
      (_event: React.MouseEvent, node: Node) => setSelected([node.id]),
      [setSelected]
    );

    const handleResetLayout = useCallback(() => {
      mutate(
        map(steps, (step) =>
          asUpdate(step, asMutation({ field: "orders", type: "json" }, {}))
        )
      );
    }, [steps]);

    const handleNodeMoved = useCallback(
      (event: React.MouseEvent, node: Node) => {
        const step = find(steps, { id: node.id });

        if (!step) {
          return;
        }

        mutate(
          asUpdate(
            step,
            asMutation(
              { field: "orders", type: "json" },
              setPositionOrders(step.orders, "default", node.position)
            )
          )
        );
      },
      [steps]
    );

    const handleNodeDoubleClicked = useCallback(
      (_event: React.MouseEvent, node: Node) => {
        !!onOpenStep
          ? when(find(steps, { id: node.id }), onOpenStep)
          : onSelectionChanged?.([node.id]);
      },
      [onOpenStep, onSelectionChanged]
    );

    return (
      <Flow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        showControls={interactable}
        className={cx(!interactable && styles.readonly, className)}
        {...flowProps}
        onPaneClick={() => setSelected([])}
        onResetLayout={handleResetLayout}
        onConnect={handleConnect}
        onNodesDelete={handleDelete}
        onNodeDragStop={handleNodeMoved}
        onEdgesDelete={handleDisconnect}
        onNodeClick={onNodeClicked}
        onNodeDoubleClick={handleNodeDoubleClicked}
      />
    );
  }
);

export function WorkflowStepNode({
  data: { selected, onAddNext, step, direction = "vertical" },
}: NodeProps<{
  selected?: boolean;
  step: WorkflowStep;
  direction: "horizontal" | "vertical";
  onAddNext?: (step: WorkflowStep, handle?: string) => void;
}>) {
  const created = useLazyEntities(
    step.template ? undefined : step.refs?.created
  );
  const assigned = useMemo(() => step.owner, [created]);

  if (!step) {
    return <></>;
  }

  return (
    <div className={cx(styles.workflowStep, selected && styles.selected)}>
      <Handle
        className={styles.handle}
        type="target"
        position={direction === "horizontal" ? Position.Left : Position.Top}
      />

      <div className={styles.iconContainer}>
        <WorkflowStepIcon step={step} className={styles.icon} size={48} />
        {!!assigned && (
          <Icon
            className={styles.assignedIcon}
            size="medium"
            icon={<RelationIcon relation={assigned} />}
          />
        )}
      </div>

      <VStack gap={0}>
        <CenteredOverflow>
          <TextLarge bold className={styles.text}>
            {step.name}
          </TextLarge>
        </CenteredOverflow>

        {step.action !== WorkflowAction.Condition && !!step.outputs?.length && (
          <CenteredOverflow>
            <TextMedium bold subtle className={styles.text}>
              {map(step.outputs, (v) => (
                <CustomTooltip
                  contents={<StepOutputPreview step={step} output={v} />}
                >{`{${v.field}}`}</CustomTooltip>
              ))}
            </TextMedium>
          </CenteredOverflow>
        )}
      </VStack>

      {/* Used for positioning */}
      <InvisibleHandle
        type="source"
        position={direction === "horizontal" ? Position.Right : Position.Bottom}
      />

      {/* Used for positioning */}
      {step.action === WorkflowAction.Condition && (
        <>
          <InvisibleHandle
            id="else"
            type="source"
            className={styles.fixedRight}
            position={
              direction === "vertical" ? Position.Right : Position.Bottom
            }
          />
          {onAddNext && (
            <ButtonHandle
              id="else"
              type="source"
              position={
                direction === "horizontal" ? Position.Bottom : Position.Right
              }
              onClick={withHardHandle(() => onAddNext?.(step, "else"))}
              className={cx(
                styles.addNext,
                styles.addButton,
                styles.fixedRight
              )}
            />
          )}
        </>
      )}

      {onAddNext && (
        <ButtonHandle
          type="source"
          position={
            direction === "horizontal" ? Position.Right : Position.Bottom
          }
          onClick={withHardHandle(() => onAddNext?.(step))}
          className={cx(styles.addNext, styles.addButton)}
        />
      )}
    </div>
  );
}

const StepOutputPreview = ({
  step,
  output,
}: {
  step: WorkflowStep;
  output: VariableDef;
}) => {
  if (step.template) {
    return (
      <Text className={styles.label}>
        Output set to variable after running.
      </Text>
    );
  }

  return (
    <VStack className={styles.outputPreview}>
      {!!output.value.relation && (
        <RelationLabel
          className={styles.label}
          relation={output.value.relation}
        />
      )}
      {!!output.value.relations?.length && (
        <VStack className={styles.relations}>
          {map(output.value.relations, (relation) => (
            <RelationLabel className={styles.label} relation={relation} />
          ))}
        </VStack>
      )}
      {!!output.value.rich_text && (
        <ReadonlyDocument color="inverted" content={output.value.rich_text} />
      )}
      {!output.value.relation &&
        !output.value.rich_text &&
        !output.value.relations?.length && (
          <Text className={styles.label}>{toLabel(output)}</Text>
        )}
    </VStack>
  );
};
