import { atomFamily, selectorFamily } from "recoil";

import { ID, Schedule } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type ScheduleStoreState = StoreState<Schedule>;

export const WorkspaceScheduleStoreAtom = atomFamily<ScheduleStoreState, ID>({
  key: "WorkspaceScheduleStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "schedule" },
  effects: (wid) => [indexedDBStorageForStore(wid, "schedule")],
});

export const ScheduleStoreAtom = WorkspaceWrappedAtom(
  "ScheduleStoreAtom",
  WorkspaceScheduleStoreAtom
);

export const ScheduleAtom = selectorFamily({
  key: "ScheduleAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(ScheduleStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(ScheduleStoreAtom, mergeItem<Schedule>(newValue));
      }
    },
});
