import { find, map, pick, without } from "lodash";
import {
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  DatabaseID,
  Entity,
  EntityType,
  FilterQuery,
  ID,
  JsonArray,
  PropertyMutation,
  PropertyValue,
  PropertyValueRef,
  Ref,
  VariableDef,
  Workflow,
  WorkflowAction,
  WorkflowStep,
} from "@api";

import { JsonObject } from "@prisma/client/runtime/library";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useFilterableProperties, useLazyProperties } from "@state/databases";
import {
  useCreateEntity,
  useLazyEntities,
  useLazyEntity,
  useLazyQuery,
  useMarkAsSeen,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { useAddToRecents } from "@state/recents";
import { useRunWorkflow, useTestWorkflow, useVariables } from "@state/workflow";
import { WorkflowRunnerModal } from "@state/workflow/runner-modal";

import {
  dependencySort,
  ensureArray,
  ensureMany,
  justOne,
  maybeLookup,
  omitFalsey,
  OneOrMany,
  uniqBy,
} from "@utils/array";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { extractVarReference, isFormula, toVarReference } from "@utils/formula";
import { useConst } from "@utils/hooks";
import { equalsAny, switchEnum } from "@utils/logic";
import { Maybe, safeAs, when } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import { asFormulaValue, getPropertyValue, toRef } from "@utils/property-refs";
import { toBaseScope } from "@utils/scope";
import { plural, titleCase } from "@utils/string";
import { useSyncPathnameID } from "@utils/url";

import { usePageId } from "@ui/app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Button } from "@ui/button";
import { ButtonGroup, SplitButton } from "@ui/button-group";
import { Container } from "@ui/container";
import { CounterButton } from "@ui/counter-button";
import { Divider } from "@ui/divider";
import { EditableHeading } from "@ui/editable-heading";
import { PaneOpts } from "@ui/engine";
import { EntityPreview } from "@ui/entity-preview";
import { Centered, FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowUpRight,
  BoltAlt,
  CheckIcon,
  ClipboardNotes,
  ClockHistory,
  CounterIcon,
  Icon,
  Mouse,
  Play,
  StatusIcon,
} from "@ui/icon";
import { InflatedStatusTag } from "@ui/inflated-status-tag";
import { Field, TextInput } from "@ui/input";
import { Label } from "@ui/label";
import { ListItem } from "@ui/list-item";
import { Menu } from "@ui/menu";
import { CheckMenuItem, MenuItem } from "@ui/menu-item";
import { Modal } from "@ui/modal";
import { useMutate } from "@ui/mutate";
import { showError } from "@ui/notifications";
import { PackageTag } from "@ui/package-label";
import AppPage from "@ui/page/app-page";
import { NestedPropertyFilter } from "@ui/property-filter";
import { RelationIcon, RelationLabel } from "@ui/relation-label";
import { DocumentEditor } from "@ui/rich-text";
import { ColoredSection } from "@ui/section";
import { GlobalEntitySelect, LocationSelect, PersonSelect } from "@ui/select";
import { EntityTypeSelect } from "@ui/select/entity-type";
import { SlackSelect } from "@ui/select/slack";
import { WithVariableSelect } from "@ui/select/variable";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { SquareButton } from "@ui/square-button";
import { Switch } from "@ui/switch";
import { TabBar } from "@ui/tab-bar";
import { TemplateConfigure } from "@ui/template-configure";
import { Text, TextMedium, TextSmall } from "@ui/text";
import { VariableList } from "@ui/variable-list";
import { WorkflowBuilderFlow } from "@ui/workflow-builder-flow";
import { CollectDialog } from "@ui/workflow-collect-dialog";

import { WorkflowStepIcon } from "../workflow_step/icon";

import styles from "./editor.module.css";

export const WorkflowEditorPane = (props: PaneOpts<Workflow>) => {
  const { id, item: workflow } = props;
  const [page] = useRegisterPage(id, workflow);
  const stepSource = useNestedSource(workflow, "workflow_step");
  const teamId = useMemo(() => toBaseScope(workflow.source.scope), [workflow]);
  const pushTo = usePushTo();
  const goTo = useGoTo();
  const mutate = useMutate();
  const create = useCreateEntity(stepSource.type, stepSource.scope);
  const [selected, setSelected] = useState<Maybe<ID>>();
  const [active, setActive] = useState<"build" | "edit" | "run" | string>(
    workflow?.template ? "build" : "run"
  );
  const [adding, setAdding] = useState<boolean | string>(false);
  const [addingTo, setAddingTo] = useState<WorkflowStep | undefined>();

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, workflow?.id);

  // Add to recents
  useAddToRecents(id);

  const addStep = useCallback(
    (defaults: PropertyMutation[]) => {
      const action =
        (find(defaults, { field: "action" })?.value
          .text as Maybe<WorkflowAction>) || WorkflowAction.Create;

      const step = create([
        ...switchEnum<WorkflowAction, PropertyMutation[]>(action, {
          create: () => [
            asMutation({ field: "name", type: "text" }, "Add Work"),
            asMutation({ field: "options.entity", type: "text" }, "task"),
          ],
          control: () => [
            asMutation({ field: "name", type: "text" }, "Wait All"),
            asMutation({ field: "options.all", type: "boolean" }, true),
          ],
          ai: () => [
            asMutation({ field: "name", type: "text" }, "AI Prompt"),
            // asMutation({ field: "outputs", type: "json" }, [
            //   { field: "response", type: "text", label: "Response" },
            // ]),
          ],
          message: () => [
            asMutation({ field: "name", type: "text" }, "Send Message"),
            asMutation({ field: "options.via", type: "text" }, "slack"),
          ],
          exit: () => [
            asMutation({ field: "name", type: "text" }, "End Workflow"),
          ],
          condition: () => [
            asMutation({ field: "name", type: "text" }, "Condition"),
          ],
          update: () => [asMutation({ field: "name", type: "text" }, "Update")],
          wait: () => [asMutation({ field: "name", type: "text" }, "Wait")],
          else: () => [],
        }),

        asMutation({ field: "location", type: "text" }, stepSource.scope),
        asMutation({ field: "action", type: "text" }, action),
        asMutation({ field: "status", type: "status" }, { id: "NTS" }),
        asMutation(
          { field: "template", type: "text" },
          !!workflow.template ? "nested" : undefined
        ),
        asMutation(
          { field: "refs.blockedBy", type: "relations" },
          when(toRef(addingTo), ensureArray)
        ),
        asMutation({ field: "refs.workflow", type: "relations" }, [
          { id: workflow.id },
        ]),
        ...defaults,
      ]);

      if (adding === "else" && addingTo) {
        mutate(
          asUpdate(
            addingTo,
            asAppendMutation({ field: "refs.else", type: "relations" }, [
              toRef(step),
            ])
          )
        );
      }

      mutate(
        asUpdate(
          workflow,
          asAppendMutation({ field: "refs.steps", type: "relations" }, [
            toRef(step),
          ])
        )
      );

      setAdding(false);
      setAddingTo(undefined);

      setSelected(step.id);
    },
    [addingTo, workflow?.template]
  );

  const handleAddStep = useCallback(
    (connectTo?: WorkflowStep, handle?: string) => {
      setAddingTo(connectTo);
      setAdding(handle || true);
    },
    [create]
  );

  const handleOpenStep = useCallback(
    (step: WorkflowStep) => {
      if (step.template) {
        setSelected(step.id);
      } else if (step.refs?.created?.length) {
        pushTo(step.refs.created[0]);
      }
    },
    [setSelected, setActive]
  );

  useEffect(() => {
    if (!selected?.length && active === "edit") {
      setActive(workflow?.template ? "build" : "run");
    }
  }, [selected]);

  if (!workflow) {
    return <></>;
  }

  return (
    <Sheet
      size="full"
      transparency="mid"
      interactable={false}
      className={styles.container}
    >
      {adding && <AddWorkModal onAdd={addStep} onClose={setAdding} />}

      <HeaderBar {...props} />

      <WorkflowBuilderFlow
        id={id}
        className={styles.main}
        selected={when(selected, ensureArray) || []}
        onAddStep={handleAddStep}
        onOpenStep={handleOpenStep}
        onSelectionChanged={(s) => {
          setSelected(justOne(s));
          setActive("edit");
        }}
      />

      <VStack className={styles.pane} fit="container" gap={20}>
        <TabBar
          active={active}
          onActiveChanged={setActive}
          className={styles.tabBar}
          options={[
            { id: "build", title: "Build" },
            { id: "edit", title: workflow?.template ? "Edit" : "Details" },
            { id: "run", title: "Run" },
          ]}
        />

        {active === "build" && (
          <SpaceBetween direction="vertical" width="container">
            <FillSpace direction="vertical" fit="container" width="container">
              <VStack fit="container" gap={16}>
                <Field label="Status">
                  <SpaceBetween>
                    <MenuItem>
                      <SpaceBetween>
                        <Text>
                          {workflow.status?.id === "DFT"
                            ? "Ready to publish"
                            : "Available for use"}
                        </Text>
                        <Switch
                          checked={workflow.status?.id !== "DFT"}
                          onChange={(checked) =>
                            mutate(
                              asUpdate(
                                workflow,
                                asMutation(
                                  { field: "status", type: "status" },
                                  { id: checked ? "AVL" : "DFT" }
                                )
                              )
                            )
                          }
                        />
                      </SpaceBetween>
                    </MenuItem>
                  </SpaceBetween>
                </Field>

                <Field label="Workflow Owner">
                  <PersonSelect
                    value={workflow.owner}
                    className={{ trigger: styles.control }}
                    onChange={(v) =>
                      mutate(
                        asUpdate(
                          workflow,
                          asMutation({ field: "owner", type: "relation" }, v)
                        )
                      )
                    }
                  />
                </Field>

                {/* TODO */}
                {true && (
                  <Field label="Workflow Triggers">
                    <ColoredSection>
                      <VStack gap={10}>
                        <SpaceBetween>
                          <Label icon={Mouse}>Quick Actions</Label>
                          {workflow?.status?.id === "DFT" ? (
                            <TextSmall subtle>Not Published</TextSmall>
                          ) : (
                            <StatusIcon
                              status={{ group: "done", color: "green" }}
                            />
                          )}
                        </SpaceBetween>

                        <SpaceBetween>
                          <Label icon={ClockHistory}>Recurring Schedules</Label>

                          <div
                            onClick={() =>
                              goTo([teamId, "/settings/schedules"])
                            }
                          >
                            {when(
                              workflow?.refs?.schedules?.length || undefined,
                              (count) => (
                                <Container inset="right" padding="none">
                                  <CounterButton count={count} />
                                </Container>
                              )
                            ) || (
                              <Button size="tiny" variant="primary">
                                Setup
                              </Button>
                            )}
                          </div>
                        </SpaceBetween>

                        <SpaceBetween>
                          <Label icon={ClipboardNotes}>Forms</Label>
                          {/* <TextSmall subtle>Setup</TextSmall> */}
                          <TextSmall subtle>Coming soon...</TextSmall>
                        </SpaceBetween>

                        <SpaceBetween>
                          <Label icon={BoltAlt}>Automated Rules</Label>
                          {/* <TextSmall subtle>Setup</TextSmall> */}
                          <TextSmall subtle>Coming soon...</TextSmall>
                        </SpaceBetween>
                      </VStack>
                    </ColoredSection>
                  </Field>
                )}
                <Field label="Input Variables">
                  <VariableList
                    variables={workflow.inputs}
                    scope={workflow.source.scope}
                    onChanged={(vars) => {
                      mutate(
                        asUpdate(
                          workflow,
                          asMutation(
                            { field: "inputs", type: "json" },
                            safeAs<JsonArray>(vars)
                          )
                        )
                      );
                    }}
                  />
                </Field>
              </VStack>
            </FillSpace>
            <Field>
              <SquareButton
                className={styles.addButton}
                onClick={() => handleAddStep()}
              >
                <VStack fit="container" justify="center" align="center">
                  <HStack className={styles.stack}>
                    <WorkflowStepIcon action={WorkflowAction.AI} />
                    <WorkflowStepIcon action={WorkflowAction.Create} />
                    <WorkflowStepIcon action={WorkflowAction.Condition} />
                    <WorkflowStepIcon action={WorkflowAction.Message} />
                    <WorkflowStepIcon action={WorkflowAction.Exit} />
                  </HStack>
                  <Text>Add Step</Text>
                </VStack>
              </SquareButton>
            </Field>
          </SpaceBetween>
        )}

        {active === "edit" && (
          <WorkflowStepEditor id={selected} workflow={workflow} />
        )}

        {active === "run" && (
          <>
            {!!workflow.template && (
              <WorkflowRunHistory id={id} workflow={workflow} />
            )}
            {!workflow.template && <WorkflowRunner id={id} />}
          </>
        )}
      </VStack>
    </Sheet>
  );
};

const HeaderBar = ({ id, item: workflow }: PaneOpts<Workflow>) => {
  const mutate = useUpdateEntity(id);
  const goTo = useGoTo();
  return (
    <VStack className={styles.header} fit="content" gap={0}>
      <HStack>
        <EditableHeading
          key={workflow.id}
          size="h3"
          text={workflow.name || ""}
          placeholder="Untitled"
          onChange={(t) =>
            t && mutate(asMutation({ field: "name", type: "text" }, t))
          }
        />
        <PackageTag type={workflow.source.type} scope={workflow.source.scope} />
      </HStack>
      {!!workflow?.refs?.fromTemplate?.length && (
        <Button
          className={styles.hoverLink}
          variant="link"
          onClick={() =>
            when(workflow.refs.fromTemplate?.[0], (t) => goTo([t, "/builder"]))
          }
        >
          <HStack gap={4}>
            <Text>Based on</Text>
            <RelationLabel
              icon={false}
              relation={toRef(workflow.refs.fromTemplate[0])}
              iconRight={ArrowUpRight}
            />
          </HStack>
        </Button>
      )}
    </VStack>
  );
};

const ADD_STEPS = [
  {
    action: WorkflowAction.Create,
    label: "Add Work",
  },
  {
    action: WorkflowAction.Update,
    label: "Update Work",
  },
  {
    action: WorkflowAction.Find,
    label: "Find Work",
  },
  {
    action: WorkflowAction.Wait,
    label: "Wait Time",
  },
  {
    action: WorkflowAction.Control,
    label: "Wait All",
  },
  {
    action: WorkflowAction.Condition,
    label: "Condition",
  },
  {
    action: WorkflowAction.AI,
    label: "AI Prompt",
  },
  {
    action: WorkflowAction.Message,
    label: "Send Message",
  },
  {
    action: WorkflowAction.RunWorkflow,
    label: "Run Workflow",
  },
  {
    action: WorkflowAction.Exit,
    label: "End Workflow",
  },
];

const AddStepOptions = memo(
  ({ onAdd }: { onAdd: Fn<WorkflowAction, void> }) => {
    return (
      <HStack wrap gap={0}>
        {map(ADD_STEPS, ({ label, action }) => (
          <SquareButton
            key={action}
            className={styles.squareButton}
            icon={<WorkflowStepIcon size={"medium"} action={action} />}
            iconSize="xlarge"
            text={label}
            onClick={() => onAdd?.(action)}
          />
        ))}
      </HStack>
    );
  },
  (prev, next) => prev.onAdd === next.onAdd
);

AddStepOptions.displayName = "AddStepOptions";

const AddWorkModal = ({
  onAdd,
  onClose,
}: {
  onAdd: Fn<PropertyMutation[], void>;
  onClose: Fn<boolean, void>;
}) => {
  const [action, _setAction] = useState<WorkflowAction>();

  const setAction = useCallback((a: WorkflowAction) => {
    _setAction(a);
    onAdd([asMutation({ field: "action", type: "text" }, a)]);
  }, []);

  return (
    <Modal
      padding="none"
      open={true}
      autoPosition
      onOpenChanged={onClose}
      closeOnEscape={true}
      className={styles.addModal}
    >
      {!action && (
        <Container size="half">
          <AddStepOptions onAdd={setAction} />
        </Container>
      )}
    </Modal>
  );
};

const WorkflowStepEditor = ({
  id,
  workflow,
}: {
  id: Maybe<ID>;
  workflow: Workflow;
}) => {
  const step = useLazyEntity<"workflow_step">(id);
  const steps = useLazyEntities<"workflow_step">(workflow.refs.steps);
  const mutate = useUpdateEntity(id || "");

  if (!step) {
    return (
      <Centered fit="container" direction="horizontal">
        <Text subtle>Nothing selected.</Text>
      </Centered>
    );
  }

  return (
    <VStack fit="container">
      <Field>
        <TextInput
          icon={<WorkflowStepIcon action={step.action} />}
          value={step.name || ""}
          placeholder="Untitled step"
          autoFocus={false}
          onChange={(n) =>
            mutate(asMutation({ field: "name", type: "text" }, n))
          }
        />
      </Field>

      <Divider />

      {step.action === "wait" && (
        <WorkflowStepEditorWait
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "ai" && (
        <WorkflowStepEditorAI
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "message" && (
        <WorkflowStepEditorMessage
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "control" && (
        <WorkflowStepEditorControl
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "find" && (
        <WorkflowStepEditorFind
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "condition" && (
        <WorkflowStepEditorCondition
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "create" && (
        <WorkflowStepEditorCreate
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "update" && (
        <WorkflowStepEditorUpdate
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
    </VStack>
  );
};

const WorkflowRunHistory = ({
  id,
  workflow,
}: {
  id: Maybe<ID>;
  workflow: Workflow;
}) => {
  const pushTo = usePushTo();
  const [running, setRunning] = useState<ID>();
  const { start, ready } = useTestWorkflow(
    workflow,
    useMemo(() => (started) => setRunning(started.id), [])
  );
  const onRan = useConst(() => setRunning(undefined));
  const runs = useLazyQuery(
    "workflow",
    useMemo(
      () => ({
        field: "refs.fromTemplate",
        type: "relation",
        op: "equals",
        // TODO: getting replaced by createFromTemplate
        value: { relation: { id: workflow.id } },
      }),
      [workflow.id]
    )
  );

  return (
    <VStack>
      <TextMedium bold>Run History</TextMedium>

      {running && <WorkflowRunnerModal id={running} onClose={onRan} />}

      <Field>
        <VStack>
          {map(runs, (r) => (
            <ListItem key={r.id} onClick={() => pushTo(r)}>
              <SpaceBetween>
                <Text>{r.name}</Text>
                <InflatedStatusTag status={r.status} source={r.source} />
              </SpaceBetween>
            </ListItem>
          ))}
        </VStack>
      </Field>

      <Divider />

      <HStack fit="container" justify="flex-end">
        <Button
          variant="primary"
          size="small"
          loading={!ready}
          onClick={() => start?.(workflow)}
          icon={Play}
        >
          Test Workflow
        </Button>
      </HStack>
    </VStack>
  );
};

const WorkflowRunner = ({ id }: { id: Maybe<ID> }) => {
  const pushTo = usePushTo();
  const workflow = useLazyEntity<"workflow">(id);
  const steps = useLazyEntities<"workflow_step">(workflow?.refs.steps);
  const ordered = useMemo(
    () =>
      dependencySort(steps || [], (s) =>
        map(s.refs?.blockedBy, (b) => toRef(b)?.id)
      ),
    [steps]
  );
  const [collecting, setCollecting] = useState(false);
  const { run, running, ready, toCollect, onCollected } = useRunWorkflow(
    workflow,
    steps
  );

  const handleOpen = useCallback((step: WorkflowStep) => {
    if (!!step.refs?.created?.length) {
      pushTo(step.refs.created[0]);
    }
  }, []);

  useEffect(() => {
    if (toCollect?.length) {
      setCollecting(true);
    }
  }, [toCollect]);

  // TODO: Timing problems when creating workflow and setting variables inbetween steps
  useEffect(() => {
    if (ready && !running) {
      run();
    }
  }, [ready, running]);

  return (
    <VStack>
      {!!(toCollect?.length && collecting && workflow) && (
        <CollectDialog
          collect={toCollect as PropertyValueRef<Entity>[]}
          source={workflow.source}
          onCollected={(pv) => onCollected(pv as VariableDef[])}
          onCancel={() => setCollecting(false)}
        />
      )}
      <ColoredSection>
        <HStack>
          <Text>Workflow Runner</Text>
          <TextSmall subtle>
            {running ? "Running..." : ready ? "Ready" : "Waiting..."}
          </TextSmall>
        </HStack>
      </ColoredSection>
      {map(ordered, (s) => (
        <WorkflowStepListItem
          key={s.id}
          step={s}
          onClick={() => handleOpen(s)}
        />
      ))}

      <Divider />

      {/* <Button
        variant="primary"
        disabled={!!running || !ready}
        fit="container"
        className={styles.center}
        onClick={() => run()}
      >
        Run Next Step
      </Button> */}
    </VStack>
  );
};

const WorkflowStepListItem = ({
  step: s,
  onClick,
}: {
  step: WorkflowStep;
  onClick: Fn<MouseEvent, void>;
}) => {
  const pushTo = usePushTo();
  return (
    <ListItem key={s.id} onClick={onClick}>
      <SpaceBetween>
        <HStack>
          <WorkflowStepIcon step={s} size="small" />
          <Text>{s.name}</Text>
          <TextSmall subtle>{s.id}</TextSmall>
        </HStack>

        {map(s.refs?.created, (r) => (
          <Icon
            key={r.id}
            onClick={() => pushTo(r)}
            icon={<RelationIcon relation={r} />}
          />
        ))}
      </SpaceBetween>
    </ListItem>
  );
};

/* Step Editors */

type StepEditorProps = {
  step: WorkflowStep;
  workflow: Workflow;
  allSteps: Maybe<WorkflowStep[]>;
};

const WorkflowStepEditorCreate = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const pushTo = usePushTo();
  const mutate = useUpdateEntity(step.id);
  const mutateWorkflow = useUpdateEntity(workflow.id);
  const source = useMemo(
    () => ({
      scope: step.source.scope,
      type: safeAs<EntityType>(step.options?.entity) || "task",
    }),
    [step.source.scope, step.options?.entity]
  );
  const properties = useLazyProperties(source);
  const bodyProp = useMemo(
    () => find(properties, { field: "body" }),
    [properties]
  );
  const title = useMemo(
    () => find(step.overrides, { field: "title" }),
    [step.overrides]
  );
  const body = useMemo(
    () =>
      find(step.overrides, { field: "body" })?.value.rich_text || {
        html: "",
      },
    [step.overrides]
  );
  const variables = useVariables(workflow, allSteps);

  const outputVariable = useMemo(() => step.outputs?.[0], [step.outputs]);
  const handleToggleOutputVariable = useCallback(
    (output: boolean) => {
      mutate(
        asMutation(
          { field: "outputs", type: "json" },
          output
            ? [
                {
                  field: step.options?.entity || "default",
                  type: "relation",
                  options: { references: step.options?.entity },
                },
              ]
            : []
        )
      );
    },
    [step.outputs, step.options?.entity]
  );

  const mutateOverride = useCallback(
    (change: OneOrMany<PropertyMutation>) => {
      mutate(
        omitFalsey([
          asMutation(
            { field: "overrides", type: "json" },
            safeAs<JsonArray>(
              uniqBy(
                [
                  ...(step.overrides || []),
                  ...map(ensureMany(change), (c) =>
                    pick(c, "field", "value", "type")
                  ),
                ],
                (c) => c.field,
                "last"
              )
            )
          ),
        ])
      );
    },
    [step.overrides, step.id]
  );

  if (step.refs?.created?.length) {
    return (
      <CreatedWorkPreview
        work={step.refs.created[0]}
        onOpen={(r) => !!r && pushTo(r)}
      />
    );
  }

  return (
    <>
      <Field padded>
        <SpaceBetween>
          <EntityTypeSelect
            value={step.options?.entity as Maybe<EntityType>}
            scope={step.source.scope}
            plural={false}
            onChange={(v) =>
              mutate(asMutation({ field: "options.entity", type: "text" }, v))
            }
          />
        </SpaceBetween>
      </Field>

      <Field padded>
        <EditableHeading
          key={step.id}
          size="h3"
          text={title?.value.text || ""}
          autoFocus={!title?.value.text}
          placeholder="Untitled"
          onChange={(t) => {
            mutateOverride(asMutation({ field: "title", type: "text" }, t));
            mutate(asMutation({ field: "name", type: "text" }, t));
          }}
        />
      </Field>

      <Field>
        <TemplateConfigure
          template={when(safeAs<string>(step.options?.useTemplate), toRef)}
          overrides={safeAs<PropertyValueRef[]>(step.overrides)}
          field="overrides"
          variables={variables}
          blacklist={["status", "name", "title", "body"]}
          source={source}
          onChange={(c) => {
            mutate(c);
            // When setting the owner/assigned on the task, also set it on the step
            when(
              find(ensureMany(c), (c) => c.field === "overrides"),
              (c) =>
                mutate(
                  asMutation(
                    { field: "owner", type: "relation" },
                    find(
                      safeAs<PropertyMutation[]>(c.value.json),
                      (p) =>
                        p.type === "relation" &&
                        equalsAny(p.field, ["owner", "assigned"])
                    )?.value?.relation
                  )
                )
            );
          }}
        />
      </Field>

      <Field label={bodyProp?.label} padded>
        <DocumentEditor
          placeholder="What needs to get done..."
          newLineSpace="large"
          scope={source.scope}
          variables={variables}
          onNewVariable={(v) =>
            mutateWorkflow(
              asMutation(
                { field: "inputs", type: "json" },
                safeAs<JsonArray>([...(workflow.inputs || []), v])
              )
            )
          }
          content={body}
          onChanged={(rt) =>
            mutateOverride(asMutation({ field: "body", type: "rich_text" }, rt))
          }
        />
      </Field>

      <Divider />

      <Field>
        <CheckMenuItem
          inset
          checked={!!safeAs<boolean>(step.outputs?.length)}
          onChecked={handleToggleOutputVariable}
          text="Save as variable"
        />

        {!!outputVariable && (
          <TextInput
            value={outputVariable.field ? `@${outputVariable.field}` : ""}
            updateOn="blur"
            placeholder="@"
            onChange={(t) =>
              mutate(
                asMutation(
                  { field: "outputs", type: "json" },
                  safeAs<JsonArray>([
                    { ...outputVariable, field: t?.replace(/^@/, "") },
                  ])
                )
              )
            }
          />
        )}
      </Field>
    </>
  );
};

const toWaitLabel = (step: Partial<WorkflowStep>) => {
  const waitTimes = when(
    safeAs<number | string>(step.options?.waitTimes),
    Number
  );
  const waitPeriod = when(safeAs<string>(step.options?.waitPeriod), String);

  if (!waitTimes || !waitPeriod) {
    return "Wait";
  }

  return `Wait ${waitTimes} ${plural(titleCase(waitPeriod), waitTimes)}`;
};

const WorkflowStepEditorWait = ({ step }: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const handleChange = useCallback(
    ({
      waitTimes,
      waitPeriod,
    }: Partial<{ waitTimes: number; waitPeriod: string }>) => {
      mutate(
        omitFalsey([
          !!waitTimes
            ? asMutation(
                { field: "options.waitTimes", type: "number" },
                Math.max(waitTimes, 0)
              )
            : undefined,
          waitPeriod
            ? asMutation(
                { field: "options.waitPeriod", type: "text" },
                waitPeriod
              )
            : undefined,
          asMutation(
            { field: "name", type: "text" },
            toWaitLabel({
              options: {
                waitTimes: waitTimes || step.options?.waitTimes,
                waitPeriod: waitPeriod || step.options?.waitPeriod,
              },
            })
          ),
        ])
      );
    },
    [step]
  );

  return (
    <>
      <Field label="Wait">
        <SpaceBetween gap={6}>
          <div className={styles.numberInput}>
            <TextInput
              value={when(step.options?.waitTimes, String) || "0"}
              updateOn="change"
              onChange={(v) => handleChange({ waitTimes: parseInt(v, 10) })}
              inputType="number"
            />
          </div>
          <FillSpace direction="horizontal">
            <ButtonGroup fit="container">
              {map(["hour", "day", "week", "month"], (period) => (
                <SplitButton
                  key={period}
                  size="small"
                  fit="container"
                  selected={step.options?.waitPeriod === period}
                  onClick={() => handleChange({ waitPeriod: period })}
                >
                  {plural(
                    titleCase(period),
                    when(step.options?.waitTimes, Number) ?? 2
                  )}
                </SplitButton>
              ))}
            </ButtonGroup>
          </FillSpace>
        </SpaceBetween>
      </Field>
    </>
  );
};

const WorkflowStepEditorControl = ({ step }: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);

  return (
    <>
      <Field label="Required paths to complete">
        <FillSpace>
          <ButtonGroup fit="container">
            <SplitButton
              size="small"
              fit="container"
              selected={!!step.options?.all}
              onClick={() =>
                mutate([
                  asMutation({ field: "options.all", type: "boolean" }, true),
                  asMutation({ field: "name", type: "text" }, "Wait All"),
                ])
              }
            >
              All
            </SplitButton>

            <SplitButton
              size="small"
              fit="container"
              selected={
                !step.options?.all && (step.options?.atLeast || 1) === 1
              }
              onClick={() =>
                mutate([
                  asMutation({ field: "options.all", type: "boolean" }, false),
                  asMutation({ field: "name", type: "text" }, "Wait Any"),
                ])
              }
            >
              Any
            </SplitButton>
          </ButtonGroup>
        </FillSpace>
      </Field>
    </>
  );
};

const WorkflowStepEditorCondition = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const variables = useVariables(workflow, allSteps);
  const sourceEntity = useLazyEntity(safeAs<ID>(step.options?.sourceEntity));
  const sourceVar = useMemo(
    () => find(variables, (p) => p.field === step.options?.sourceVar),
    [step.options?.sourceVar, variables]
  );
  const filteringOnType = useMemo(() => {
    if (sourceEntity?.source.type === "workflow_step") {
      return safeAs<WorkflowStep>(sourceEntity)?.options
        ?.entity as Maybe<EntityType>;
    }

    if (sourceVar) {
      return justOne(sourceVar?.options?.references);
    }

    return sourceEntity?.source.type;
  }, [step.options?.sourceEntity, step.options?.sourceVar, variables]);

  const filterSource = useMemo(
    () =>
      filteringOnType
        ? ({ type: filteringOnType, scope: step.source.scope } as DatabaseID)
        : undefined,
    [filteringOnType, step.source.scope]
  );
  const props = useLazyProperties(filterSource);
  const getProp = useMemo(() => maybeLookup(props, (p) => p.field), [props]);
  const filter = useMemo(
    () =>
      (getPropertyValue(step, {
        field: "options.filter",
        type: "json",
      })?.json as FilterQuery) || {
        and: [],
      },
    [step.options?.filter]
  );

  return (
    <>
      <Field label="Check if work">
        <WithVariableSelect
          value={sourceVar}
          allowed="relation"
          options={variables}
          onChange={(v) =>
            mutate(
              asMutation({ field: "options.sourceVar", type: "text" }, v?.field)
            )
          }
        >
          <GlobalEntitySelect
            value={when(safeAs<string>(step.options?.sourceEntity), toRef)}
            type={"workflow_step"}
            className={{ trigger: styles.control }}
            allowed={["workflow", "workflow_step"]}
            templates={!!workflow.template}
            scope={workflow.source.scope}
            showOtherTeams={false}
            onChange={(v) =>
              mutate(
                asMutation(
                  { field: "options.sourceEntity", type: "relation" },
                  when(v?.id, toRef)
                )
              )
            }
          />
        </WithVariableSelect>
      </Field>

      {filterSource && (
        <Field label="Passes condition">
          <Menu className={cx(styles.control, styles.filterMenu)}>
            <NestedPropertyFilter
              filter={filter}
              definition={getProp}
              onChanged={(f) =>
                mutate(
                  asMutation(
                    { field: "options.filter", type: "json" },
                    f as Maybe<JsonObject>
                  )
                )
              }
              source={filterSource}
            />
          </Menu>
        </Field>
      )}

      {step.options?.filter && (
        <Field>
          <CheckMenuItem
            checked={safeAs<boolean>(step.options?.keepChecking) ?? false}
            text="Keep checking"
            onChecked={(v) =>
              mutate(
                asMutation(
                  { field: "options.keepChecking", type: "boolean" },
                  v
                )
              )
            }
          />
        </Field>
      )}
    </>
  );
};

const WorkflowStepEditorUpdate = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const variables = useVariables(workflow, allSteps);
  const sourceEntity = useLazyEntity(safeAs<ID>(step.options?.sourceEntity));
  const sourceVar = useMemo(
    () => find(variables, (p) => p.field === step.options?.sourceVar),
    [step.options?.sourceVar, variables]
  );
  const updateingOnType = useMemo(() => {
    if (sourceEntity?.source.type === "workflow_step") {
      return safeAs<WorkflowStep>(sourceEntity)?.options
        ?.entity as Maybe<EntityType>;
    }

    if (step?.options?.sourceVar) {
      const varr = find(variables, (p) => p.field === step.options?.sourceVar);
      return justOne(varr?.options?.references);
    }

    return sourceEntity?.source.type;
  }, [step.options?.sourceEntity, step.options?.sourceVar, variables]);

  const updateSource = useMemo(
    () =>
      updateingOnType
        ? ({ type: updateingOnType, scope: step.source.scope } as DatabaseID)
        : undefined,
    [updateingOnType, step.source.scope]
  );

  return (
    <>
      <Field label="Work to be updated">
        <WithVariableSelect
          value={sourceVar}
          allowed={["relation", "relations"]}
          options={variables}
          onChange={(v) =>
            mutate(
              asMutation({ field: "options.sourceVar", type: "text" }, v?.field)
            )
          }
        >
          <GlobalEntitySelect
            value={when(safeAs<string>(step.options?.sourceEntity), toRef)}
            type={"workflow_step"}
            className={{ trigger: styles.control }}
            allowed={["workflow", "workflow_step"]}
            templates={!!workflow.template}
            scope={workflow.source.scope}
            showOtherTeams={false}
            onChange={(v) =>
              mutate(
                asMutation(
                  { field: "options.sourceEntity", type: "relation" },
                  when(v?.id, toRef)
                )
              )
            }
          />
        </WithVariableSelect>
      </Field>

      {updateSource && (
        <Field label="Make changes">
          <TemplateConfigure
            template={undefined}
            overrides={safeAs<PropertyValueRef[]>(step.overrides)}
            field="overrides"
            variables={variables}
            source={updateSource}
            onChange={mutate}
          />
        </Field>
      )}
    </>
  );
};

const WorkflowStepEditorFind = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const findingType = useMemo(
    () => safeAs<EntityType>(step.options?.entity),
    [step?.options?.entity]
  );
  const variables = useVariables(workflow, allSteps);

  const [filterWithinValue, filterVariable] = useReferencedVariable(
    safeAs<string>(step.options?.within),
    variables
  );

  const filterSource = useMemo(
    () =>
      findingType
        ? ({
            type: findingType,
            scope: filterWithinValue.text || toBaseScope(step.source.scope),
          } as DatabaseID)
        : undefined,
    [findingType, filterWithinValue, step.source.scope]
  );
  const props = useFilterableProperties(filterSource);
  const getProp = useMemo(() => maybeLookup(props, (p) => p.field), [props]);

  const filter = useMemo(
    () =>
      (getPropertyValue(step, {
        field: "options.filter",
        type: "json",
      })?.json as FilterQuery) || {
        and: [],
      },
    [step.options?.filter]
  );

  const outputVariable = useMemo(() => step.outputs?.[0], [step.outputs]);
  const toOutputMutation = useCallback(
    (
      changes: Partial<{
        field: string;
        type: "relation" | "relations";
        references: EntityType;
      }>
    ) =>
      asMutation({ field: "outputs", type: "json" }, [
        {
          field:
            changes.field ||
            step.outputs?.[0]?.field ||
            step.options?.entity ||
            "default",
          type:
            changes?.type ||
            step.outputs?.[0]?.type ||
            (step.options?.limit === 1 ? "relation" : "relations"),
          options: {
            references:
              changes.references ||
              step.outputs?.[0]?.options?.references ||
              step.options?.entity,
          },
        },
      ]),
    [step.outputs, step.options?.entity]
  );

  useEffect(() => {
    if (!step.outputs?.length) {
      mutate(toOutputMutation({}));
    }
  }, []);

  return (
    <>
      <Field label="Find">
        <EntityTypeSelect
          value={step.options?.entity as Maybe<EntityType>}
          scope={step.source.scope}
          additional={useConst(["note"])}
          plural={false}
          onChange={(v) =>
            mutate(asMutation({ field: "options.entity", type: "text" }, v))
          }
        />
      </Field>

      {filterSource && (
        <>
          <Field label="Located in">
            <WithVariableSelect
              value={filterVariable}
              options={variables}
              allowed={["relation"]}
              onChange={(v) =>
                v &&
                mutate(
                  asMutation(
                    { field: "options.within", type: "text" },
                    toVarReference(v)
                  )
                )
              }
            >
              <LocationSelect
                className={{ trigger: styles.control }}
                location={filterSource?.scope}
                onChange={(v) =>
                  mutate(
                    asMutation({ field: "options.within", type: "text" }, v)
                  )
                }
                source={filterSource}
              />
            </WithVariableSelect>
          </Field>

          <Field label="Matching filter">
            <Menu className={cx(styles.control, styles.filterMenu)}>
              <NestedPropertyFilter
                filter={filter}
                definition={getProp}
                onChanged={(f) =>
                  mutate(
                    asMutation(
                      { field: "options.filter", type: "json" },
                      f as Maybe<JsonObject>
                    )
                  )
                }
                source={filterSource}
              />
            </Menu>
          </Field>
        </>
      )}

      <Field label="Limit to">
        {/* Field > * css causing some issues with button immediately beneath */}
        <div>
          <ButtonGroup fit="container">
            <SplitButton
              selected={step.options?.limit === 1}
              onClick={() =>
                mutate([
                  asMutation({ field: "options.limit", type: "number" }, 1),
                  toOutputMutation({ type: "relation" }),
                ])
              }
            >
              One
            </SplitButton>
            <SplitButton
              selected={step.options?.limit !== 1}
              onClick={() =>
                mutate([
                  asMutation({ field: "options.limit", type: "number" }, 100),
                  toOutputMutation({ type: "relation" }),
                ])
              }
            >
              Multiple
            </SplitButton>
          </ButtonGroup>
        </div>
      </Field>

      {step.options?.limit === 1 && (
        <Field label="Sort by">
          {/* TODO: Refactor SortByOptionsMenu from view-options-menu so that it can be reused here...  */}
          <Button size="small">...</Button>
        </Field>
      )}

      <Field label={"Save as variable"}>
        {!!outputVariable && (
          <TextInput
            value={outputVariable.field ? `@${outputVariable.field}` : ""}
            updateOn="blur"
            placeholder="@"
            onChange={(t) =>
              mutate(toOutputMutation({ field: t?.replace(/^@/, "") }))
            }
          />
        )}
      </Field>
    </>
  );
};

const WorkflowStepEditorAI = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const mutateWorkflow = useUpdateEntity(workflow.id);
  const variables = useVariables(workflow, allSteps);

  return (
    <VStack gap={20}>
      <Field label="Prompt">
        <ColoredSection>
          <DocumentEditor
            newLineSpace="large"
            autoFocus={!step?.options?.prompt}
            placeholder="Write instructions for AI to follow..."
            scope={step.source.scope}
            content={
              getPropertyValue(step, {
                field: "options.prompt",
                type: "rich_text",
              })?.rich_text
            }
            onChanged={(c) =>
              mutate(
                asMutation({ field: "options.prompt", type: "rich_text" }, c)
              )
            }
            variables={variables}
            onNewVariable={(v) =>
              mutateWorkflow(
                asMutation(
                  { field: "inputs", type: "json" },
                  safeAs<JsonArray>([...(workflow.inputs || []), v])
                )
              )
            }
          />
        </ColoredSection>
      </Field>

      <Field label="Outputs" help="Variables you want AI to fill out.">
        <VariableList
          variables={step.outputs}
          scope={step.source.scope}
          onChanged={(vars) =>
            mutate(
              asMutation(
                { field: "outputs", type: "json" },
                safeAs<JsonArray>(vars)
              )
            )
          }
        />
      </Field>
    </VStack>
  );
};

const WorkflowStepEditorMessage = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const variables = useVariables(workflow, without(allSteps, step));

  const outputVariable = useMemo(() => step.outputs?.[0], [step.outputs]);
  const [threadValue, threadVariable] = useReferencedVariable(
    safeAs<string>(step.options?.thread),
    variables
  );
  const handleToggleOutputVariable = useCallback(
    (output: boolean) => {
      mutate(
        asMutation(
          { field: "outputs", type: "json" },
          output
            ? [
                {
                  field: "message",
                  type: "relation",
                  options: { references: "note" },
                },
              ]
            : []
        )
      );
    },
    [step.outputs, step.options?.entity]
  );

  const [channel, setChannel] = useState(safeAs<string>(step.options?.channel));
  const [thread, setThread] = useState<Maybe<string>>(
    threadValue?.text || undefined
  );

  const setChannelOptions = useCallback(
    ({ channel, thread }: { channel?: string; thread?: string }) => {
      setChannel(channel);
      setThread(thread);

      mutate(
        omitFalsey([
          channel
            ? asMutation({ field: "options.channel", type: "text" }, channel)
            : undefined,
          thread
            ? asMutation({ field: "options.thread", type: "text" }, thread)
            : undefined,
        ])
      );
    },
    [channel, thread]
  );

  return (
    <VStack gap={20}>
      <Field label="Message">
        <ColoredSection>
          <DocumentEditor
            variables={variables}
            newLineSpace="small"
            autoFocus={!step?.options?.prompt}
            placeholder="Write your message..."
            content={
              getPropertyValue(step, {
                field: "options.message",
                type: "rich_text",
              })?.rich_text
            }
            onChanged={(c) =>
              mutate(
                asMutation({ field: "options.message", type: "rich_text" }, c)
              )
            }
          />
        </ColoredSection>
      </Field>

      <Field label="Send via">
        <HStack>
          <ButtonGroup fit="container">
            <SplitButton
              fit="container"
              size="small"
              selected={step.options?.via === "email"}
              onClick={
                () => showError("Coming soon...")
                // mutate(
                //   asMutation({ field: "options.via", type: "text" }, "email")
                // )
              }
            >
              Email
            </SplitButton>
            <SplitButton
              fit="container"
              size="small"
              selected={step.options?.via === "slack"}
              onClick={() =>
                mutate(
                  asMutation({ field: "options.via", type: "text" }, "slack")
                )
              }
            >
              Slack
            </SplitButton>
            <SplitButton
              fit="container"
              size="small"
              selected={step.options?.via === "sms"}
              onClick={
                () => showError("Coming soon...")
                // mutate(
                //   asMutation({ field: "options.via", type: "text" }, "sms")
                // )
              }
            >
              SMS
            </SplitButton>
          </ButtonGroup>
        </HStack>
      </Field>

      {step.options?.via === "slack" && (
        <Field label="Channel">
          <WithVariableSelect
            value={threadVariable}
            allowed={["relation"]}
            options={variables}
            onChange={(v) =>
              mutate(
                asMutation(
                  { field: "options.thread", type: "text" },
                  v && toVarReference(v)
                )
              )
            }
          >
            <SlackSelect
              className={styles.control}
              position="bottom-right"
              channel={channel}
              thread={thread}
              mode="thread"
              placeholder="Select a channel..."
              onChange={(c, t) => setChannelOptions({ channel: c, thread: t })}
            />
          </WithVariableSelect>
        </Field>
      )}

      <Field>
        <CheckMenuItem
          inset
          checked={!!safeAs<boolean>(step.outputs?.length)}
          onChecked={handleToggleOutputVariable}
          text="Save as variable"
        />

        {!!outputVariable && (
          <TextInput
            value={outputVariable.field}
            updateOn="blur"
            placeholder="Variable to save to..."
            onChange={(t) =>
              mutate(
                asMutation(
                  { field: "outputs", type: "json" },
                  safeAs<JsonArray>([{ ...outputVariable, field: t }])
                )
              )
            }
          />
        )}
      </Field>
    </VStack>
  );
};

const CreatedWorkPreview = ({
  work,
  onOpen,
}: {
  work: Ref;
  onOpen: Fn<Maybe<Ref>, void>;
}) => {
  const entity = useLazyEntity(work.id);
  const blacklist = useConst(["refs.workflows", "refs.fromWorkflow"]);

  if (entity) {
    return (
      <EntityPreview
        entity={entity}
        onOpen={onOpen}
        propBlacklist={blacklist}
      />
    );
  }

  return <RelationLabel relation={work} onClick={() => onOpen(work)} />;
};

export const WorkflowEditorPage = ({ id }: { id: ID }) => {
  const workflow = useLazyEntity<"workflow">(id);
  const pageId = usePageId();
  const [page] = useRegisterPage(id, workflow);
  const goTo = useGoTo();

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, workflow?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(id, pageId);

  if (!workflow) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!workflow} title={workflow.name}>
      <StackContainer>
        {!workflow.template ? (
          <SmartBreadcrumbSheet />
        ) : (
          <SmartBreadcrumbSheet
            ignorePath={true}
            onClick={() =>
              goTo(
                location.pathname?.replace(`/${workflow.id || id}/builder`, "")
              )
            }
          />
        )}
        <WorkflowEditorPane id={id} item={workflow} />
      </StackContainer>
    </AppPage>
  );
};

const useReferencedVariable = (
  value: Maybe<string>,
  variables: VariableDef[]
) => {
  const valueRef = useMemo(() => asFormulaValue(value), [value]);
  const variable = useMemo(
    () =>
      when(
        valueRef?.formula && extractVarReference(valueRef.formula),
        (field) => find(variables, { field })
      ),
    [variables, valueRef]
  );
  return [valueRef, variable] as const;
};
