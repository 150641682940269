import { isArray, isString } from "lodash";

import { Json, JsonObject } from "@api";

import { asJSON } from "@utils/json";

// Extracts JSON from a markdown code block
const extractJsonMarkdown = (markdown: string): string => {
  const match = markdown.match(/```json\n([\s\S]+?)\n```/);
  return match ? match[1] : markdown;
};

export const parseJsonResponse = (response: string | Json): Json => {
  if (isString(response)) {
    return asJSON(extractJsonMarkdown(response));
  }
  return response;
};

export const asJsonObject = (json: Json): JsonObject => {
  if (isArray(json)) {
    return json[0] as JsonObject;
  }
  return json;
};
