import { Editor } from "@tiptap/core";
import { reduce, some } from "lodash";

import { Entity, isPerson, toTitleOrName } from "@api";

import { toMentionName } from "@state/persons/utils";

import { BASE_PATH } from "@utils/link";
import { Maybe, safeAs } from "@utils/maybe";
import { toLink } from "@utils/navigation";
import { mapAll } from "@utils/promise";

export const isFocused = (editor: Editor) =>
  editor.view.hasFocus() ||
  some(
    editor.state.plugins,
    (p) =>
      p.getState(editor.state)?.active || safeAs<{ active: boolean }>(p)?.active
  ) ||
  // TODO: Remove, as I don't think this works...
  !!editor.state.tr.getMeta("plugin-focused");

export const getExtensionOptions = (name: string, editor: Editor) =>
  editor.extensionManager.extensions.find((ext) => ext.name === name)?.options;

// For passing html out to outside world
export async function withExternalLinks(
  html: string,
  get: (id: string) => Promise<Maybe<Entity>>
): Promise<string> {
  // Updated regex to capture all attributes while ensuring data-mention-id is present
  const linkRegex =
    /<a ([^>]*?)data-mention-id="([a-z]+_[^"]+)"([^>]*?)>([^<]+)<\/a>/g;
  const matches = [...html.matchAll(linkRegex)];

  const replacements = await mapAll(
    matches,
    async ([match, preMentionAttrs, mentionId, postMentionAttrs, content]) => {
      try {
        const thing = await get(mentionId);
        if (!thing) return match;

        // Combine and deduplicate attributes
        const href = toLink(thing) || `${BASE_PATH}/${mentionId}`;
        const textContent =
          (isPerson(thing) ? toMentionName(thing) : toTitleOrName(thing)) ||
          thing.source.type;

        // Preserve existing attributes while ensuring no duplicates
        const combinedAttrs = new Map();

        // Parse and add existing attributes
        const allAttrs = (preMentionAttrs + " " + postMentionAttrs).trim();
        const attrMatches = [
          ...allAttrs.matchAll(/(\w+(?:-\w+)*)(?:="([^"]*)")?/g),
        ];
        attrMatches.forEach(([, name, value]) => {
          if (value !== undefined) {
            combinedAttrs.set(name, value);
          } else if (name) {
            combinedAttrs.set(name, ""); // Handle boolean attributes
          }
        });

        // Set/override core attributes
        combinedAttrs.set("data-mention-id", mentionId);
        combinedAttrs.set("href", href);

        // Build the final attributes string
        const attributesStr = Array.from(combinedAttrs.entries())
          .map(([name, value]) => (value ? `${name}="${value}"` : name))
          .join(" ");

        return `<a ${attributesStr}>${textContent}</a>`;
      } catch (error) {
        console.error(`Failed to fetch mention name for ${mentionId}:`, error);
        return match;
      }
    }
  );

  return reduce(
    matches,
    (acc, [match], i) => acc.replace(match, replacements[i]),
    html
  );
}
