import { forEach, reduce } from "lodash";

import { Entity, HasLocation, Ref } from "@api";

import { pushDirty } from "@utils/array";
import { uniqRefs } from "@utils/relation-ref";
import { fromScope, takeUpTo } from "@utils/scope";

export type NestedItems = {
  [location: string]: (Entity | Ref)[];
};

export function toNestedItems(entities: HasLocation[]): NestedItems {
  const initialMap: NestedItems = { "": [] };

  return reduce(
    entities,
    (acc, entity) => {
      const location = entity.location || entity.source.scope;

      forEach(fromScope(location), (parent, index) => {
        const pLoc = takeUpTo(location, parent);
        acc[pLoc] = uniqRefs([...(acc[pLoc] || []), { id: parent }], "first");
      });

      acc[location] = uniqRefs(pushDirty(acc[location] || [], entity), "last");

      return acc;
    },
    initialMap
  );
}
