import { reduce } from "lodash";
import { atomFamily, selector, selectorFamily } from "recoil";

import { ID, Job } from "@api";

import { appendKey, localStorageEffect } from "@state/local-storage-effect";
import { SimpleStoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { ISODate, usePointDate } from "@utils/date-fp";
import { Maybe } from "@utils/maybe";
import { maybeValues } from "@utils/object";
import { minutesAgo } from "@utils/time";
import { clearJob, setJob } from "./actions";
import { isDefault } from "@utils/recoil";

export type JobStore = SimpleStoreState<Job> & {
  lastChecked: Maybe<ISODate>;
};

export const WorkspaceJobStoreAtom = atomFamily<JobStore, ID>({
  key: "WorkspaceJobStoreAtom",
  default: { dirty: [], lookup: {}, lastChecked: undefined },
  effects: (wid) => [
    localStorageEffect<JobStore>({
      key: appendKey("traction.store.job", wid),
      props: ["lookup", "dirty", "lastChecked"],
      clean: (state) => ({
        ...state,
        lookup: reduce(
          state.lookup,
          (acc, v) =>
            !v ||
            ["completed", "failed"]?.includes(v.status || "never") ||
            (v.status === "running" &&
              usePointDate(v.lockedAt, (d) => minutesAgo(d) > 10))
              ? acc
              : { ...acc, [v.id]: v },
          {}
        ),
      }),
    }),
  ],
});

export const JobStoreAtom = WorkspaceWrappedAtom(
  "JobStoreAtom",
  WorkspaceJobStoreAtom
);

export const JobAtom = selectorFamily<Maybe<Job>, ID>({
  key: "JobAtom",
  get:
    (id) =>
    ({ get }) => {
      const store = get(JobStoreAtom);
      return store.lookup[id];
    },
  set:
    (id) =>
    ({ set }, newValue) => {
      set(
        JobStoreAtom,
        !newValue || isDefault(newValue) ? clearJob(id) : setJob(newValue)
      );
    },
});

export const allJobs = selector({
  key: "allJobs",
  get: ({ get }) => {
    const store = get(JobStoreAtom);
    return maybeValues(store.lookup, (v) => v.status !== "completed");
  },
});
