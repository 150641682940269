import { filter, find, flatMap, keys, map, reduce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Entity,
  EntityType,
  hasStatus,
  PropertyRef,
  Status,
  Update,
} from "@api";
import { EntityMap } from "@api/mappings";

import { useGetAnyProperty, useInflateStatus } from "@state/databases";
import { useNestedEntities } from "@state/generic";
import { WorkflowContext } from "@state/workflows";

import { omitEmpty } from "@utils/array";
import { newID } from "@utils/id";
import { maybeMap, when } from "@utils/maybe";
import { maybeValues, set } from "@utils/object";
import { asMutation, asUpdate } from "@utils/property-mutations";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { Dialog } from "@ui/dialog";
import { FillSpace } from "@ui/flex";
import { Check, SpinnerIcon } from "@ui/icon";
import { Fields } from "@ui/input";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { CheckMenuItem, MenuItem } from "@ui/menu-item";
import { StatusTag } from "@ui/tag";

interface ParentStartDialogProps {
  entity: Entity;
  toParentProp: PropertyRef;
  onCollected: (collected: Update<Entity>[]) => void;
  onCancelled: () => void;
  context: WorkflowContext<Entity>;
}

export function ParentStartDialog({
  entity,
  toParentProp,
  onCollected,
  onCancelled,
  context: { props },
}: ParentStartDialogProps) {
  const [statuses, setStatuses] = useState<Partial<Record<EntityType, Status>>>(
    {}
  );
  const [opts, setOpts] = useState<Partial<Record<EntityType, boolean>>>({});
  const getProp = useGetAnyProperty();
  const getStatus = useInflateStatus();
  const { children: allChildren, loading } = useNestedEntities(entity);
  const children = useMemo(
    () =>
      reduce(
        keys(allChildren) as EntityType[],
        (res, key) => {
          const draftKids = filter(
            allChildren?.[key] as Entity[],
            (c) =>
              hasStatus(c) &&
              !!c.status?.id &&
              getStatus(c.status.id, c.source)?.group === "planning"
          );
          return draftKids?.length ? { ...res, [key]: draftKids } : res;
        },
        {} as EntityMap
      ),
    [allChildren, getProp]
  );
  const hasChildren = useMemo(
    () => maybeValues(children as Record<EntityType, Entity[]>)?.length > 0,
    [children]
  );

  const onSubmit = useCallback(() => {
    const transaction = newID();
    onCollected(
      omitEmpty([
        // Mark the parent entity as in-progress
        when(statuses[entity.source.type], (status) =>
          asUpdate(
            entity,
            [asMutation({ field: "status", type: "status" }, status)],
            transaction
          )
        ),

        // Move all tasks that are in draft to their ready status
        ...flatMap(keys(children) as EntityType[], (key) =>
          maybeMap(children[key] as Entity[], (c) =>
            when(
              statuses[key],
              (status) =>
                ({
                  id: c.id,
                  source: c.source,
                  method: "update",
                  transaction,
                  changes: [
                    asMutation({ field: "status", type: "status" }, status),
                  ],
                } as Update<Entity>)
            )
          )
        ),
      ])
    );
  }, [children, statuses, onCollected]);

  useEffect(() => {
    // Get first non-draft status
    setStatuses(
      reduce(
        [entity.source.type, ...keys(children)] as EntityType[],
        (acc, k) => {
          const status = when(
            k === entity.source.type
              ? entity.source
              : children?.[k]?.[0]?.source,
            (source) =>
              find(
                getProp({ field: "status", type: "status" }, source)?.values
                  ?.status,
                (s: Status) => s.group !== "planning"
              )
          );

          return set(acc, k, status);
        },
        {} as Partial<Record<EntityType, Status>>
      )
    );
  }, [getProp, children]);

  useEffect(() => {
    // Default to opt in for all children
    setOpts(
      reduce(
        keys(children),
        (acc, k) => ({ ...acc, [k]: true }),
        {} as Partial<Record<EntityType, boolean>>
      )
    );
  }, [children]);

  return (
    <Dialog
      title={`Start ${entity.source.type}`}
      description={`Mark this ${entity.source.type} and all nested work as ready to start?`}
      onDismiss={onCancelled}
      actions={
        <>
          <Button onClick={() => onCancelled()}>Cancel</Button>
          <Button variant="primary" onClick={onSubmit}>
            Yes, let's go!
          </Button>
        </>
      }
    >
      <Container stack="vertical" padding="none" gap={20}>
        <FillSpace direction="vertical" fit="container">
          <Container gap={20} stack="vertical" fit="container" padding="none">
            <Fields>
              <Menu>
                <MenuGroup>
                  <CheckMenuItem checked={true} disabled>
                    Mark this {entity.source.type} as{" "}
                    {when(statuses?.[entity.source.type], (s) => (
                      <StatusTag status={s} />
                    )) || "started"}
                  </CheckMenuItem>

                  {!loading && !hasChildren && (
                    <MenuItem disabled icon={Check}>
                      No nested work
                    </MenuItem>
                  )}
                  {loading && (
                    <MenuItem disabled icon={SpinnerIcon}>
                      Collecting nested work...
                    </MenuItem>
                  )}

                  {map(children, (values, type: EntityType) => (
                    <CheckMenuItem
                      key={type}
                      checked={opts[type as EntityType] ?? false}
                      onChecked={() =>
                        setOpts((d) => ({
                          ...d,
                          [type]: !(opts[type as EntityType] ?? false),
                        }))
                      }
                    >
                      Mark {values?.length || 1} {type} as{" "}
                      {when(statuses?.[type], (s) => (
                        <StatusTag status={s} />
                      )) || "ready to start"}
                    </CheckMenuItem>
                  ))}
                </MenuGroup>
              </Menu>
            </Fields>
          </Container>
        </FillSpace>
      </Container>
    </Dialog>
  );
}
