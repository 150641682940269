export type TZDate = `${number}-${number}-${number}Z`;
export type TZDateTime =
  | `${number}-${number}-${number}T${number}:${number}:${number}Z`
  | `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`;

export type CalDate = `${number}-${number}-${number}`;
export type CalDateTime =
  | `${number}-${number}-${number}T${number}:${number}:${number}`
  | `${number}-${number}-${number}T${number}:${number}:${number}.${number}`;

export type ISODate = TZDate | TZDateTime | CalDate | CalDateTime;
export type JSDate = Date;
export type DateMode = "calendar" | "utc" | "local";

type Fn<T, R> = (input: T) => R;

const isISODate = (d: unknown): d is ISODate =>
  typeof d === "string" &&
  /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d+)?Z?)?$/.test(d);

const isCalDate = (d: ISODate): d is CalDate => d.length === 10;
const isTZDate = (d: ISODate): d is TZDate =>
  d.endsWith("Z") && d.length === 11;
const isJSDate = (d: ISODate | JSDate): d is JSDate => d instanceof Date;

const formatDate = (date: JSDate, mode: DateMode): ISODate => {
  const pad = (n: number) => n.toString().padStart(2, "0");
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const millis = date.getMilliseconds() ? `.${date.getMilliseconds()}` : "";

  return mode === "calendar"
    ? (`${year}-${month}-${day}` as CalDate)
    : mode === "utc"
    ? (`${year}-${month}-${day}T${hours}:${minutes}:${seconds}${millis}Z` as TZDateTime)
    : (`${year}-${month}-${day}T${hours}:${minutes}:${seconds}${millis}` as CalDateTime);
};

const toISODate = (date: JSDate, mode: DateMode): ISODate =>
  formatDate(date, mode);
const toJSDate = (d: ISODate, mode: DateMode): JSDate =>
  new Date(d + (mode === "utc" && !d.endsWith("Z") ? "Z" : ""));

const inTransaction = <T extends string | JSDate>(
  d: ISODate,
  mode: DateMode,
  fn: Fn<JSDate, T>
): T extends JSDate ? ISODate : string => {
  const result = fn(toJSDate(d, mode));
  return (result instanceof Date ? toISODate(result, mode) : result) as any;
};

export {
  inTransaction,
  isCalDate,
  isISODate,
  isJSDate,
  isTZDate,
  toISODate,
  toJSDate,
};
