import { Entity, HasTemplate } from "@api";

import { cx } from "@utils/class-names";
import { safeAs } from "@utils/maybe";

import { Sheet, SheetProps } from "@ui/sheet-layout";

import { SlidePaneManager } from "./entity-pane-manager";

import styles from "./entity-sheet.module.css";

export const EntitySheet = ({
  entity,
  children,
  className,
  ...props
}: SheetProps & { entity: Entity }) => {
  return (
    <Sheet {...props} className={cx(styles.entitySheet, className)}>
      <div className={styles.wrapper}>
        <div className={styles.content}>{children}</div>
        {!safeAs<HasTemplate>(entity)?.template && (
          <div className={styles.side}>
            <SlidePaneManager
              id={entity.id}
              entity={entity}
              className={styles.paneIcons}
            />
          </div>
        )}
      </div>
    </Sheet>
  );
};
