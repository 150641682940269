import { filter, map } from "lodash";
import { useMemo, useState } from "react";

import { Ref } from "@api";

import { useLazyEntities } from "@state/generic";
import { isFinished, useShowRunning } from "@state/workflow";

import { ensureMany, OneOrMany } from "@utils/array";
import { Fn, not } from "@utils/fn";
import { minus } from "@utils/math";
import { Maybe } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";

import { CollapsibleSection } from "@ui/collapsible-section";
import { VStack } from "@ui/flex";
import { ShowMoreMenuItem } from "@ui/menu-item";

import { render, useEngine } from "..";

export const WorkflowSection = ({
  items,
  onClick,
}: {
  items: Maybe<OneOrMany<Ref>>;
  onClick?: Fn<Ref, void>;
}) => {
  const pushTo = usePushTo();
  const refs = useMemo(() => ensureMany(items), [items]);
  const allWorkflows = useLazyEntities<"workflow">(refs);
  const engine = useEngine("workflow");
  const workflows = useShowRunning(allWorkflows);

  if (!allWorkflows?.length) {
    return <></>;
  }

  return (
    <CollapsibleSection
      labelSize="medium"
      count={workflows.visible?.length || 0}
      title="Workflows"
    >
      <VStack>
        {map(workflows.visible, (workflow) =>
          render(engine.asListCard, {
            key: workflow.id,
            item: workflow,
            onOpen: onClick || pushTo,
          })
        )}
        {workflows.hasMore && (
          <ShowMoreMenuItem
            count={workflows.moreCount}
            onClick={() => workflows.setShowAll(!workflows.showAll)}
            text={workflows.showAll ? "Hide completed" : "Show completed"}
          />
        )}
      </VStack>
    </CollapsibleSection>
  );
};
