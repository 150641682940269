import { Portal } from "@radix-ui/react-portal";
import { map } from "lodash";
import { useState } from "react";

import { Entity, HasTemplate } from "@api";

import { useNestedSource } from "@state/generic";
import { SystemPackages, useHasPackages } from "@state/packages";

import { cx } from "@utils/class-names";
import { safeAs, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { getRelationValue } from "@utils/property-refs";

import { Button } from "@ui/button";
import { RelatedMeetingsPane } from "@ui/engine/meeting";
import { RelatedProcessesPane } from "@ui/engine/process";
import { ScheduleInstancesPane } from "@ui/engine/schedule";
import { RelatedWorkflowsPane } from "@ui/engine/workflow";
import { VStack } from "@ui/flex";
import {
  ClockHistory,
  CounterIcon,
  EmojiIcon,
  Icon,
  QuestionCircle,
  Search,
  WorkflowFilled,
} from "@ui/icon";
import { EntityMessagesPane } from "@ui/messages-pane";
import { SideNav } from "@ui/page-layout";
import {
  PaneItem,
  PaneManager,
  PaneManagerContext,
  usePaneManagerState,
} from "@ui/pane-manager";
import { ResourcesPane } from "@ui/resources-pane";
import { SearchPane } from "@ui/search-pane";
import { SlideInSheet } from "@ui/sheet-layout";
import { TemplatePaneManager } from "@ui/template-pane-manager";

import styles from "./entity-pane-manager.module.css";

export const EntityPaneManager = ({
  id,
  entity,
}: {
  id: string;
  entity: Entity;
}) => {
  const pushTo = usePushTo();
  const fullLocation = useNestedSource(entity);
  const installed = useHasPackages(id, [
    SystemPackages.Processes,
    SystemPackages.Meetings,
    SystemPackages.Workflows,
  ]);

  return (
    <PaneManager size="full">
      <PaneItem id="search" title="Search" icon={Search}>
        <SearchPane parentId={id} onOpen={pushTo} />
      </PaneItem>

      {when(getRelationValue(entity, "refs.repeat"), (ref) => (
        <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
          <ScheduleInstancesPane schedule={ref} instanceId={id} />
        </PaneItem>
      ))}

      {fullLocation && (
        <PaneItem
          id="resources"
          title="Attachments"
          icon={<EmojiIcon emoji="🔗" />}
        >
          <ResourcesPane location={fullLocation.scope} />
        </PaneItem>
      )}

      <PaneItem id="messages" title="Messages" icon={<EmojiIcon emoji="💬" />}>
        <EntityMessagesPane entityId={id} />
      </PaneItem>

      {installed[SystemPackages.Meetings] && (
        <PaneItem
          id="meetings"
          title="Meetings"
          icon={<EmojiIcon emoji="📅" />}
        >
          <RelatedMeetingsPane entityId={id} />
        </PaneItem>
      )}

      {installed[SystemPackages.Workflows] && (
        <PaneItem id="workflows" title="Workflows" icon={WorkflowFilled}>
          <RelatedWorkflowsPane id={entity.id} />
        </PaneItem>
      )}

      {installed[SystemPackages.Processes] && (
        <PaneItem id="processes" title="Processes" icon={QuestionCircle}>
          <RelatedProcessesPane entityId={id} />
        </PaneItem>
      )}
    </PaneManager>
  );
};

export const SlidePaneManager = ({
  id,
  entity,
  className,
}: {
  id: string;
  entity: Entity;
  className?: string;
}) => {
  const state = usePaneManagerState();
  const [visible, setVisible] = useState(false);

  return (
    <PaneManagerContext.Provider value={state}>
      <SideNav className={cx(styles.rightNav, className)}>
        <VStack>
          {map(state.panes, ({ id, title, icon, count }) => (
            <div key={id} className={styles.sideButton}>
              <Button
                key={id}
                icon={icon}
                iconSize="medium"
                subtle
                onClick={() => setVisible(true)}
              />
              {!!count && (
                <Icon
                  className={styles.counter}
                  icon={<CounterIcon count={count} />}
                />
              )}
            </div>
          ))}
        </VStack>
        <Portal data-ignore-auto-close>
          <SlideInSheet
            size="secondary"
            height="container"
            className={styles.slideIn}
            visible={visible}
            setVisible={setVisible}
          >
            {!!safeAs<HasTemplate>(entity)?.template ? (
              <TemplatePaneManager id={id} entity={entity} />
            ) : (
              <EntityPaneManager id={id} entity={entity} />
            )}
          </SlideInSheet>
        </Portal>
      </SideNav>
    </PaneManagerContext.Provider>
  );
};
