import { Task } from "@api";

import { useMarkAsSeen } from "@state/generic";

import { useAppPageContext } from "@ui/app-page";
import { Container } from "@ui/container";
import { EntityProperties } from "@ui/entity-properties";
import { EntitySheet } from "@ui/entity-sheet";
import { VStack } from "@ui/flex";
import { TaskHeader } from "@ui/task-header";
import { TemplateBanner } from "@ui/template-banner";

import { PaneOpts } from "../types";

export function TaskPane({
  id,
  item,
  size,
  actions,
  className,
}: PaneOpts<Task>) {
  const pageId = useAppPageContext();

  // Mark the note as seen by current user
  useMarkAsSeen(id, pageId);

  if (!item) {
    return <></>;
  }

  return (
    <EntitySheet
      height="container"
      size={size || "primary"}
      className={className}
      entity={item}
    >
      <VStack fit="container" gap={30}>
        {item.template && <TemplateBanner />}
        <TaskHeader id={id} actions={actions} />
        <Container
          stack="vertical"
          gap={20}
          fit="container"
          padding="horizontal"
        >
          <EntityProperties entityId={id} />
        </Container>
      </VStack>
    </EntitySheet>
  );
}
