import { useMemo } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  ReactFlowProps,
  SelectionMode,
} from "reactflow";

import { cx } from "@utils/class-names";

import { Sitemap } from "@ui/icon";

import {
  DEFAULT_EDGE_OPTIONS,
  EDGE_TYPES,
  LiveConnectingLine,
  NODE_TYPES,
} from "./components";

import styles from "./styles.module.css";

type Props = ReactFlowProps & {
  showControls?: boolean;
  onResetLayout?: () => void;
};

export const Flow = ({
  nodes,
  edges,
  className,
  showControls = true,
  nodeTypes: _nodeTypes,
  edgeTypes: _edgeTypes,
  onResetLayout,
  children,
  ...rest
}: Props) => {
  const nodeTypes = useMemo(
    () => ({ ...NODE_TYPES, ..._nodeTypes }),
    [_nodeTypes]
  );
  const edgeTypes = useMemo(
    () => ({ ...EDGE_TYPES, ..._edgeTypes }),
    [_edgeTypes]
  );
  return (
    <div
      data-selectable-ignore-drags="true"
      className={cx(styles.flow, className)}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        selectionMode={SelectionMode.Full}
        fitView={true}
        fitViewOptions={{ maxZoom: 2, duration: 2 }}
        defaultEdgeOptions={DEFAULT_EDGE_OPTIONS}
        connectionLineComponent={LiveConnectingLine}
        {...rest}
        elementsSelectable={!!rest.onNodeClick}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
      >
        {children || (
          <>
            {showControls === true && (
              <Controls showInteractive={false}>
                {onResetLayout && (
                  <button
                    className={cx(
                      "react-flow__controls-button react-flow__controls-fitview",
                      styles.controlButton
                    )}
                    onClick={onResetLayout}
                  >
                    <Sitemap />
                  </button>
                )}
              </Controls>
            )}
            <Background
              color="var(--color-border)"
              variant={BackgroundVariant.Cross}
              gap={12}
              size={1.5}
            />
          </>
        )}
      </ReactFlow>
    </div>
  );
};
