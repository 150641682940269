import { reduce } from "lodash";

import { Job } from "@api";

import {
  clearSimpleStoreItems,
  removeSimpleStoreItem,
  setSimpleStoreItem,
  setSimpleStoreItems,
} from "@state/store";

import { ISODate, now } from "@utils/date-fp";

import { JobStore } from "./atoms";
import { composel } from "@utils/fn";

export const setJob = (job: Job) => (state: JobStore) =>
  setSimpleStoreItem(job.id, job)(state);

export const clearJob = (id: string) => (state: JobStore) =>
  removeSimpleStoreItem(id)(state);

export const setLastChecked = (date: ISODate) => (state: JobStore) => ({
  ...state,
  lastChecked: date,
});

export const clearLastChecked = () => (state: JobStore) => ({
  ...state,
  lastChecked: undefined,
});

// Setting jobs from server contains all active jobs
// If we don't clear then job that were completed by other people will stay in our store forever
// since they are not returned by the server
export const setJobs = (jobs: Job[]) => (state: JobStore) =>
  composel(
    clearSimpleStoreItems(),
    setSimpleStoreItems(jobs),
    setLastChecked(now())
  )(state);
