import { map, pick } from "lodash";
import { useCallback, useMemo } from "react";

import {
  DatabaseID,
  JsonArray,
  PropertyMutation,
  PropertyValueRef,
  Ref,
  VariableDef,
} from "@api";

import { useLazyProperties } from "@state/databases";

import {
  ensureMany,
  justOne,
  maybeLookup,
  OneOrMany,
  uniqBy,
} from "@utils/array";
import { Fn } from "@utils/fn";
import { equalsAny } from "@utils/logic";
import { Maybe, maybeMap, safeAs, when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";
import { asPropertyValueRef, isAnyText, toRef } from "@utils/property-refs";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { SpaceBetween, VStack } from "@ui/flex";
import { PlusIcon } from "@ui/icon";
import { PropertyValuesList } from "@ui/property-values-list";
import { ColoredSection } from "@ui/section";
import { TemplateSelect } from "@ui/select";
import { ScopedPropertySelect } from "@ui/select/property";
import { Text } from "@ui/text";

import styles from "./template-configure.module.css";

type Props = {
  template: Maybe<Ref>;
  overrides: Maybe<PropertyValueRef[]>;
  source: DatabaseID;
  field?: string;
  blacklist?: string[];
  onChange: Fn<OneOrMany<PropertyMutation>, void>;
  variables?: VariableDef[];
};

export const TemplateConfigure = ({
  template,
  overrides,
  source,
  field = "overrides",
  variables,
  blacklist,
  onChange,
}: Props) => {
  const props = useLazyProperties(source);
  const values = useMemo(() => {
    const getProp = maybeLookup(props, (p) => p.field);
    return maybeMap(overrides, (c) =>
      !equalsAny(c.field, blacklist || [])
        ? asPropertyValueRef(getProp(c.field) || c, c.value)
        : undefined
    );
  }, [props, overrides]);

  const updateOverride = useCallback(
    (change: OneOrMany<PropertyMutation>) => {
      onChange(
        asMutation(
          { field, type: "json" },
          safeAs<JsonArray>(
            maybeMap(overrides || [], (c) =>
              c.field === justOne(change)?.field
                ? when(justOne(change), (c) =>
                    pick(c, "type", "field", "value")
                  )
                : c
            )
          )
        )
      );
    },
    [overrides, onChange]
  );

  const addOverride = useCallback(
    (change: OneOrMany<PropertyMutation>) => {
      onChange(
        asMutation(
          { field, type: "json" },
          safeAs<JsonArray>(
            uniqBy(
              [...(overrides || []), ...ensureMany(change)],
              (c) => c.field,
              "last"
            )
          )
        )
      );
    },
    [overrides, onChange]
  );

  return (
    <ColoredSection className={styles.templateSection}>
      <VStack gap={4}>
        <Container
          padding="none"
          inset="horizontal"
          className={styles.templateSelect}
        >
          <TemplateSelect
            scope={source.scope}
            type={source.type}
            allowed={[source.type]}
            allowNew={false}
            value={template}
            onChange={(r) =>
              onChange(
                asMutation({ field: "useTemplate", type: "relation" }, toRef(r))
              )
            }
            closeOnSelect={true}
            placeholder="No base template..."
            className={{ trigger: styles.control }}
          />
        </Container>

        <Divider />

        <PropertyValuesList
          values={values}
          source={source}
          editable={true}
          propsEditable={false}
          inset={false}
          variables={variables}
          onMutate={updateOverride}
          className={styles.list}
        >
          <Container padding="none" inset="horizontal">
            <SpaceBetween>
              <ScopedPropertySelect
                type={source.type}
                scope={source.scope}
                portal={true}
                whitelist={(p) =>
                  p.field === "title" ||
                  p.type === "rich_text" ||
                  !(
                    isAnyText(p) ||
                    p.readonly === true ||
                    p.visibility === "hidden" ||
                    p.type === "checklist"
                  )
                }
                onChanged={(p) => p && addOverride(asMutation(p, undefined))}
              >
                <Button size="small" subtle icon={PlusIcon}>
                  <Text subtle>Fields</Text>
                </Button>
              </ScopedPropertySelect>

              <Button
                subtle
                size="small"
                onClick={() =>
                  onChange(asMutation({ field, type: "json" }, []))
                }
              >
                <Text subtle>Clear</Text>
              </Button>
            </SpaceBetween>
          </Container>
        </PropertyValuesList>
      </VStack>
    </ColoredSection>
  );
};
