import { filter as filter_, flatMap, groupBy, map } from "lodash";
import { useMemo, useState } from "react";

import {
  Entity,
  PropertyDef,
  PropertyValue,
  SingleFilterQuery,
  Status,
} from "@api";

import { ensureArray, ensureMany } from "@utils/array";
import { isEmptyFilter, toFilterOption } from "@utils/filtering";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { toLabel } from "@utils/status";

import { MultiSelect } from "@ui/select";

import { OpHeader } from "./op-header";
import { TriggerButton } from "./trigger-button";

export interface Props {
  filter: SingleFilterQuery;
  definition: PropertyDef<Entity>;
  onChanged?: Fn<Maybe<SingleFilterQuery>, void>;
  editing?: boolean;
}

export default function PropertyFilterStatus({
  filter,
  definition,
  onChanged,
  editing,
}: Props) {
  const [open, setOpen] = useState(false);
  const filterType = filter.type;

  if (filterType !== "status") {
    throw new Error("Can only use StatusPropertyFilter with status types.");
  }

  const options = useMemo(() => {
    const groups = groupBy(definition.values?.[filterType], (v) => v.group);
    return flatMap(groups, (vs, group) => [
      {
        id: group,
        name: toLabel({ group } as Partial<Status>),
        value: { status: { group } } as PropertyValue,
      },
      ...map(vs, (v) => ({
        ...toFilterOption(definition, v),
        indent: 2,
      })),
    ]);
  }, [definition]);

  const selected = useMemo(() => {
    const ids = map(
      filter.values?.[filterType] || ensureArray(filter.value?.[filterType]),
      (v) => v.id || v.group
    );
    return filter_(options, (o) => ids?.includes(o.id));
  }, [options, filter]);

  const isEmpty = isEmptyFilter(filter);

  return (
    <MultiSelect
      open={open}
      setOpen={setOpen}
      value={selected}
      options={!isEmpty ? options : []}
      searchable={!isEmpty}
      defaultOpen={editing}
      portal={true}
      closeOnBlur={false}
      onChange={(values) => {
        onChanged?.({
          ...filter,
          value: undefined,
          values: {
            [filterType]: flatMap(values, (v) =>
              map(ensureMany(v?.value?.[filterType]), (s) => s)
            ),
          },
        });
      }}
      placeholder={`Filter values...`}
      header={
        <OpHeader
          filter={filter}
          prop={definition}
          onChanged={onChanged}
          setOpen={setOpen}
        />
      }
    >
      <TriggerButton filter={filter} prop={definition} onChanged={onChanged} />
    </MultiSelect>
  );
}
