import { forEach } from "lodash";

import { RichText, Vars } from "@api";

import { Maybe, when } from "./maybe";
import { toHtml } from "./property-refs";

export const replaceVariables = (rt: Maybe<RichText>, variables: Vars) => {
  // If no rich text or no variables, return as-is
  if (!rt?.html || !variables?.length) return rt || { html: "" };

  // Create a temporary div to manipulate the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = rt.html;

  // Find all variable placeholders
  const varElements = tempDiv.querySelectorAll("[data-var-field]");

  // Iterate through variable placeholders
  forEach(varElements, (el) => {
    const field = el.getAttribute("data-var-field");
    const type = el.getAttribute("data-var-type") || "text";

    // Find matching variable
    const matchingVar = variables.find(
      (v) => v.field === field && v.type === type
    );
    const html = when(matchingVar, toHtml);

    if (html) {
      // Replace based on variable type
      el.outerHTML = html;
    }
  });

  // Return updated rich text without the containing div
  return {
    html: tempDiv.innerHTML,
  };
};

export const extractVariables = (rt: Maybe<RichText>) => {
  if (!rt?.html) return [];

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = rt.html;

  const varElements = tempDiv.querySelectorAll("[data-var-field]");

  return Array.from(varElements).map((el) => ({
    field: el.getAttribute("data-var-field") || "",
    type: el.getAttribute("data-var-type") || "text",
    value: undefined,
  }));
};
