import { ReactNode } from "react";

import { ID, isOutcome, isTask, Outcome, Task } from "@api";

import { useLazyEntity, useUpdateEntity } from "@state/generic";

import { timeAgo } from "@utils/date";
import { fromPointDate } from "@utils/date-fp";
import { when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { now } from "@utils/now";
import { asMutation } from "@utils/property-mutations";

import { useAppPageContext } from "@ui/app-page";
import { Button } from "@ui/button";
import { CodeLabel } from "@ui/code-label";
import { Container } from "@ui/container";
import { EditableHeading } from "@ui/editable-heading";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { HStack, VStack } from "@ui/flex";
import { SubtasksIcon } from "@ui/icon";
import { SectionLabel } from "@ui/label";
import { RelationLabel, RelationText } from "@ui/relation-label";
import { TextSmall } from "@ui/text";
import { WorkflowActions } from "@ui/workflow-action-button";

import styles from "./task-header.module.css";

interface Props {
  id: ID;
  quickActions?: boolean;
  actions?: ReactNode;
}

export const TaskHeader = ({ id, quickActions = true, actions }: Props) => {
  const pageId = useAppPageContext();
  const entity = useLazyEntity(id);
  const pushTo = usePushTo();

  if (!!entity && !isTask(entity) && !isOutcome(entity)) {
    throw new Error("TaskHeader only supports tasks and outcomes.");
  }

  const parent = useLazyEntity(entity?.refs?.parent?.[0]?.id || "");

  const mutate = useUpdateEntity(entity?.id || "", pageId);

  if (!entity) {
    return <h1>Not found.</h1>;
  }

  return (
    <>
      <EntityHeaderBar padding="both" entity={entity} />

      <Container
        stack="vertical"
        className={styles.panel}
        gap={10}
        padding="horizontal"
      >
        <VStack gap={4}>
          <VStack gap={0}>
            {entity.refs?.parent?.length ? (
              <HStack>
                <CodeLabel code={(parent as Task | Outcome)?.code} />
                <SectionLabel icon={SubtasksIcon} />
                <CodeLabel code={entity?.code} />
              </HStack>
            ) : (
              <HStack>
                <CodeLabel code={entity?.code} />
              </HStack>
            )}

            <EditableHeading
              key={entity.id}
              size="h2"
              text={entity.title || ""}
              placeholder="Untitled"
              onChange={(t) =>
                t && mutate(asMutation({ field: "title", type: "text" }, t))
              }
            />
          </VStack>

          {entity.createdBy && (
            <TextSmall subtle>
              Created by{" "}
              <Button
                variant="link"
                onClick={() => when(entity.createdBy, pushTo)}
              >
                <RelationText
                  size="small"
                  className={styles.creator}
                  relation={entity.createdBy}
                />
              </Button>{" "}
              {timeAgo(fromPointDate(entity.createdAt) || now())}
            </TextSmall>
          )}
        </VStack>

        {quickActions && !entity?.template && (
          <HStack wrap>
            <WorkflowActions entity={entity} />
          </HStack>
        )}
      </Container>
    </>
  );
};
