import { omit, values } from "lodash";
import { useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { Entity, HasRefs, Integration, Person, Stamp } from "@api";

import { useLazyEntities, useLazyEntity } from "@state/generic";
import { ID } from "@state/types";
import { useAuthedUser, useAuthedUserId } from "@state/workspace";

import { indexBy } from "@utils/array";
import { useAsyncEffect } from "@utils/effects";
import { now } from "@utils/epoch-date";
import { isLocalID } from "@utils/id";
import { Maybe, when } from "@utils/maybe";
import { toRef } from "@utils/property-refs";
import { containsRef } from "@utils/relation-ref";

import { PersonAtom, PersonStoreAtom } from "./atoms";
import { getPersonLoader } from "./queries";
import { hasAlphaFeatures, toDisplayName } from "./utils";

export function useLazyGetPerson(id: ID) {
  const [person, setPerson] = useRecoilState(PersonAtom(id));

  useAsyncEffect(async () => {
    if (!id || isLocalID(id)) {
      return;
    }

    if (!person?.fetchedAt) {
      const latest = await getPersonLoader(id);
      setPerson(latest);
    }
  }, [id]);

  return person;
}

export function useLookupPersonByAlias(integration?: Integration) {
  const store = useRecoilValue(PersonStoreAtom);
  const lookup = useMemo(
    () =>
      indexBy(values(store.lookup), (p) =>
        integration ? p?.aka?.[integration] : values(omit(p?.aka, "__typename"))
      ),
    [store]
  );
  const toPerson = useCallback(
    (id: Maybe<string>) => (id ? lookup?.[id] : undefined),
    [lookup]
  );
  return { lookup, toPerson };
}

export function useLookupAliasForPerson(integration: Integration) {
  const store = useRecoilValue(PersonStoreAtom);
  const lookup = useMemo(
    () =>
      indexBy(values(store.lookup), (p) =>
        p ? [p.id, toDisplayName(p)] : undefined
      ),
    [store]
  );
  const toAlias = useCallback(
    (id: Maybe<string>) => when(id, (id) => lookup?.[id]?.aka?.[integration]),
    [lookup]
  );

  return { lookup, toAlias };
}

export const useHasSeen = (item: Entity) => {
  const meId = useAuthedUserId();
  return useMemo(
    () => containsRef((item as HasRefs)?.refs?.seenBy, toRef(meId)),
    [(item as HasRefs)?.refs?.seenBy, meId]
  );
};

export const useMe = () => {
  const authMe = useAuthedUser();
  const fullMe = useLazyEntity<"person">(authMe.id, false);
  return fullMe || (authMe as Person);
};

export const useStamper = () => {
  const me = useMe();
  return useCallback((): Stamp => ({ by: me.id, at: now() }), [me.id]);
};

export const useMaybeMe = () => {
  try {
    return useMe();
  } catch (err) {
    return undefined;
  }
};

export function useHasAlphaFeatures() {
  const me = useMe();
  return useMemo(() => hasAlphaFeatures(me), [me]);
}

export const useMyPinned = () => {
  const me = useMe();
  return useLazyEntities(me?.refs?.pins);
};
