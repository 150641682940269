import { mapValues } from "lodash";
import { RecoilState, selector } from "recoil";

import { Entity, EntityType } from "@api";
import { EntityForType } from "@api/mappings";

import { ActionStoreAtom } from "@state/actions";
import { AgendaStoreAtom } from "@state/agendas";
import { BacklogStoreAtom } from "@state/backlog";
import { CalendarStoreAtom } from "@state/calendar";
import { CampaignStoreAtom } from "@state/campaigns";
import { CompanyStoreAtom } from "@state/company";
import { ContactStoreAtom } from "@state/contact";
import { ContentStoreAtom } from "@state/content";
import { PropertyDefStoreAtom } from "@state/databases";
import { DealStoreAtom } from "@state/deal";
import { EventStoreAtom } from "@state/event";
import { FetchResultsStoreAtom } from "@state/fetch-results";
import { FormStoreAtom } from "@state/form";
import { KnowledgeBaseStoreAtom } from "@state/knowledgebase";
import { MeetingStoreAtom } from "@state/meetings";
import { NoteStoreAtom } from "@state/notes";
import { OutcomeStoreAtom } from "@state/outcomes";
import { PageStoreAtom } from "@state/page";
import { PersonStoreAtom } from "@state/persons";
import { PipelineStoreAtom } from "@state/pipeline";
import { ProcessStoreAtom } from "@state/process";
import { ProjectStoreAtom } from "@state/projects";
import { RequestStoreAtom } from "@state/request";
import { ResourceStoreAtom } from "@state/resources";
import { RoadmapStoreAtom } from "@state/roadmap";
import { ScheduleStoreAtom } from "@state/schedule";
import { SprintStoreAtom } from "@state/sprint";
import { StoreState } from "@state/store";
import { TaskStoreAtom } from "@state/tasks";
import { TeamStoreAtom } from "@state/teams";
import { ViewStoreAtom } from "@state/views";
import { WorkflowStepStoreAtom, WorkflowStoreAtom } from "@state/workflow";
import { WorkspaceStoreAtom } from "@state/workspace";

import { cachedFunc } from "@utils/fn";
import { switchEnum } from "@utils/logic";
import { toMilliSeconds } from "@utils/time";

// Update on new entity
export const getStore = <
  T extends EntityType,
  E extends Entity = EntityForType<T>
>(
  t: T
): RecoilState<StoreState<E>> =>
  switchEnum<T, RecoilState<StoreState<E>>>(t, {
    // @ts-ignore cant't follow the mappings
    project: () => ProjectStoreAtom,
    campaign: () => CampaignStoreAtom,
    calendar: () => CalendarStoreAtom,
    content: () => ContentStoreAtom,
    task: () => TaskStoreAtom,
    outcome: () => OutcomeStoreAtom,
    backlog: () => BacklogStoreAtom,
    roadmap: () => RoadmapStoreAtom,
    sprint: () => SprintStoreAtom,
    schedule: () => ScheduleStoreAtom,
    view: () => ViewStoreAtom,
    team: () => TeamStoreAtom,
    note: () => NoteStoreAtom,
    resource: () => ResourceStoreAtom,
    meeting: () => MeetingStoreAtom,
    agenda: () => AgendaStoreAtom,
    action: () => ActionStoreAtom,
    page: () => PageStoreAtom,
    person: () => PersonStoreAtom,
    process: () => ProcessStoreAtom,
    knowledgebase: () => KnowledgeBaseStoreAtom,
    form: () => FormStoreAtom,
    workspace: () => WorkspaceStoreAtom,
    event: () => EventStoreAtom,
    request: () => RequestStoreAtom,
    pipeline: () => PipelineStoreAtom,
    workflow: () => WorkflowStoreAtom,
    workflow_step: () => WorkflowStepStoreAtom,
    company: () => CompanyStoreAtom,
    contact: () => ContactStoreAtom,
    deal: () => DealStoreAtom,
  });

export const getStoreCached = cachedFunc(
  () => getStore,
  toMilliSeconds("10 hours")
);

const TYPE_LOOKUP: Record<EntityType, true> = {
  project: true,
  campaign: true,
  calendar: true,
  content: true,
  task: true,
  outcome: true,
  backlog: true,
  roadmap: true,
  sprint: true,
  schedule: true,
  view: true,
  team: true,
  note: true,
  resource: true,
  meeting: true,
  agenda: true,
  action: true,
  page: true,
  person: true,
  process: true,
  knowledgebase: true,
  form: true,
  workspace: true,
  workflow: true,
  workflow_step: true,
  pipeline: true,
  event: true,
  request: true,
  company: true,
  contact: true,
  deal: true,
};

export const EntityStores = selector({
  key: "EntityStores",
  get: ({ get }) =>
    mapValues(TYPE_LOOKUP, (_, type) => get(getStore(type as EntityType))) as {
      [K in EntityType]: StoreState<EntityForType<K>>;
    },
});

export const getAllStores = selector({
  key: "getAllStores",
  get: ({ get }) => {
    const props = get(PropertyDefStoreAtom);
    const fetchResults = get(FetchResultsStoreAtom);
    const entityStores = get(EntityStores);

    return { props, fetchResults, ...entityStores };
  },
});
