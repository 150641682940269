import { first } from "lodash";
import { useMemo } from "react";

import { Event, PropertyMutation, Status } from "@api";

import { useEditInAppCommands } from "@state/app";
import { useLazyPropertyDef, useLazyPropertyValue } from "@state/databases";
import { useUpdateEntity } from "@state/generic";

import { OneOrMany } from "@utils/array";
import { formatDay } from "@utils/date";
import { usePointDate } from "@utils/date-fp";
import { Fn } from "@utils/fn";
import { when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { asMutation } from "@utils/property-mutations";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Centered, Container } from "@ui/container";
import { EditableHeading } from "@ui/editable-heading";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { EntityProperties } from "@ui/entity-properties";
import { EntitySheet } from "@ui/entity-sheet";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Sheet } from "@ui/sheet-layout";
import { StatusTag } from "@ui/tag";
import { TemplateBanner } from "@ui/template-banner";
import { Text } from "@ui/text";
import { WorkflowActions } from "@ui/workflow-action-button";

import { MeetingDivider } from "../meeting/comps";
import { ScheduleButton } from "../schedule";
import { PaneOpts } from "../types";

export const EventPane = ({ id, item }: PaneOpts<Event>) => {
  const mutate = useUpdateEntity(item.id, item.source.scope);
  const status = useLazyPropertyValue(item, {
    field: "status",
    type: "status",
  });
  const sectionProps = useMemo(
    () => ({ event: item, status: status?.value.status, mutate }),
    [item, mutate, status?.value.status]
  );

  return (
    <EntitySheet size="primary" entity={item}>
      {!!item.template && <TemplateBanner />}

      <EventHeader {...sectionProps} />

      <Centered stack="vertical" gap={30}>
        <EntityProperties entityId={id} />
      </Centered>
    </EntitySheet>
  );
};

interface HeaderProps {
  event: Event;
  status?: Status;
  mutate: Fn<OneOrMany<PropertyMutation<Event>>, void>;
}

const EventHeader = ({ event, status }: HeaderProps) => {
  const goTo = useGoTo();
  const pageId = usePageId();
  const mutate = useUpdateEntity(event.id, pageId);
  const editInAppCommands = useEditInAppCommands();

  const startProp = useLazyPropertyDef(event.source, {
    field: "start",
    type: "date",
  });

  if (!event) {
    return <h1>Not found.</h1>;
  }

  return (
    <VStack gap={0}>
      <Container>
        <EntityHeaderBar entity={event} />
      </Container>

      <Centered>
        <VStack gap={20}>
          <SpaceBetween direction="horizontal" align="stretch" gap={12}>
            <MeetingDivider entity={event} />

            <Container gap={6} padding="none" inset="bottom" stack="vertical">
              <StatusTag
                showIcon={status?.group === "in-progress"}
                status={status}
              />

              <VStack gap={0}>
                <HStack align="baseline">
                  <EditableHeading
                    key={event.id}
                    text={event.name || ""}
                    size="h2"
                    placeholder="Event name"
                    onChange={(text) => {
                      when(text, (i) =>
                        mutate(asMutation({ field: "name", type: "text" }, i))
                      );
                    }}
                  />
                  <HStack gap={0}>
                    {event.start && !event.template && (
                      <Button
                        subtle
                        size="tiny"
                        onClick={() => editInAppCommands(startProp, event)}
                      >
                        <Text subtle>
                          {usePointDate(event.start, formatDay)}
                        </Text>
                      </Button>
                    )}
                    {event.start && event.end && <Text subtle> - </Text>}
                    {event.end && !event.template && (
                      <Button
                        subtle
                        size="tiny"
                        onClick={() => editInAppCommands(startProp, event)}
                      >
                        <Text subtle>{usePointDate(event.end, formatDay)}</Text>
                      </Button>
                    )}
                  </HStack>

                  {!!(event.refs?.repeat || event.refs?.schedule)?.length && (
                    <ScheduleButton
                      size="tiny"
                      subtle={true}
                      onClick={() =>
                        when(
                          first(event.refs?.repeat || event.refs?.schedule),
                          goTo
                        )
                      }
                      schedule={first(
                        event.refs?.repeat || event.refs?.schedule
                      )}
                    />
                  )}
                </HStack>
              </VStack>
            </Container>
          </SpaceBetween>

          {!event.template && (
            <HStack fit="container" gap={4}>
              <WorkflowActions entity={event} />
            </HStack>
          )}
        </VStack>
      </Centered>
    </VStack>
  );
};
