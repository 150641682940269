import { JsonArray, JsonObject, SettingsData } from "@api";

import { PropertyDefStoreState } from "@state/databases";
import { AllStores } from "@state/generic/types";

export type AiCaseContext = {
  stores: AllStores & { props: PropertyDefStoreState };
  settings: SettingsData;
};

// Helper function for inference
export function asAiUseCase<I, R, D>(useCase: AiUseCase<I, R, D>) {
  return useCase;
}

export type AiUseCase<Input, Result, Data> = {
  prepareData?: (inp: Input, c: AiCaseContext) => Promise<Data>;
  toPrompt(data: Data, c: AiCaseContext): string;
  parseCompletion(
    response: string | JsonObject | JsonArray,
    data: Data,
    c: AiCaseContext
  ): Result;
};
