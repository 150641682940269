import { useMemo } from "react";

import {
  Entity,
  EntityType,
  HasBlocked,
  hasIcon,
  hasStatus,
  isEntity,
  RelationRef,
  Schedule,
  toTitleOrName,
} from "@api";

import { useLazyPropertyValue } from "@state/databases";
import { useLazyRelation } from "@state/generic";
import { isProcessId } from "@state/process";
import { toSentence } from "@state/schedule";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { isLocalID, isPersonId, isTeamId, maybeTypeFromId } from "@utils/id";
import { Maybe, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { ComponentOrNode } from "@utils/react";

import { Button, Props as ButtonProps } from "@ui/button";
import { Ellipsis } from "@ui/ellipsis";
import { render, useEngine } from "@ui/engine";
import { ActionCheckMark } from "@ui/engine/action";
import { WorkflowStepIcon } from "@ui/engine/workflow_step";
import { SpaceBetween } from "@ui/flex";
import {
  ArrowUpRight,
  EmojiIcon,
  FileAlt,
  Icon,
  LinkIcon,
  PersonIcon,
  PlusIcon,
  Process,
  StatusLive,
  TeamIcon,
  ViewIcon,
  WorkflowFilled,
} from "@ui/icon";
import { Label, Props as LabelProps } from "@ui/label";
import { LocationLabel } from "@ui/location-button";
import { MenuItem, MenuItemProps } from "@ui/menu-item";
import { StatusIcon } from "@ui/status-button";
import { Tag } from "@ui/tag";
import { Props as TooltipProps, Tooltip } from "@ui/tooltip";

import styles from "./relation-label.module.css";

type Props = {
  relation: Maybe<RelationRef>;
  icon?: boolean | ComponentOrNode;
  inline?: boolean;
} & Omit<LabelProps, "icon">;

type MenuProps = {
  relation: Maybe<RelationRef>;
  icon?: boolean | ComponentOrNode;
  onOpen?: Fn<Maybe<RelationRef>, void>;
} & Omit<MenuItemProps, "icon" | "onClick">;

export const StatusRelationIcon = ({ relation, size, className }: Props) => {
  const {
    value: { status },
  } = useLazyPropertyValue(relation as Entity, {
    field: "status",
    type: "status",
  });

  return (
    <StatusIcon
      className={className}
      size={size}
      blocked={(relation as Maybe<HasBlocked>)?.blocked}
      status={{
        ...status,
        blocked: (relation as Maybe<{ blocked: boolean }>)?.blocked,
      }}
    />
  );
};

export const RelationIcon = ({ relation, size, className }: Props) => {
  const inflated = useLazyRelation(relation);

  if (isEntity(inflated, "view")) {
    return <ViewIcon layout={inflated?.layout} />;
  }

  if (isEntity(inflated, "workflow")) {
    return <WorkflowFilled />;
  }

  if (isEntity(inflated, "person") || isEntity(inflated, "contact")) {
    return <PersonIcon person={inflated} />;
  }

  if (isEntity(inflated, "company")) {
    return <PersonIcon person={inflated} shape="square" />;
  }

  if (isEntity(inflated, "workflow_step")) {
    return <WorkflowStepIcon step={inflated} />;
  }

  if (isEntity(inflated, "action")) {
    return <ActionCheckMark status={inflated.status} />;
  }

  if (isEntity(inflated, "event")) {
    return <EmojiIcon emoji="📅" />;
  }

  if (isEntity(inflated, "agenda")) {
    return <></>;
  }

  if (isEntity(inflated, "team")) {
    return <TeamIcon team={inflated} />;
  }

  if (isEntity(inflated, "meeting")) {
    return inflated.status?.id === "INP" ? (
      <StatusLive />
    ) : (
      <EmojiIcon emoji="🤝" />
    );
  }

  if (hasIcon(inflated) && inflated.icon) {
    return <EmojiIcon emoji={inflated.icon} />;
  }

  if (when(relation?.id, isProcessId)) {
    return <Process />;
  }

  if (isEntity(inflated, "resource")) {
    return inflated.url ? (
      <LinkIcon url={inflated.url} icon={inflated.icon} type={inflated.type} />
    ) : (
      <StatusLive />
    );
  }

  if (isEntity(inflated, "page")) {
    return <FileAlt />;
  }

  if (hasStatus(inflated)) {
    return (
      <StatusRelationIcon
        relation={inflated}
        size={size}
        className={className}
      />
    );
  }

  return <></>;
};

export const RelationMenuItem = ({ relation, icon, ...rest }: MenuProps) => {
  const inflated = useLazyRelation(relation) as Maybe<Entity>;
  const engine = useEngine(
    (inflated as Maybe<Entity>)?.source?.type ||
      when(relation?.id, maybeTypeFromId<EntityType>) ||
      "task"
  );

  if (!relation || !inflated) {
    return <></>;
  }

  if (!!isLocalID(relation.id)) {
    return (
      <MenuItem icon={PlusIcon}>Create "{toTitleOrName(inflated)}"</MenuItem>
    );
  }

  // if (isTemplate(inflated)) {
  //   return (
  //     <MenuItem icon={icon === true ? undefined : icon} {...rest}>
  //       <RelationLabel relation={relation} />
  //     </MenuItem>
  //   );
  // }

  return render(engine?.asMenuItem, {
    ...rest,
    key: relation?.id,
    item: inflated,
    icon: icon === true ? undefined : icon,
  });
};

export const RelationLabel = ({
  relation,
  subtle = false,
  icon = true,
  inline,
  size,
  className,
  ...props
}: Props) => {
  const inflated = useLazyRelation(relation);

  if (inflated && (inflated as Schedule)?.source?.type === "schedule") {
    return (
      <Label
        className={cx(className, inline && styles.inline)}
        subtle={subtle}
        {...props}
        size={size}
        icon={undefined}
        iconRight={undefined}
      >
        {toSentence(inflated as Schedule) || "Loading..."}
      </Label>
    );
  }

  return (
    <Label
      className={cx(className, inline && styles.inline)}
      subtle={subtle}
      {...props}
      size={size}
      icon={
        icon ? (
          <Icon
            size={size}
            icon={icon === true ? <RelationIcon relation={inflated} /> : icon}
          />
        ) : undefined
      }
    >
      <Ellipsis>{toTitleOrName(inflated as Entity) || "Loading..."}</Ellipsis>
    </Label>
  );
};

export const RelationLocationLabel = ({ relation, ...rest }: Props) => {
  const inflated = useLazyRelation(relation);
  const showLocation = useMemo(
    () =>
      !!relation?.id && !isTeamId(relation?.id) && !isPersonId(relation?.id),
    [relation?.id]
  );

  return (
    <SpaceBetween gap={6}>
      <RelationLabel relation={inflated} fit="content" />

      {showLocation &&
        when((inflated as Maybe<Entity>)?.source?.scope, (scope) => (
          <LocationLabel size="small" location={scope} showTeam={true} />
        ))}
    </SpaceBetween>
  );
};

export const RelationTag = ({ relation, className, ...props }: Props) => (
  <Tag className={className}>
    <RelationLabel relation={relation} {...props} />
  </Tag>
);

export const RelationTooltip = ({
  relation,
  children,
  ...props
}: Props & Omit<TooltipProps, "text">) => {
  const inflated = useLazyRelation(relation);

  return (
    <Tooltip text={toTitleOrName(inflated as Entity)} {...props}>
      {children}
    </Tooltip>
  );
};

export const RelationButton = ({
  relation,
  size = "small",
  subtle = true,
  variant,
  ...props
}: Omit<Props, "size"> & Pick<ButtonProps, "size" | "subtle" | "variant">) => {
  const pushTo = usePushTo();

  if (!relation) {
    return <></>;
  }

  return (
    <Button
      size={size}
      subtle={subtle}
      variant={variant}
      onClick={() => pushTo(relation)}
      iconRight={ArrowUpRight}
    >
      <RelationLabel relation={relation} {...props} />
    </Button>
  );
};

export const RelationText = ({ relation, ...props }: Props) => {
  const inflated = useLazyRelation(relation);

  return <>{toTitleOrName(inflated as Entity) || "Loading..."}</>;
};
