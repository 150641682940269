import { map } from "lodash";
import { useState } from "react";

import { DatabaseID, Entity, HasRefs } from "@api";

import { SystemPackages, useHasPackages } from "@state/packages";
import {
  useActionWorkflows,
  useRunWorkflowState,
  WorkflowAction,
  WorkflowData,
} from "@state/workflows";

import { withHandle } from "@utils/event";
import { useShowMore } from "@utils/hooks";
import { safeAs } from "@utils/maybe";

import { Button, Props as ButtonProps } from "@ui/button";
import { Play } from "@ui/icon";
import { Text } from "@ui/text";

import { WorkflowCollectDialog } from "./workflow-collect-dialog";
import { RunWorkflowModal } from "./workflow-run-modal";

interface Props<T extends Entity> {
  action: WorkflowAction<T>;
  data: WorkflowData<T>;
  source: DatabaseID;
  variant: ButtonProps["variant"];
  inset?: boolean;
  size?: ButtonProps["size"];
}

export const WorkflowActionButton = <T extends Entity>({
  action,
  source,
  data,
  variant = "secondary",
  inset = false,
  size,
}: Props<T>) => {
  const { run, collecting, cancelCollecting, context } = useRunWorkflowState(
    source.type,
    data
  );

  return (
    <>
      {collecting && context && (
        <WorkflowCollectDialog
          action={action}
          data={data}
          source={source}
          context={context}
          onCollected={run}
          onCancel={cancelCollecting}
        />
      )}
      <Button
        variant={variant}
        inset={inset}
        icon={action.icon}
        onClick={withHandle(() => run(action))}
        loading={!!collecting}
        size={size}
      >
        {action.title}
      </Button>
    </>
  );
};

interface WorkflowActionsProps<T extends Entity> {
  entity: T;
  highlightFirst?: boolean;
  showAll?: boolean;
  showCustom?: boolean;
  size?: ButtonProps["size"];
}

export const WorkflowActions = <T extends Entity>({
  entity,
  showAll,
  highlightFirst = true,
  showCustom = true,
  size,
}: WorkflowActionsProps<T>) => {
  const installed = useHasPackages(entity.id, [SystemPackages.Workflows]);
  const [workflowActions, actionData] = useActionWorkflows(entity);
  const { visible, hasMore, showMore, moreCount } = useShowMore(
    workflowActions,
    3,
    showAll
  );
  const [workflowModal, setWorkflowModal] = useState(false);

  return (
    <>
      {map(
        visible,
        (a, i) =>
          actionData && (
            <WorkflowActionButton
              key={a.id}
              variant={
                a.variant ||
                (highlightFirst && i === 0 ? "primary" : "secondary")
              }
              size={size}
              action={a}
              data={actionData}
              source={entity.source}
            />
          )
      )}

      {installed[SystemPackages.Workflows] &&
        !safeAs<HasRefs>(entity)?.refs?.fromStep?.length &&
        !!showCustom && (
          <Button onClick={() => setWorkflowModal(true)} icon={Play}>
            Start Workflow
          </Button>
        )}

      {hasMore && (
        <Button subtle onClick={showMore}>
          <Text subtle>+{moreCount}</Text>
        </Button>
      )}

      {workflowModal && (
        <RunWorkflowModal
          entity={entity}
          onClose={() => setWorkflowModal(false)}
        />
      )}
    </>
  );
};
