import { find } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";

import { ID } from "@api";

import { useLazyEntity, useUnsavedUpdates } from "@state/generic";
import { JobAtom, useCheckForNewJobs } from "@state/jobs";

import { useAsyncEffect } from "@utils/effects";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";

import { WorkflowStepsBar } from "@ui/engine/workflow/components";
import { Centered } from "@ui/flex";
import { Icon, SpinnerIcon } from "@ui/icon";
import { claimAndRunJob } from "@ui/jobs-queue";
import { Modal } from "@ui/modal";
import { Text } from "@ui/text";

export const WorkflowRunnerModal = ({
  id,
  onClose,
}: {
  id: ID;
  onClose: Fn<void, void>;
}) => {
  const [jobId, setJobId] = useState<Maybe<ID>>();
  const [job, setJob] = useRecoilState(JobAtom(jobId || ""));
  const workflow = useLazyEntity<"workflow">(id);
  const unsaved = useUnsavedUpdates(id);
  const ran = useRef(false);
  const { check } = useCheckForNewJobs();

  useAsyncEffect(async () => {
    // Wait until all workflow changes are saved...
    if (!!unsaved?.length || ran.current) {
      return;
    }

    try {
      ran.current = true;
      const allJobs = await check();
      const toClaim = find(allJobs, (j) => j.data?.id === id);
      if (!toClaim) {
        return;
      }

      const latest = await claimAndRunJob(toClaim);
      if (latest?.id) {
        setJob(latest);
        setJobId(latest?.id);
      }
    } catch (e) {
      onClose();
    }
  }, [!!unsaved?.length]);

  // Close modal when finished running...
  useEffect(() => {
    if (jobId && (!job || job?.status === "completed")) {
      onClose();
    }
  }, [job?.status]);

  return (
    <Modal open={true} onOpenChanged={() => onClose()}>
      <Centered gap={10}>
        <Icon size="large" icon={SpinnerIcon} />
        <Text subtle>{"Calculating optimal path..."}</Text>
        {workflow && <WorkflowStepsBar workflow={workflow} />}
      </Centered>
    </Modal>
  );
};
