import { ReactNode, useState } from "react";

import { cx } from "@utils/class-names";

import styles from "./centered-overflow.module.css";

export const CenteredOverflow = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const [height, setHeight] = useState(0);
  return (
    <div className={cx(styles.container, className)} style={{ height }}>
      <div
        className={styles.content}
        ref={(el) => el && setHeight(el.clientHeight)}
      >
        {children}
      </div>
    </div>
  );
};
