import axios from "axios";
import { find, values } from "lodash";
import { useEffect } from "react";

import { Company } from "@api";

import { WorkflowDefinition, WorkflowDefinitionConfig } from "@state/workflows";

import { maybeLookup, omitEmpty } from "@utils/array";
import { maybeMap, when } from "@utils/maybe";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { fuzzyMatch } from "@utils/search";
import { toDomain } from "@utils/url";

import { Magic } from "@ui/icon";
import { LinkDialog } from "@ui/link-dialog";
import { showError, withLoading } from "@ui/notifications";

export const addWebsite: WorkflowDefinition<Company> = {
  id: "addWebsite",
  trigger: "ACTION",
  type: ["company"],
  title: "Add a website",

  allowed: ({ entity }, context) => !entity.websites?.length,

  collect: ({ data: { entity }, onCollected, onCancelled }) => {
    return (
      <LinkDialog
        link={undefined}
        onCancel={onCancelled}
        onLinked={(l) =>
          onCollected(
            !!l?.url
              ? [{ field: "website", type: "link", value: { link: l } }]
              : []
          )
        }
      />
    );
  },

  execute: ({ entity, collected }, { props }) => {
    const link = collected?.[0]?.value?.link;
    if (!link) {
      return [];
    }

    return asUpdate(
      entity,
      asMutation({ field: "websites", type: "links" }, [link])
    );
  },
};

export const enrich: WorkflowDefinition<Company> = {
  id: "enrich",
  trigger: "ACTION",
  type: ["company"],
  icon: Magic,
  title: "Enrich",

  allowed: ({ entity }, context) => !entity.custom?.bio,

  collect: ({
    data: { entity },
    onCollected,
    onCancelled,
    context: { props },
  }) => {
    useEffect(() => {
      withLoading(async () => {
        const getProp = maybeLookup(props, (p) => p.field);
        try {
          const domain = when(
            find(entity.websites, (w) => !!w.url)?.url,
            toDomain
          );
          const { data: profile } = await axios.get<CompanyProfile>(
            "https://api.enrich.so/v1/api/company?name=" +
              entity.name +
              (domain ? "&domain=" + domain : ""),
            {
              // TODO: Regenerate token and add to env
              headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2ZmY2ZWU5MGY1M2JlN2ExZTUyYTQ5OCIsInR5cGUiOiJhcGkiLCJpYXQiOjE3MjgwMTYxMDd9.RVEj8X7eS9uasA9DmCB8y0EFB2DYFP1IunT-tDKmaXI`,
              },
            }
          );

          onCollected(
            omitEmpty([
              asMutation(
                { field: "websites", type: "links" },
                maybeMap(values(profile.urls), (url) =>
                  url ? { url } : undefined
                )
              ),

              asMutation({ field: "name", type: "text" }, profile.name),

              asMutation(
                { field: "custom.bio", type: "text" },
                profile.description
              ),
              asMutation(
                { field: "avatar", type: "text" },
                profile.images.logo
              ),

              when(
                find(
                  getProp("industry")?.values?.select || [],
                  (v) => !!v.name && fuzzyMatch(v.name, profile.industries?.[0])
                ),
                (i) => asMutation({ field: "industry", type: "select" }, i)
              ),

              asMutation(
                { field: "custom.headquarters", type: "text" },
                omitEmpty([
                  profile.locations?.headquarter?.city,
                  profile.locations?.headquarter?.geographic_area,
                  profile.locations?.headquarter?.country,
                ]).join(", ")
              ),
            ])
          );
        } catch (err) {
          showError("Failed to enrich contact.");
          onCancelled();
          throw err;
        }

        return "";
      });
    }, []);

    return <></>;
  },
  execute: ({ entity, collected }, { props }) => {
    return asUpdate(entity, collected);
  },
};

export const definitions: WorkflowDefinitionConfig<Company> = {
  triggers: [],
  suggestions: [],
  actions: [enrich, addWebsite],
};

export default definitions;

type CompanyProfile = {
  name: string;
  universal_name: string;
  company_id: number;
  description: string;
  phone: string | null;
  followers: number;
  urls: {
    company_page: string;
    li_url: string;
  };
  industries: string[];
  images: {
    logo: string;
    cover: string;
  };
  tagline: string | null;
  founded: string | null;
  type: string;
  staff: {
    total: number;
    range: {
      start: number;
      end: number | null;
    };
  };
  call_to_action: {
    url: string;
    text: string;
  };
  locations: {
    headquarter: {
      country: string;
      geographic_area: string;
      city: string;
      postal_code: string;
      line1: string;
      line2: string | null;
      description: string | null;
    };
    other: Array<{
      country: string;
      geographic_area: string;
      city: string;
      postal_code: string;
      line1: string;
      line2: string | null;
      description: string | null;
    }>;
  };
  specialities: string[];
  funding_data: {
    num_of_funding_rounds: number;
    last_funding_round: {
      funding_type: string;
      money_raised: {
        currency: string;
        amount: number;
      };
      announced_on: {
        month: number;
        day: number;
        year: number;
      };
      lead_investors: Array<{
        name: string;
        logo: string;
        investor_url: string;
      }>;
      num_of_other_investors: number;
      round_url: string;
    };
    organization_url: string;
    funding_rounds_url: string;
  };
  hashtags: string[];
  domain: string;
  total_credits: number;
  credits_used: number;
  credits_remaining: number;
};
