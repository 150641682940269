import { snakeCase } from "change-case";
import { every, filter, first, map, some } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getRecoil } from "recoil-nexus";

import {
  DatabaseID,
  Entity,
  EntityType,
  FilterQuery,
  getOptimizedForFilter,
  isEntity,
  isSchedule,
  Period,
  PropertyMutation,
  PropertyValueRef,
  Ref,
  RefOrID,
  Status,
  VariableDef,
  Workflow,
  WorkflowStep,
} from "@api";

import { JsonArray } from "@prisma";

import { useAiUseCase, workflowStep } from "@state/ai";
import { allPropertiesForSource } from "@state/databases";
import {
  GenericItem,
  useCreateFromObject,
  useCreateFromTemplate,
  useGetItemFromAnyStore,
  useLazyEntities,
  useLazyEntity,
  useNestedSource,
  useSource,
  useUnsavedUpdates,
} from "@state/generic";
import { useMe } from "@state/persons";
import { useReplyOrCreateThread } from "@state/resources";
import { addPeriod } from "@state/schedule";
import { useSetting } from "@state/settings";

import {
  dependencySort,
  ensureArray,
  ensureMany,
  indexBy,
  justOne,
  maybeLookup,
  maybeLookupById,
  maybeMap,
  omitEmpty,
  OneOrMany,
} from "@utils/array";
import { toISODate, useISODate } from "@utils/date-fp";
import { log } from "@utils/debug";
import { useAsyncEffect } from "@utils/effects";
import { toJSDate } from "@utils/epoch-date";
import { passes } from "@utils/filtering";
import { composel, fallback, Fn, not } from "@utils/fn";
import { isFormula } from "@utils/formula";
import { useConst, useOnce } from "@utils/hooks";
import { maybeTypeFromId, typeFromId } from "@utils/id";
import { equalsAny, switchEnum } from "@utils/logic";
import { minus } from "@utils/math";
import { Maybe, safeAs, when } from "@utils/maybe";
import { now } from "@utils/now";
import { merge } from "@utils/object";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
  flattenChanges,
  fromOverrides,
} from "@utils/property-mutations";
import {
  asFormulaValue,
  asRelationValue,
  getPropertyValue,
  isAnyRelation,
  isEmptyRef,
  referencesType,
  toPropertyValueRef,
  toRef,
} from "@utils/property-refs";
import { containsRef } from "@utils/relation-ref";
import { toBaseScope, toChildLocation } from "@utils/scope";
import { toChannelId, toThreadId } from "@utils/slack";
import { toStamp } from "@utils/stamp";
import { isGreaterThan, secondsAgo } from "@utils/time";
import { isStar, withoutStar } from "@utils/wildcards";

import { useMutate } from "@ui/mutate";
import { showError } from "@ui/notifications";

import { toFinishWorkflowUpdates } from "./actions";
import {
  evalFormula,
  evalFormulas,
  isFinished,
  toStepMessage,
  toVariables,
} from "./utils";

export const useStartWorkflow = (
  parent: Maybe<Entity>,
  onStarted?: Fn<Ref, void>
) => {
  const me = useMe();
  const [starting, setStarting] = useState<Maybe<Workflow>>();
  const source = useNestedSource(parent, "workflow");
  const mutate = useMutate();
  const [collecting, setCollecting] = useState<VariableDef[]>();
  const onFinished = useCallback(
    (wf: Ref) => {
      if (!starting) {
        throw new Error("No running workflow on callback.");
      }

      onStarted?.(wf);
      setStarting(undefined);

      // Probs don't need this as below usedBy should link
      mutate(
        omitEmpty([
          parent &&
            asUpdate(
              parent,
              asAppendMutation(
                {
                  field: isSchedule(parent) ? "instances" : "refs.fromWorkflow",
                  type: "relations",
                },
                [{ id: wf.id }]
              )
            ),
          asUpdate(
            starting,
            asMutation({ field: "stamps.lastRun", type: "stamp" }, toStamp(me))
          ),
        ])
      );
    },
    [starting, onStarted, setStarting]
  );

  const createFromTemplate = useCreateFromTemplate(source, onFinished);
  const ready = createFromTemplate.ready;

  const start = useCallback(
    (workflow: Workflow) => {
      if (!ready) {
        showError("Not ready to start workflow.");
        return;
      }

      if (!!starting) {
        // Prevent double starting
        return;
      }

      setStarting(workflow);

      // Add the entity to the workflow inputs if it's a matching relation
      setCollecting(
        map(workflow.inputs, (i) =>
          isAnyRelation(i) &&
          isEmptyRef(i) &&
          !!parent &&
          (isStar(i.options?.references) ||
            equalsAny(
              parent.source.type,
              ensureMany(withoutStar(i.options?.references))
            ))
            ? {
                ...i,
                value: asRelationValue(i.type, parent.id),
              }
            : i
        )
      );
    },
    [createFromTemplate.create, ready]
  );

  useEffect(() => {
    if (!starting || !collecting || !parent) {
      return;
    }

    // Still collecting input variables
    if (collecting.length && some(collecting, isEmptyRef)) {
      return;
    }

    createFromTemplate.create(starting, {
      overrides: {
        [starting.id]: {
          inputs: collecting,
          status: { id: "RUN" },
          refs: {
            startedFrom: [{ id: parent.id }],
            usedBy: isEntity(parent, "schedule") ? [] : [{ id: parent.id }],
          },
        },
        ["workflow_step"]: {
          status: { id: "NTS" },
        },
      },
    });
  }, [starting, collecting, parent?.id]);

  return {
    start,
    starting: starting,
    ready: ready,
    collecting,
    onCollected: setCollecting,
    onCancelled: () => setStarting(undefined),
  };
};

export const useTestWorkflow = (
  workflow: Workflow,
  onCreated: Fn<Ref, void>
) => {
  const [starting, setStarting] = useState<Maybe<Workflow>>();
  const source = workflow.source;
  const { create, ready } = useCreateFromTemplate(
    source,
    useCallback(
      (wf: Ref) => {
        setStarting(undefined);
        onCreated?.(wf);
      },
      [setStarting, onCreated]
    )
  );

  const start = useCallback(
    (workflow: Workflow) => {
      setStarting(workflow);

      create(workflow, {
        overrides: {
          [workflow.id]: {
            status: { id: "RUN" },
          },
          ["workflow_step"]: {
            status: { id: "NTS" },
          },
        },
      });
    },
    [create, ready]
  );

  return { start, starting: starting?.id || false, ready: ready };
};

const useFinishWorkflow = () => {
  const mutate = useMutate();
  return useCallback(
    (workflow: Workflow, steps: WorkflowStep[]) =>
      mutate(toFinishWorkflowUpdates(workflow, steps)),
    []
  );
};

export const useRunWorkflow = (
  workflow: Maybe<Workflow>,
  steps: Maybe<WorkflowStep[]>,
  onFinished?: Fn<Workflow, void>
) => {
  const mutate = useMutate();
  const me = useMe();
  const getStep = useMemo(() => maybeLookupById(steps || []), [steps]);
  const [once, reset] = useOnce(`${workflow?.id}-finished`);
  const [collected, setCollected] = useState(false);
  const unsaved = useUnsavedUpdates(workflow?.id);
  const stepRunner = useRunWorkflowStep(
    { workflow, steps },
    useConst(() => {}), // TODO: On success
    useConst(() => {}) // TODO: On failure
  );
  const finishAndCleanup = useFinishWorkflow();
  const finishedSaving = useMemo(
    () => !!workflow?.id && !unsaved?.length,
    [unsaved?.length]
  );
  const nextToRun = useMemo(
    () =>
      !finishedSaving
        ? []
        : filter(
            dependencySort(steps || [], (s) =>
              map(s.refs?.blockedBy, (r) => r.id)
            ),

            (s) => {
              const deps = map(s.refs?.blockedBy, (r) => getStep(r.id));
              return (
                // Any steps that are not started and have no dependencies
                (s.status?.id === "NTS" &&
                  (!deps.length ||
                    some(deps, (d) => !!d && d.status?.id === "FNS"))) ||
                // Or waiting steps that haven't just been run now
                (s.status?.id === "WAI" &&
                  useISODate(s.updatedAt, (d) => secondsAgo(d) > 10))
              );
            }
          ),
    [steps]
  );
  const finished = useMemo(
    () => !!steps?.length && !nextToRun?.length && every(steps, isFinished),
    [nextToRun, steps]
  );

  const toCollect = useMemo(() => {
    // Only collect workflow inputs
    const vars = toVariables(workflow, []);
    return filter(vars, isEmptyRef);
  }, [workflow, steps]);

  const onCollected = useCallback(
    (vars: VariableDef[]) => {
      if (!workflow) {
        return;
      }
      const getVar = maybeLookup(vars, (v) => v.field);

      mutate(
        asUpdate(
          workflow,
          asMutation(
            { field: "inputs", type: "json" },
            safeAs<JsonArray>(
              map(workflow.inputs, (v) => {
                const collected = getVar(v.field);
                return collected ? { ...v, value: collected.value } : v;
              })
            )
          )
        )
      );

      setCollected(true);
    },
    [workflow?.inputs]
  );

  const ready =
    !workflow?.template &&
    equalsAny(workflow?.status?.id, ["RUN", "WAI"]) &&
    finishedSaving &&
    !!(finished || nextToRun?.length) &&
    stepRunner.ready &&
    (toCollect.length === 0 || collected);

  // Call the onFinished callback when the workflow has run everything it can
  useEffect(() => {
    if (
      // No unsaved changes
      finishedSaving &&
      // Loaded data
      workflow &&
      steps &&
      // Has nothing left to run
      !stepRunner.running &&
      !nextToRun?.length
    ) {
      once(() => onFinished?.(workflow));
    }
  }, [finishedSaving, workflow, steps, stepRunner.running, nextToRun]);

  return {
    run: useCallback(() => {
      if (!ready) {
        throw new Error("Workflow not ready to run.");
      }

      if (workflow) {
        mutate(
          asUpdate(
            workflow,
            asMutation({ field: "stamps.lastRun", type: "stamp" }, toStamp(me))
          )
        );
      }

      if (nextToRun?.length) {
        reset();
        stepRunner.run(nextToRun[0]);
      } else if (finished && workflow) {
        finishAndCleanup(workflow, steps || []);
      }
    }, [nextToRun, finished, ready]),
    toCollect,
    onCollected,
    running: stepRunner.running || false,
    message:
      stepRunner.message || when(nextToRun?.[0], (s) => toStepMessage(s, true)),
    ready: ready,
  };
};

type RunWorkflowContext = {
  workflow: Maybe<Workflow>;
  steps: Maybe<WorkflowStep[]>;
};

const useRunWorkflowStep = (
  { workflow, steps }: RunWorkflowContext,
  onFinished: (step: WorkflowStep) => void,
  onFailed: (step: WorkflowStep) => void
) => {
  const [running, setRunning] = useState<WorkflowStep>();
  const mutate = useMutate();
  const [once, reset] = useOnce(workflow?.id || "never");
  const getItem = useGetItemFromAnyStore();
  const getStep = useMemo(() => maybeLookupById(steps || []), [steps]);

  const creatingSource = useSource(
    fallback(
      () => running?.options?.entity as Maybe<EntityType>,
      () => (running?.action === "message" ? "note" : undefined)
    ),
    when(workflow, (w) => toChildLocation(w.source.scope, w.id))
  );

  const handleCompleted = useCallback(
    (step: WorkflowStep, status?: Status) => {
      const stepStatus = status || { id: "FNS" };
      mutate([
        asUpdate(
          step,
          asMutation(
            { field: "status", type: "status" },
            // If it's a task then set the status to waiting (for the work to be done), otherwise set it to finished
            stepStatus
          )
        ),
        ...(workflow
          ? [
              asUpdate(
                workflow,
                omitEmpty([
                  // Add the step to the finished refs
                  stepStatus?.id === "FNS"
                    ? asAppendMutation(
                        { field: "refs.finished", type: "relations" },
                        [{ id: step.id }]
                      )
                    : undefined,
                ])
              ),
            ]
          : []),
      ]);

      // Ordering important
      reset();
      setRunning(undefined);
      onFinished(step);
    },
    [onFinished, reset]
  );

  const storeRefInOutputs = useCallback(
    (step: WorkflowStep, ref: OneOrMany<Ref>) => {
      const refs = ensureArray(ref);
      if (!refs?.length) {
        return;
      }

      const refSource = {
        scope: step.source.scope,
        type: typeFromId(justOne(refs)?.id || ""),
      } as DatabaseID;

      mutate(
        asUpdate(
          step,
          asMutation(
            { field: "outputs", type: "json" },
            safeAs<JsonArray>(
              map(step.outputs, (v) => {
                return isAnyRelation(v) && referencesType(v, refSource.type)
                  ? { ...v, value: asRelationValue(v.type, refs) }
                  : { ...v, value: v.value || {} };
              })
            )
          )
        )
      );
    },
    [workflow, steps]
  );

  const handleCreated = useCallback(
    (step: WorkflowStep, newlyCreated?: Ref) => {
      // Temp hack – add any overrides to the created work
      if (newlyCreated) {
        const newSource = {
          scope: step.source.scope,
          type: typeFromId(newlyCreated.id),
        } as DatabaseID;

        mutate({
          id: newlyCreated.id,
          source: newSource,
          method: "update",
          changes: evalFormulas(
            step.overrides || [],
            toVariables(workflow, steps)
          ) as PropertyMutation[],
        });

        // If the step has outputs, update them with the newly created entity
        if (step.outputs?.length) {
          storeRefInOutputs(step, newlyCreated);
        }
      }

      const isTask =
        (step?.options?.entity || when(newlyCreated?.id, maybeTypeFromId)) ===
        "task";

      // Ordering important
      reset();
      setRunning(undefined);
      handleCompleted(step, { id: isTask ? "WAI" : "FNS" });
    },
    [workflow, handleCompleted, reset]
  );

  const handleRollback = useCallback(
    (step: WorkflowStep, status?: Status) => {
      mutate(
        asUpdate(
          step,
          asMutation(
            { field: "status", type: "status" },
            status || { id: "NTS" }
          )
        )
      );

      // Ordering important
      reset();
      setRunning(undefined);
      onFailed(step);
    },
    [onFailed, reset]
  );

  const create = useCreateFromObject(
    creatingSource?.type || "task",
    creatingSource?.scope
  );
  const template = useCreateFromTemplate(creatingSource, (created) => {
    running && handleCreated(running, created);
  });
  const ai = useAiUseCase(workflowStep);

  const defaultChannel = useSetting<string>(
    workflow?.id || "",
    "settings.channel"
  );
  const threadValue = useMemo(
    () => asFormulaValue(safeAs<string>(running?.options?.thread)),
    [running?.options?.thread]
  );
  const replyToRef = useMemo(
    () =>
      when(
        threadValue?.formula,
        (formula) =>
          evalFormula(formula, "relation", toVariables(workflow, steps))
            ?.relation
      ),
    [threadValue]
  );
  const replyTo = useLazyEntity<"note">(replyToRef?.id);
  const [channel, thread] = useMemo(
    () =>
      when(replyTo?.links?.[0]?.url, (url) => [
        toChannelId(url),
        toThreadId(url),
      ]) || [
        safeAs<string>(running?.options?.channel) || defaultChannel,
        threadValue.text,
      ],
    [running?.options, replyTo]
  );
  const sendSlack = useReplyOrCreateThread(channel, thread);

  const run = useCallback(async () => {
    if (!running || !workflow || !template.ready) {
      throw new Error("Tried to run workflow step before ready.");
    }

    // Mark the step as running
    mutate(
      asUpdate(
        running,
        asMutation({ field: "status", type: "status" }, { id: "RUN" })
      )
    );

    try {
      await switchEnum(running.action || "", {
        exit: () => {
          mutate(
            asUpdate(
              workflow,
              asMutation({ field: "status", type: "status" }, { id: "FNS" })
            )
          );

          handleCompleted(running, { id: "FNS" });
        },

        entry: () => {
          mutate(
            asUpdate(
              workflow,
              asMutation({ field: "status", type: "status" }, { id: "RUN" })
            )
          );

          handleCompleted(running);
        },

        wait: () => {
          const firstStarted =
            when(running?.stamps?.["status_in-progress"]?.at, toJSDate) ||
            now();
          const waitTimes = safeAs<number>(running?.options?.waitTimes) || 0;
          const waitPeriod =
            safeAs<Period>(running?.options?.waitPeriod) || Period.Day;

          const end = addPeriod(firstStarted, waitPeriod, waitTimes);

          if (isGreaterThan(now(), end)) {
            handleCompleted(running);
          } else {
            handleRollback(running, { id: "WAI" });
          }
        },

        condition: () => {
          const getVar = maybeLookup(
            toVariables(workflow, steps),
            (v) => v.field
          );
          const targetId =
            safeAs<string>(running.options?.sourceEntity) ||
            when(safeAs<string>(running.options?.sourceVar), (v) => {
              const variable = getVar(v);
              return (
                variable?.value.relation?.id ||
                justOne(variable?.value.relations)?.id
              );
            });

          const target = when(targetId, (id) => getRecoil(GenericItem(id)));

          if (!target) {
            throw new Error("No target found for update.");
          }

          const filter = getPropertyValue(running, {
            field: "options.filter",
            type: "json",
          })?.json as FilterQuery;

          const props = getRecoil(allPropertiesForSource(target.source));
          const indexedProps = indexBy(props, (p) => p.field);

          const passed = passes(target, filter, indexedProps);

          if (!!running?.options?.keepChecking) {
            handleRollback(running, { id: "NTS" });
            return;
          }

          mutate([
            // Store results in outputs
            asUpdate(
              running,
              asMutation(
                { field: "outputs", type: "json" },
                safeAs<JsonArray>([
                  {
                    field: when(running.name, snakeCase) || running.id,
                    type: "boolean",
                    value: { boolean: passed },
                  },
                ])
              )
            ),
            // Close downstream steps
            ...maybeMap(running.refs?.blocks || [], (r) =>
              (
                passed
                  ? // If passed, then close all else flows
                    containsRef(running?.refs?.else, r)
                  : // Else close all non-else flows
                    !containsRef(running?.refs?.else, r)
              )
                ? asUpdate(
                    { id: r.id, source: running.source },
                    asMutation(
                      { field: "status", type: "status" },
                      { id: "SKP" }
                    )
                  )
                : undefined
            ),
          ]);

          handleCompleted(running);
        },

        find: async () => {
          const output = running.outputs?.[0];

          if (!output) {
            throw new Error("No out found for find.");
          }

          const findSource = {
            type: running.options?.entity,
            scope:
              when(safeAs<string>(running?.options?.within), (s) => {
                if (isFormula(s)) {
                  const id = evalFormula(
                    s,
                    "relation",
                    toVariables(workflow, steps)
                  )?.relation?.id;
                  const item = when(id, getItem);
                  return item
                    ? toChildLocation(item?.source.scope, item.id)
                    : id;
                }

                return s;
              }) || toBaseScope(workflow.source.scope),
          } as DatabaseID;

          const baseFilter: FilterQuery = {
            field: "location",
            op: "contains",
            type: "text",
            value: { text: findSource.scope },
          };
          const userFilter = safeAs<FilterQuery>(
            getPropertyValue(running, {
              field: "options.filter",
              type: "json",
            })?.json
          );
          const filter = { and: omitEmpty([baseFilter, userFilter]) };

          const limit = safeAs<number>(running.options?.limit) || 100;
          const { all } = await getOptimizedForFilter(findSource, filter, {
            since: toISODate(now(), "point"), // Don't return results, just IDs
            limit,
            archived: false,
            templates: false,
          });

          mutate(
            asUpdate(
              running,
              asMutation(
                { field: "outputs", type: "json" },
                safeAs<JsonArray>([
                  {
                    ...output,
                    value:
                      limit === 1
                        ? { relation: when(first(all), toRef) }
                        : { relations: map(all, toRef) },
                  },
                ])
              )
            )
          );

          handleCompleted(running);
        },

        update: () => {
          const variables = toVariables(workflow, steps);
          const getVar = maybeLookup(variables, (v) => v.field);
          const targetIds =
            when(safeAs<string>(running.options?.sourceEntity), ensureArray) ||
            when(safeAs<string>(running.options?.sourceVar), (v) => {
              const variable = getVar(v);
              return (
                when(variable?.value.relation, ensureArray) ||
                variable?.value.relations
              );
            });

          const targets = maybeMap(targetIds || ([] as RefOrID[]), (refOrId) =>
            getRecoil(GenericItem(toRef(refOrId)?.id))
          );

          if (!targets?.length || targetIds?.length !== targets.length) {
            throw new Error("No target found for update.");
          }

          mutate(
            map(targets, (target) =>
              asUpdate(
                target,
                fromOverrides(
                  evalFormulas(running.overrides || [], variables)
                ) as PropertyMutation[]
              )
            )
          );
          handleCompleted(running);
        },

        set_var: () => {},

        control: () => {
          const all = safeAs<boolean>(running.options?.all) || false;
          const refs = ensureMany(running.refs?.blockedBy);
          const blocked = map(refs, (r) => getStep(r.id));
          const requirement = all ? every : some;

          if (requirement(blocked, (b) => !!b && b.status?.id === "FNS")) {
            handleCompleted(running);
          } else {
            handleRollback(running, { id: "WAI" });
          }
        },

        ai: async () => {
          try {
            const outputValues = await ai.run({
              workflow,
              step: running,
              steps: steps || [],
            });

            const getFieldValue = maybeLookup(
              outputValues || [],
              (v) => safeAs<PropertyValueRef>(v)?.field
            );

            // Add the values to the outputs if when provided by ai
            mutate(
              asUpdate(
                running,
                asMutation(
                  { field: "outputs", type: "json" },
                  safeAs<JsonArray>(
                    map(
                      running.outputs,
                      (o) =>
                        when(getFieldValue(o.field), (v) => ({
                          ...o,
                          value: safeAs<PropertyValueRef>(v)?.value || o.value,
                        })) || o
                    )
                  )
                )
              )
            );

            handleCompleted(running);
          } catch (err) {
            log(err);
            handleRollback(running);
          }
        },

        message: async () => {
          // TODO: Add support for email/sms
          // TODO: Support using relation variables and evalling them to something useful in slack.

          const messageRaw = toPropertyValueRef(running, {
            field: "options.message",
            type: "rich_text",
          });

          if (!messageRaw) {
            throw new Error("No message found to send.");
          }

          const [message] = evalFormulas(
            [messageRaw],
            toVariables(workflow, steps)
          );

          if (!message.value.rich_text) {
            throw new Error("No message to send after evaluating formulas.");
          }

          const { link: url } = await sendSlack(
            {
              message: message.value.rich_text,
            },
            {
              message: {
                html: `<p>Thread created from <a data-mention-id="${workflow.id}">workflow</a>.</p>`,
              },
            }
          );
          const link = url ? { text: "Slack Thread", url: url } : undefined;

          const saved = create?.([
            flattenChanges([
              { field: "type", type: "text", value: { text: "update" } },
              {
                field: "links",
                type: "links",
                value: { links: link ? [link] : undefined },
              },
              {
                field: "title",
                type: "text",
                value: { text: "Workflow Message" },
              },
              {
                field: "body",
                type: "rich_text",
                value: { rich_text: message.value.rich_text },
              },
              {
                field: "refs.fromStep",
                type: "relations",
                value: { relations: [{ id: running.id }] },
              },
              {
                field: "refs.fromWorkflow",
                type: "relations",
                value: { relations: [{ id: workflow.id }] },
              },
              {
                field: "refs.followers",
                type: "relations",
                value: { relations: running.refs?.followers },
              },
            ]),
          ]);

          // Publish note to variables if options variable name is set
          // saved;

          handleCreated(running, justOne(saved));
        },

        create: () => {
          // Already ran and waiting for the work to finish...
          if (running?.status?.id === "WAI") {
            handleRollback(running, { id: "WAI" });
            return;
          }

          // Already ran and created but didn't save properly...
          if (!!running?.refs?.created?.length) {
            handleCompleted(running);
            return;
          }

          const createTemplate = when(
            safeAs<string>(running.options?.useTemplate),
            toRef
          );

          if (createTemplate) {
            template.create(createTemplate, {
              overrides: {
                "*": {
                  refs: {
                    fromWorkflow: when(
                      workflow?.id,
                      composel(toRef, ensureArray)
                    ),
                    fromStep: [{ id: running.id }],
                  },
                },
              },
            });
          } else {
            const created = create?.([
              merge(
                flattenChanges(
                  evalFormulas(
                    running.overrides || [],
                    toVariables(workflow, steps)
                  )
                ),
                {
                  refs: {
                    fromWorkflow: when(
                      workflow?.id,
                      composel(toRef, ensureArray)
                    ),
                    fromStep: [{ id: running.id }],
                  },
                }
              ),
            ]);
            handleCreated(running, justOne(created));
          }
        },
        else: () => {},
      });
    } catch (err) {
      log(err);
      handleRollback(running);
    }
  }, [
    running,
    template.create,
    create,
    workflow,
    steps,
    handleCreated,
    handleRollback,
    handleCompleted,
    storeRefInOutputs,
  ]);

  useAsyncEffect(async () => {
    if (running?.id && template?.ready) {
      once(() => run());
    }
  }, [running?.id, template?.ready]);

  return {
    run: (ws: WorkflowStep) => {
      if (running) {
        throw new Error(`Already running another step (${running.id}).`);
      }
      setRunning(ws);
    },
    running: running?.id || false,
    message: useMemo(
      () => (running ? toStepMessage(running, true) : undefined),
      [running]
    ),
    ready: !running,
  };
};

export const useVariables = (
  workflow: Maybe<Workflow>,
  steps: Maybe<WorkflowStep[]>
) => {
  return useMemo(() => toVariables(workflow, steps), [workflow?.inputs, steps]);
};

export const useWorkflowSteps = (workflow: Maybe<Workflow>) => {
  const all = useLazyEntities<"workflow_step">(workflow?.refs?.steps);
  return useMemo(
    () => dependencySort(all || [], (s) => map(s.refs?.blockedBy, (r) => r.id)),
    [all]
  );
};

export const useShowRunning = (workflows: Maybe<Workflow[]>) => {
  const [showAll, setShowAll] = useState(false);
  const filtered = useMemo(
    () => filter(workflows, not(isFinished)),
    [workflows, showAll]
  );
  const moreCount = minus(workflows?.length, filtered?.length);

  return {
    visible: showAll ? workflows : filtered,
    hasMore: moreCount > 0,
    moreCount,
    showAll,
    setShowAll,
  };
};
