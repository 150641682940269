import { filter, find, map, orderBy } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";

import {
  claimJob,
  Entity,
  ID,
  Job,
  JobStatus,
  Update,
  updateJobStatus,
} from "@api";

import { useQueueUpdates } from "@state/generic";
import {
  isLockedByMe,
  isRunning,
  isTimedOut,
  JobStoreAtom,
  RUNNERS,
  setJob,
  useJobQueue,
} from "@state/jobs";
import { useMe } from "@state/persons";

import { ensureMany, OneOrMany } from "@utils/array";
import { useISODate } from "@utils/date-fp";
import { addInfo, debug, log } from "@utils/debug";
import { useAsyncEffect } from "@utils/effects";
import { useWindowEvent } from "@utils/event";
import { Fn, use } from "@utils/fn";
import { useOnce } from "@utils/hooks";
import { newID } from "@utils/id";
import { switchEnum } from "@utils/logic";
import { when } from "@utils/maybe";
import { toRef } from "@utils/property-refs";
import { now, secondsAgo, useTick } from "@utils/time";

import { usePageId } from "@ui/app-page";
import { WithMutateContext } from "@ui/mutate";

// Lock key prevents multiple tabs from running the same job
const LOCK_KEY = newID();

export const claimAndRunJob = async (job: Job | ID) => {
  const updated = await claimJob(toRef(job).id, LOCK_KEY);
  if (updated) {
    setRecoil(JobStoreAtom, setJob(updated));
  }
  return updated;
};

const replaceCreatedBy = (
  updates: OneOrMany<Update<Entity>>,
  id: string
): Update<Entity>[] =>
  map(ensureMany(updates), (u) =>
    u.method === "create"
      ? {
          ...u,
          changes: [
            ...u.changes,
            {
              field: "createdBy",
              type: "relation",
              value: { relation: { id } },
            },
          ],
        }
      : u
  );

export const JobsQueue = () => {
  const [init, setInit] = useState(now());
  const me = useMe();
  const { jobs } = useJobQueue();
  const _mutate = useQueueUpdates();
  const isIdle = useMemo(
    () => secondsAgo(init) > 15,
    [init, useTick("5 seconds")]
  );

  const myQueue = useMemo(
    () =>
      orderBy(
        // Unclaimed jobs or running jobs claimed by me
        filter(jobs, (j) =>
          switchEnum(j.status || "", {
            // Always return jobs that have been claimed by this tab
            running: () => j.lockedBy?.id === me.id && j.lockKey === LOCK_KEY,

            // Return unclaimed jobs if idle
            queued: isIdle,

            // Else not for me
            else: false,
          })
        ),
        (j) =>
          use(
            switchEnum(j.status, {
              running: 1,
              queued: 2,
              else: 3,
            }),
            (index) => `${index}-${j.createdAt}`
          ),
        "asc"
      ),
    [jobs, isIdle]
  );

  const next = useMemo(() => myQueue?.[0], [myQueue]);

  const mutate = useCallback(
    (updates: OneOrMany<Update<Entity>>) => {
      if (next?.createdBy?.id) {
        _mutate(replaceCreatedBy(updates, next.createdBy.id));
      } else {
        _mutate(updates);
      }
    },
    [next?.createdBy?.id, _mutate]
  );

  // When window is refocused, set init to now
  useWindowEvent("focus", () => setInit(now()));

  return (
    <div style={{ display: "none" }}>
      <WithMutateContext mutate={mutate}>
        {next && <JobRunner key={next.id} job={next} />}
      </WithMutateContext>
    </div>
  );
};

interface RunnerProps {
  job: Job;
  onCompleted?: Fn<Job, void>;
}

function JobRunner({ job, onCompleted }: RunnerProps) {
  const pageId = usePageId();
  const me = useMe();
  const Runner = useMemo(
    () => find(RUNNERS, (r) => r.accepts(job))?.runner,
    [job]
  );
  const mutate = useQueueUpdates(pageId);
  const setStore = useSetRecoilState(JobStoreAtom);
  const claiming = useRef(false);
  const [once] = useOnce(`job-runner-${job.id}-${job.lockedAt}-${job.lockKey}`);

  const handleCompleted = useCallback(async () => {
    try {
      const updated = await updateJobStatus(job.id, JobStatus.Completed);
      when(updated, (j) => setStore(setJob(j)));
      onCompleted?.(updated || job);
    } catch (err) {
      addInfo({ state: "Trying to complete running job.", job });
      log(err);
    }
  }, [setStore, onCompleted, job?.id]);

  const handleFailed = useCallback(async () => {
    try {
      when(await updateJobStatus(job.id, JobStatus.Failed), (j) =>
        setStore(setJob(j))
      );
    } catch (err) {
      addInfo({ state: "Trying to complete running job.", job });
      log(err);
    }
  }, [setStore, job?.id]);

  useWindowEvent(
    "beforeunload",
    (e) => {
      if (
        isRunning(job) &&
        isLockedByMe(job, me, LOCK_KEY) &&
        !isTimedOut(job)
      ) {
        e.stopImmediatePropagation();
        e.returnValue =
          "Background jobs are being run. Leaving now could cause faulty data. Wait a few seconds and try again. Or reload anyway?";
      }
    },
    false,
    [job.status]
  );

  // Try claim job whenever ID, staus or Runner changes
  useAsyncEffect(async () => {
    if (job.status === "queued" && !!Runner && !claiming.current) {
      claiming.current = true;
      try {
        claimAndRunJob(job);
      } catch (err) {
        // Job already claimed by someone else, ignore the error
      } finally {
        claiming.current = false;
      }
    }
  }, [job.id, job.status, Runner]);

  // Auto failing jobs that were locked for more than one minute
  useEffect(() => {
    if (useISODate(job.lockedAt, (d) => secondsAgo(d) > 60)) {
      debug("Job locked for more than a minute.", job);
      handleFailed();
    }
  }, [job.lockedAt, handleFailed, useTick("10 seconds")]);

  // Not ready to run, still being claimed
  if (!isRunning(job)) {
    return <></>;
  }

  // No runner found
  if (!Runner) {
    debug("No runner found for job.");
    return <></>;
  }

  // Not locked by me, or is locked by another tab/window/component
  if (!isLockedByMe(job, me, LOCK_KEY)) {
    debug("Not locked by me.", { job, me, LOCK_KEY, Runner });
    return <></>;
  }

  if (isTimedOut(job)) {
    debug("Job timed out.", { job, me, LOCK_KEY, Runner });
    return <></>;
  }

  return (
    <Runner
      key={job.id}
      job={job}
      once={once}
      mutate={mutate}
      onCompleted={handleCompleted}
      onFailed={handleFailed}
    />
  );
}
