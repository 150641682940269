import { debounce, findIndex, first, last, map, throttle } from "lodash";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { isWithinEditable, Keys, useWindowEvent } from "@utils/event";
import { ifDo, switchEnum } from "@utils/logic";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { isCommandKey } from "@utils/mouse";

import { useDebouncedMemo } from "./hooks";
import { add, addAll, last as setLast, without } from "./set";

/* State */

export interface SelectionState {
  selected: Set<string>;
  focused: Maybe<string>;
}

export type SetSelectionState = Dispatch<SetStateAction<SelectionState>>;

export const isSelected = (state: Maybe<SelectionState>, key: string) =>
  (!!key && state?.selected.has(key)) ?? false;

export const isFocused = (state: SelectionState, key: string) =>
  key && state.focused === key;

export const isMultiSelected = (state: Maybe<SelectionState>) =>
  (state?.selected?.size ?? 0) > 1;

type SelectionMode = "select" | "deselect" | "toggle" | "replace";

export const deselectOne = (key: string) => (state: SelectionState) =>
  !state.selected?.has(key)
    ? state
    : {
        ...state,
        selected: without(state.selected, key),
        focused: setLast(state.selected),
      };

export const selectOne = (key: string) => (state: SelectionState) =>
  state.focused === key && state.selected.has(key)
    ? state
    : {
        ...state,
        selected: state.selected.has(key)
          ? state.selected
          : add(state.selected, key),
        focused: key,
      };

export const selectOnly = (key: string) => (state: SelectionState) =>
  state.focused === key && state.selected.size === 1
    ? state
    : {
        ...state,
        selected: new Set([key]),
        focused: key,
      };

export const toggleOne = (key: string) => (state: SelectionState) =>
  state.selected.has(key) ? deselectOne(key)(state) : selectOne(key)(state);

export const selectAll =
  (keys: string[], mode: SelectionMode, focused?: Maybe<string>) =>
  (state: SelectionState) => ({
    ...state,
    selected: mode === "replace" ? new Set(keys) : addAll(state.selected, keys),
    focused: focused || last(keys),
  });

export const select = (key: string, mode: SelectionMode = "replace") =>
  switchEnum(mode, {
    deselect: () => deselectOne(key),
    toggle: () => toggleOne(key),
    select: () => selectOne(key),
    replace: () => selectOnly(key),
    else: () => selectOne(key),
  });

export const clearSelection = () => (s: SelectionState) =>
  !s.selected?.size
    ? s
    : {
        selected: new Set([]),
        focused: undefined,
      };

/* HTML Manipulation */

const removeTextSelection = throttle(
  () => window.getSelection()?.removeAllRanges(),
  200
);

const canDragFrom = (e: MouseEvent) =>
  !e.defaultPrevented &&
  ((!isWithinEditable(e) &&
    !["H1", "SPAN", "H2", "H3"].includes((e.target as HTMLElement).nodeName)) ||
    e.shiftKey) &&
  // Can only start draf from within an ignore-drags when shift key is down
  (!(e.target as HTMLElement)?.closest(
    '[data-selectable-ignore-drags="true"]'
  ) ||
    e.shiftKey);

const canDragMove = (e: MouseEvent) => !e.defaultPrevented;

const isOverlapping = (r: Rect, r2: Rect) =>
  !(
    r.top > r2.top + r2.height ||
    r.left + r.width < r2.left ||
    r.top + r.height < r2.top ||
    r.left > r2.left + r2.width
  );

const asSelectable = (element: Maybe<Element> | null) => {
  const key = element?.getAttribute("data-selectable-key");

  if (!element || !key) {
    return undefined;
  }

  return { element, key };
};

const getParentSelectable = (e: Event) =>
  asSelectable((e.target as HTMLElement)?.closest('[data-selectable="true"]'));

const getAllSelectables = () =>
  maybeMap(
    Array.from(document.querySelectorAll('[data-selectable="true"]')),
    asSelectable
  );

export const getNextSelectable = (element: Maybe<Element>) => {
  const all = Array.from(document.querySelectorAll('[data-selectable="true"]'));
  const current = element ? all.indexOf(element) : -1;
  return asSelectable(all[current + 1]);
};

export const getPrevSelectable = (element: Maybe<Element>) => {
  const all = Array.from(document.querySelectorAll('[data-selectable="true"]'));
  const current = element ? all.indexOf(element) : all.length;
  return asSelectable(all[current - 1]);
};

const getFirstSelectable = () => {
  const all = Array.from(document.querySelectorAll('[data-selectable="true"]'));
  return asSelectable(first(all));
};

const getLastSelectable = () => {
  const all = Array.from(document.querySelectorAll('[data-selectable="true"]'));
  return asSelectable(last(all));
};

export const getSelectable = (id: string) =>
  asSelectable(document.querySelector(`[data-selectable-key="${id}"]`));

const getFocusedSelectable = (state: SelectionState) =>
  when(state.focused, getSelectable);

const canKeyFrom = (e: KeyboardEvent) => !isWithinEditable(e);

/* Drag State */

interface Point {
  x: number;
  y: number;
}

type Rect = {
  top: number;
  left: number;
  height: number;
  width: number;
};

interface DragState {
  from: Maybe<Point>;
  to: Maybe<Point>;
  enabled: boolean;
}

const toPoint = (e: MouseEvent) => ({ x: e.clientX, y: e.clientY });

const toRect = (p: Point, p2: Point) => ({
  top: Math.min(p.y, p2.y),
  left: Math.min(p.x, p2.x),
  width: Math.abs(p2.x - p.x),
  height: Math.abs(p2.y - p.y),
});

const getDistance = (p: Point, p2: Point) =>
  Math.sqrt(Math.pow(p.y - p2.y, 2) + Math.pow(p.x - p2.x, 2));

const clearDrag = () => (_s: DragState) => ({
  from: undefined,
  to: undefined,
  enabled: false,
});

/* Component */

export type MouseHighlight = { dragging: false } | ({ dragging: true } & Rect);

export const useSelectableState = () =>
  useState<SelectionState>({
    selected: new Set([]),
    focused: undefined,
  });

export function useMouseSelectable(
  selection: SelectionState,
  setSelection: SetSelectionState,
  enabled: boolean = true
): MouseHighlight {
  // TODO: Pull out into seperate hook
  const [drag, _setDrag] = useState<DragState>({
    from: undefined,
    to: undefined,
    enabled: false,
  });
  const setDrag = useCallback(throttle(_setDrag, 10, { leading: true }), []);

  useWindowEvent(
    "mousedown",
    (e) => {
      /*
       * Drag selection starting
       */

      if (enabled && canDragFrom(e)) {
        // Start immediately if shift key down
        if (e.shiftKey) {
          setDrag(() => ({ from: toPoint(e), to: undefined, enabled: true }));
        } else {
          setDrag(() => ({ from: toPoint(e), to: undefined, enabled: false }));
        }
      }

      /*
       * Mouse selection
       */
      if (
        // Ignore when not marked as enabled
        !enabled ||
        // Ignore right clicks
        e.button === 2 ||
        // Ignore direct body (detatched nodes) clicks
        (e.target as HTMLElement)?.nodeName === "BODY" ||
        e.defaultPrevented ||
        !!(e.target as HTMLElement)?.closest(
          '[data-selectable-ignore-clicks="true"]'
        ) ||
        (drag.from && drag.enabled)
      ) {
        return;
      }

      const selected = getParentSelectable(e);
      const focused = getFocusedSelectable(selection);

      if (!selected && e.shiftKey) {
        // Don't deselect on shift + click away as start of drag
        return;
      } else if (!selected) {
        setSelection(clearSelection());
        return;
      }

      if (e.shiftKey && focused && focused.key !== selected.key) {
        const all = getAllSelectables();
        const top = findIndex(all, (s) => s.key === focused.key);
        const bottom = findIndex(all, (s) => s.key === selected.key);
        const from = Math.min(top, bottom);
        const to = Math.max(top, bottom);
        const selection = maybeMap(all, (a, i) =>
          i >= from && i <= to ? a.key : undefined
        );
        setSelection(selectAll(selection, "select", selected.key));
        // Handle event when shift key is in use
        e.preventDefault();
      } else if (isCommandKey(e)) {
        setSelection(select(selected.key, "toggle"));
        // Handle event when command key is in use
        e.preventDefault();
      } else if (!selection.selected.has(selected.key)) {
        // Don't replace selection for already selected items on mousedown,
        // but rather wait for mouseup (desired behaviour) + solves dragging
        setSelection(select(selected.key, "replace"));
        // Don't handle the event here so that Drag or Text inputs can still use
      }
    },
    true,
    [selection.selected, selection.focused, enabled, drag]
  );

  // Dragging
  useWindowEvent(
    "mousemove",
    (e) => {
      // When selectable is not enabled
      if (!enabled) {
        return;
      }

      // Clear drag if mouse button is no longer down
      // Any just ignores deprecated which for linting purposes
      if (
        e.defaultPrevented ||
        !drag.from ||
        !(e.buttons ?? (e as any).which)
      ) {
        if (drag.enabled) {
          setDrag(clearDrag());
        }
        return;
      }

      if (!!drag.from && canDragMove(e)) {
        // Stops text selections
        removeTextSelection();

        const calculateOverlap = debounce((from: Point, to: Point) => {
          const all = getAllSelectables();
          const overlapping = maybeMap(all, (s) =>
            isOverlapping(s.element.getBoundingClientRect(), toRect(from, to))
              ? s?.key
              : undefined
          );
          setSelection(selectAll(overlapping, "select", undefined));
        }, 10);

        const to = toPoint(e);
        const dragging =
          drag.enabled ||
          when(
            drag.from,
            (from) => getDistance(from, to) > (e.shiftKey ? 5 : 80)
          ) ||
          false;

        setDrag((s) => ({ ...s, to, enabled: dragging }));

        if (dragging) {
          calculateOverlap(drag.from, to);
          e.preventDefault();
        }
      }
    },
    true,
    [selection.selected, selection.focused, enabled, drag]
  );

  // Drag end
  useWindowEvent(
    "mouseup",
    (e) => {
      // When selectable is not enabled
      if (!enabled) {
        return;
      }

      const selected = getParentSelectable(e);

      // Clear out the drag history always but wait 1ms
      // so that the click event doesn't deselect highlighted items
      setTimeout(() => setDrag(clearDrag()), 1);

      // Handle event when from an active drag
      if (drag.enabled) {
        removeTextSelection();
        e.preventDefault();
      } else if (!isCommandKey(e) && !e.shiftKey && selected) {
        setSelection(select(selected.key, "replace"));
      }
    },
    true,
    [selection.selected, selection.focused, enabled, drag]
  );

  if (!drag.to || !drag.from || !drag.enabled) {
    return { dragging: false };
  }

  return {
    dragging: true,
    ...toRect(drag.from, drag.to),
  };
}

export function useKeyboardSelectable(
  state: SelectionState,
  setState: SetSelectionState,
  enabled: boolean = true
) {
  // Keyboard selection
  useWindowEvent(
    "keydown",
    (e) =>
      enabled &&
      switchEnum(!canKeyFrom(e) ? "" : (e.code as Keys), {
        // Select all
        KeyA: () => {
          if (e?.metaKey) {
            const all = getAllSelectables();
            if (all.length) {
              const selection = map(all, (a) => a.key);
              setState(selectAll(selection, "replace", last(selection)));
              e.preventDefault();
            }
          }
        },
        // Lol logic needs lots of work
        Escape: () =>
          ifDo(!!state.selected.size, () => {
            setState(clearSelection());
            e.preventDefault();
          }),
        ArrowDown: () => {
          const focused = getFocusedSelectable(state);

          // Pressing down arrow when nothing is selected, selects the
          // first selectable in the list
          if (!focused) {
            return when(getFirstSelectable(), (first) => {
              e.preventDefault();
              setState(select(first.key, "replace"));
            });
          }

          const above = getPrevSelectable(focused.element);
          const below = getNextSelectable(focused.element);

          // At the bottom of the list, nowhere to go
          if (!below) {
            return;
          }

          // Handled here.
          e.preventDefault();

          // Not multi-selecting
          if (!e.shiftKey) {
            setState(select(below.key, "replace"));
            return;
          }

          const shouldDeselectCurrent =
            // No above selectable (top of list)
            // And below is already selected (on island)
            (!above && !!state.selected.has(below.key)) ||
            // Previous selectable exists and is not selected
            // And below is already selected (on island)
            (above &&
              !state.selected.has(above.key) &&
              !!state.selected.has(below.key));

          if (shouldDeselectCurrent) {
            setState(select(focused.key, "deselect"));
          }

          setState(select(below.key, "select"));
        },
        ArrowUp: () => {
          const focused = getFocusedSelectable(state);

          // Pressing up arrow when nothing is selected, selects the
          // last selectable in the list
          if (!focused) {
            return when(getLastSelectable(), (last) => {
              // Handled here.
              e.preventDefault();
              setState(select(last.key, "replace"));
            });
          }

          // Else select the selectable above the currently focused
          const above = getPrevSelectable(focused.element);
          const below = getNextSelectable(focused.element);

          // At the top of the list, nowhere to go
          if (!above) {
            return;
          }

          // Handled here.
          e.preventDefault();

          // Not multi-selecting
          if (!e.shiftKey) {
            setState(select(above.key, "replace"));
            return;
          }

          const shouldDeselectCurrent =
            // No selectable below (bottom of list)
            // and above selectable is already selected (on edge of island)
            (!below && !!state.selected.has(above.key)) ||
            // Below selectable exists and is not selected
            // above selectable is already selected (on edge of island)
            (below &&
              !state.selected.has(below.key) &&
              !!state.selected.has(above.key));

          if (shouldDeselectCurrent) {
            setState(select(focused.key, "deselect"));
          }

          setState(select(above.key, "select"));
        },
        else: () => {},
      }),
    // Skip already handled events
    true,
    [state.selected, state.focused, enabled]
  );
}

export const IgnoreSelectable = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{ display: "contents" }}
      data-selectable-ignore-clicks="true"
      data-selectable-ignore-drags="true"
    >
      {children}
    </div>
  );
};

export function useSelectableIgnoreClicks() {
  return { "data-selectable-ignore-clicks": "true" };
}

export function useSelectable(key: string) {
  return {
    "data-selectable": "true",
    "data-selectable-key": key,
    "data-selectable-ignore-drags": "true",
  };
}
export const PageSelectionContext =
  createContext<Maybe<[SelectionState, SetSelectionState]>>(undefined);

export function usePageSelection() {
  const local = useSelectableState();
  const page = useContext(PageSelectionContext);
  return page ?? local;
}

export function useSelected(id: string, selection: Maybe<SelectionState>) {
  return useMemo(
    () => !!selection && isSelected(selection, id),
    [selection, id]
  );
}

export function useSlowSelected(id: string, selection: Maybe<SelectionState>) {
  const selected = useSelected(id, selection);
  return useDebouncedMemo(() => !!selected, 300, [selected]);
}
