import {
  flatten,
  groupBy,
  isEmpty,
  isString,
  map,
  result,
  some,
  values,
} from "lodash";
import { useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";

import {
  Entity,
  EntityType,
  FetchOptions,
  FilterQuery,
  ID,
  Ref,
  RelationRef,
} from "@api";
import { EntityForType } from "@api/mappings";

import {
  isPropForEntity,
  PropertyDefStoreAtom,
  useLazyProperties,
  useProperties,
} from "@state/databases";
import { FetchResultsAtom, updateFetchedResults } from "@state/fetch-results";
import { setItems } from "@state/store";
import { useActiveWorkspaceId } from "@state/workspace";

import { ensureMany, OneOrMany } from "@utils/array";
import { fromPointDate, ISODate } from "@utils/date-fp";
import { useAsyncEffect } from "@utils/effects";
import { Fn } from "@utils/fn";
import { isLocalID, isTemplateId, maybeTypeFromId } from "@utils/id";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { maybeValues } from "@utils/object";
import { orderItems } from "@utils/ordering";
import { mapAll } from "@utils/promise";
import { useArrayKey } from "@utils/react";
import { hashable } from "@utils/serializable";

import { getStore } from "../atoms";
import {
  getEntitiesLoader,
  getEntityLoader,
  getItemsNestedWithinLoader,
  getOptimizedForFilter,
} from "../queries";
import { GenericItem, itemsForQuery } from "../selectors";
import { isInflated, toNestedTypes } from "../utils";
import { useGetItemFromAnyStore, useItemsAsEntityMap } from "./core";

type EntityIDMap = Partial<{ [key in EntityType]: ID[] }>;

// Sets items in their respective stores
const setItemsInStore = (items: OneOrMany<Entity>) =>
  map(
    groupBy(
      ensureMany(items),
      (l) => l?.source?.type || maybeTypeFromId(l.id) || ""
    ),
    (v, k) => !!k && setRecoil(getStore(k as EntityType), setItems(v))
  );

export const useLazyEntity = <T extends EntityType = EntityType>(
  id: Maybe<ID>,
  fetch: boolean = true
): Maybe<EntityForType<T>> => {
  const entity = useRecoilValue(GenericItem(id || ""));

  useAsyncEffect(async () => {
    if (
      !id ||
      (!!entity && !fetch) ||
      maybeTypeFromId(id) === "workspace" ||
      isTemplateId(id) ||
      isLocalID(id)
    ) {
      return;
    }

    await getEntityLoader(
      id,
      when(entity?.updatedAt, fromPointDate),
      setItemsInStore
    );
  }, [id, !entity]); // Reload when ID changes or if entity gets removed from store to trigger reload

  return entity as Maybe<EntityForType<T>>;
};

export const useEntityState = <T extends EntityType = EntityType>(): [
  Maybe<Entity | Ref>,
  Fn<Maybe<Ref>, void>
] => {
  const [ref, setRef] = useState<Ref>();
  const entity = useLazyEntity<T>(ref?.id);
  return [entity || ref, setRef];
};

export const useLazyEntities = <T extends EntityType = EntityType>(
  refs: Maybe<(Ref | ID)[]>,
  fetch: boolean = true
): Maybe<EntityForType<T>[]> => {
  const ids = useMemo(() => map(refs, (r) => (isString(r) ? r : r.id)), [refs]);
  const refsDep = useMemo(() => ids.join(","), [ids]);
  const getItem = useGetItemFromAnyStore();

  useAsyncEffect(async () => {
    if (!fetch || isEmpty(ids) || some(ids, isLocalID)) {
      return;
    }

    await getEntitiesLoader(ids, setItemsInStore);
  }, [refsDep]);

  return useMemo(
    () => maybeMap(ids, getItem) as EntityForType<T>[],
    [getItem, refsDep]
  );
};

export const useOrderedEntities = <T extends EntityType = EntityType>(
  refs: Ref[],
  fetch: boolean = true
): Maybe<EntityForType<T>[]> => {
  const items = useLazyEntities<T>(refs, fetch);
  return useMemo(() => orderItems<EntityForType<T>>(items || []), [items]);
};

export const useLazyQuery = <T extends EntityType = EntityType>(
  type: T,
  filter: FilterQuery<EntityForType<T>>,
  opts?: FetchOptions
): EntityForType<T>[] => {
  const workspaceId = useActiveWorkspaceId();
  const hashed = useMemo(
    () => hashable({ type, filter, opts }),
    [type, filter, opts?.templates, opts?.fetch, opts?.archived]
  );
  const localItems = useRecoilValue(itemsForQuery(hashed));

  useAsyncEffect(async () => {
    if (opts?.fetch !== false) {
      // TODO: Should this store IDs of the results and return them
      // Causes a race condition with infiite loading ....
      const { changed } = await getOptimizedForFilter(
        { type, scope: workspaceId },
        filter,
        opts
      );
      setItemsInStore(changed);
    }
  }, [hashed.toJSON()]); // Cached by hashable

  return localItems as EntityForType<T>[];
};

export const useLazyRelation = (relation: Maybe<RelationRef>) => {
  return (
    useLazyEntity(isInflated(relation) ? undefined : relation?.id, false) ||
    relation
  );
};

const getAllNestedEntities = async (
  entity: Entity,
  childTypes: EntityType[],
  since?: ISODate,
  onItems?: (items: Entity[]) => void
): Promise<EntityIDMap> => {
  const result: EntityIDMap = {};

  await mapAll(childTypes, async <T extends EntityType>(type: T) => {
    const items = await getItemsNestedWithinLoader(
      entity.id,
      { type: type as EntityType, scope: entity.source.scope },
      since,
      onItems
    );

    if (items.length) {
      result[type] = items;
    }
  });

  return result;
};

export const useNestedEntities = (
  entity: Maybe<Entity>,
  types?: EntityType[],
  skipCache?: boolean
) => {
  const loading = useRef(false);
  const [results, setResults] = useRecoilState(
    FetchResultsAtom(`${entity?.id}-nested`)
  );
  const [fetched, setFetched] = useState(
    skipCache ? false : !!results?.ids?.length
  );
  const { props, ready } = useProperties(entity?.source);
  const children = useItemsAsEntityMap(results?.ids);

  // TODO: Need to wait for children to be actually fetched before changing loading to be false
  useAsyncEffect(async () => {
    if (!entity || !ready || loading.current) {
      return;
    }

    loading.current = true;

    const items = await getAllNestedEntities(
      entity,
      types || toNestedTypes(props),
      results?.fetchedAt,
      setItemsInStore
    );

    setResults(updateFetchedResults(flatten(values(items))));
    setFetched(true);

    loading.current = false;
  }, [
    entity?.id,
    ready,
    useArrayKey(props, (p) => p.field),
    useArrayKey(types),
  ]);

  return useMemo(
    () => ({
      children: skipCache && !fetched ? undefined : children,
      loading: loading?.current || !fetched,
    }),
    [children, loading, ready, skipCache, fetched]
  );
};

export const useNestedEntitiesOfType = <T extends EntityType>(
  entity: Maybe<Entity>,
  childType: T
): { loading: boolean; children: Maybe<EntityForType<T>[]> } => {
  const [results, setResults] = useRecoilState(
    FetchResultsAtom(`${entity?.id}-nested-${childType}`)
  );
  const children = useLazyEntities(results?.ids, false);
  const [loading, setLoading] = useState(false);

  useAsyncEffect(async () => {
    if (!entity) {
      return;
    }

    setLoading(true);

    const items = await getItemsNestedWithinLoader(
      entity.id,
      { type: childType, scope: entity.source.scope },
      results?.fetchedAt,
      setItemsInStore
    );
    setResults(updateFetchedResults(items));

    setLoading(false);
  }, [entity?.id]);

  return useMemo(
    () => ({ children: children as EntityForType<T>[], loading }),
    [children, loading]
  );
};

export const useManyNestedEntities = (parents: Maybe<Entity[]>) => {
  const [results, setResults] = useRecoilState(
    FetchResultsAtom(`${map(parents, (p) => p?.id).join("-")}-nested`)
  );
  const [loading, setLoading] = useState(false);
  const props = useLazyProperties(parents?.[0]?.source);
  const propsStore = useRecoilValue(PropertyDefStoreAtom);
  const children = useItemsAsEntityMap(results?.ids);

  useAsyncEffect(async () => {
    // Props not really loaded yet
    if (props.length <= 1) {
      return;
    }

    setLoading(true);

    // Fetch nested children for every entity
    const ids = await mapAll(parents || [], async (parent) => {
      // SInce we just needd the props to find the child types, look at all scoped props for this entity.
      const props = maybeValues(propsStore.lookup, (p) =>
        isPropForEntity(p, parent.source.type)
      );
      const childs = await getAllNestedEntities(
        parent,
        toNestedTypes(props),
        results?.fetchedAt,
        setItemsInStore
      );
      return flatten(values(childs));
    });

    setResults(updateFetchedResults(flatten(ids)));
    setLoading(false);
  }, [useArrayKey(parents, (p) => p.id), useArrayKey(props, (p) => p.field)]);

  return useMemo(() => ({ children, loading }), [children, loading]);
};
