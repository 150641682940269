import { filter, map } from "lodash";
import { Fragment, useCallback, useMemo } from "react";

import { Status, Workflow, WorkflowStep } from "@api";

import { useLazyEntities } from "@state/generic";
import { isFinished } from "@state/workflow";

import { dependencySort } from "@utils/array";
import { when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";

import { HStack } from "@ui/flex";
import ProgressBar from "@ui/progress-bar";
import { Tooltip } from "@ui/tooltip";

import { WorkflowStepIcon } from "../workflow_step/icon";

import styles from "./styles.module.css";

export const WorkflowProgressBar = ({
  workflow,
  status,
  className,
}: {
  workflow: Workflow;
  status?: Status;
  className?: string;
}) => {
  const percent = useMemo(() => {
    if (status?.group === "done") {
      return 100;
    }

    const finished = workflow?.refs?.finished?.length;
    const total = workflow?.refs?.steps?.length;

    if (!total || !finished) {
      return 0;
    }

    return Math.round((finished / total) * 100);
  }, [workflow, status?.group]);

  return (
    <ProgressBar
      className={className}
      thickness={2}
      percent={percent}
      color="green"
    />
  );
};

export const WorkflowStepsBar = ({
  workflow,
  status,
}: {
  workflow: Workflow;
  status?: Status;
}) => {
  const pushTo = usePushTo();
  const finished = isFinished(workflow);
  const steps = useLazyEntities<"workflow_step">(workflow?.refs?.steps);
  const ordered = useMemo(
    () =>
      dependencySort(
        finished ? filter(steps, (s) => s?.status?.id === "FNS") : steps || [],
        (s) => map(s.refs?.blockedBy, (b) => b?.id)
      ),
    [steps, isFinished]
  );
  const handleStepClicked = useCallback(
    (e: React.MouseEvent, step: WorkflowStep) =>
      when(step.refs?.created?.[0], (r) => {
        e.preventDefault();
        pushTo(r);
        return false;
      }),
    [pushTo]
  );

  return (
    <HStack fit="container" gap={4} className={styles.stepsBar}>
      {/* Make the start always a little green for the feels */}
      <div style={{ width: "40px" }}>
        <ProgressBar thickness={2} percent={100} color={"green"} />
      </div>

      {map(ordered, (step, i) => (
        <Fragment key={step.id}>
          <Tooltip text={step.name} delay={0}>
            <WorkflowStepIcon
              step={step}
              size={16}
              onClick={(e) => handleStepClicked(e, step)}
            />
          </Tooltip>
          {i !== ordered.length - 1 && (
            <ProgressBar
              thickness={2}
              percent={step.status?.id === "FNS" ? 100 : 0}
              color={"green"}
            />
          )}
        </Fragment>
      ))}
    </HStack>
  );
};
