import { Task } from "@api";

import { useLazyPropertyValue } from "@state/databases";
import { useMe } from "@state/persons";

import { cx } from "@utils/class-names";
import { when } from "@utils/maybe";

import { DueDate } from "@ui/due-date";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween } from "@ui/flex";
import { Icon } from "@ui/icon";
import { Label } from "@ui/label";
import { SmartLocationLabel } from "@ui/location-button";
import { MenuItem } from "@ui/menu-item";
import { RelationIcon, StatusRelationIcon } from "@ui/relation-label";

import { MenuItemOpts } from "../types";

export const TaskMenuItem = ({
  item,
  onOpen,
  className,
  showProps,
  ...props
}: MenuItemOpts<Task>) => {
  const me = useMe();
  const status = useLazyPropertyValue(item, {
    field: "status",
    type: "status",
  });
  return (
    <EntityContextMenu entity={item}>
      <MenuItem
        className={cx(className)}
        {...props}
        onClick={() => onOpen?.(item)}
      >
        <SpaceBetween>
          <HStack gap={4}>
            <Label
              icon={<StatusRelationIcon relation={item} />}
              text={item.title}
            />
            <SmartLocationLabel
              showTeam={false}
              showIcons={false}
              size="small"
              subtle
              location={item.location}
            />
          </HStack>
          <HStack gap={4}>
            {when(item.start || item.end, (d) => (
              <DueDate date={d} status={status.value.status?.group} />
            ))}
            {item.assigned?.id !== me?.id && (
              <Icon
                size="small"
                icon={<RelationIcon relation={item.assigned} />}
              />
            )}
          </HStack>
        </SpaceBetween>
      </MenuItem>
    </EntityContextMenu>
  );
};
