import { useMemo } from "react";
import { EdgeTypes, NodeTypes } from "reactflow";

import { EDGE_TYPES, NODE_TYPES } from "./components";

export const useNodeTypes = (types: NodeTypes, deps: any[]) => {
  return useMemo(() => ({ ...NODE_TYPES, ...types }), deps);
};

export const useEdgeTypes = (types: EdgeTypes, deps: any[]) => {
  return useMemo(() => ({ ...EDGE_TYPES, ...types }), deps);
};
