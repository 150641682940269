import { map } from "lodash";
import { ReactNode, useMemo } from "react";

import { PropertyType, VariableDef } from "@api/types";

import { OneOrMany } from "@utils/array";
import { Fn } from "@utils/fn";
import { equalsAny } from "@utils/logic";
import { Maybe, maybeMap, when } from "@utils/maybe";

import { Button } from "@ui/button";
import { FillSpace, SpaceBetween } from "@ui/flex";
import { BracketsCurly, NoneIcon } from "@ui/icon";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { Select, SelectProps } from "@ui/select";
import { Text, TextSmall } from "@ui/text";

type PropSelectOption = {
  id: string;
  name: string;
  ref?: VariableDef;
};

export type Props = {
  options?: VariableDef[];
  value?: VariableDef;
  allowed?: OneOrMany<PropertyType>;
  portal?: boolean;
  onChange?: Fn<Maybe<VariableDef>, void>;
  className?: string;
} & Omit<
  SelectProps<PropSelectOption>,
  "value" | "options" | "className" | "onChange"
>;

const toOption = (v: VariableDef) => ({
  id: v.field,
  name: `@${v.field}`,
  icon: when(v.type, (type) => <PropertyTypeIcon type={type} />) ?? (
    <NoneIcon />
  ),
  ref: v,
});

export function VariableSelect({
  options,
  placeholder,
  value,
  caret,
  className,
  allowed,
  onChange,
  children,
  ...rest
}: Props) {
  const selected = useMemo(() => when(value, toOption), [value]);

  const menuOptions = useMemo(() => {
    return maybeMap(options, (o) =>
      !allowed || equalsAny(o.type, allowed) ? toOption(o) : undefined
    );
  }, [options]);

  return (
    <Select
      {...rest}
      value={selected}
      options={menuOptions}
      clearable
      onChange={(v) => onChange?.(v?.ref)}
    >
      {children || (
        <Button size="small" variant={!!value ? "ai" : "secondary"}>
          @
        </Button>
      )}
    </Select>
  );
}

export default VariableSelect;

export const WithVariableSelect = ({
  children,
  value,
  ...props
}: Props & { children: ReactNode }) => {
  const select = (
    <VariableSelect {...props} value={value}>
      <Button
        size="small"
        icon={BracketsCurly}
        variant={!!value ? "ai" : "secondary"}
        fit={!!value ? "container" : "content"}
      >
        {!!value && (
          <SpaceBetween>
            <Text color="inverse">{value.label}</Text>
            <TextSmall color="inverse">{value.field}</TextSmall>
          </SpaceBetween>
        )}
      </Button>
    </VariableSelect>
  );

  return (
    <SpaceBetween gap={4}>
      {children && !value && (
        <FillSpace direction="horizontal">{children}</FillSpace>
      )}
      {!!value ? <FillSpace>{select}</FillSpace> : select}
    </SpaceBetween>
  );
};
