import { intersection, isString, map, without } from "lodash";
import { useCallback, useMemo } from "react";

import { EntityType, SelectOption } from "@api";

import { useInstalledEntities } from "@state/packages";
import { useEntityLabels } from "@state/settings";

import { Fn } from "@utils/fn";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { toBaseScope } from "@utils/scope";

import { Box } from "@ui/icon";
import { MenuItem } from "@ui/menu-item";

import { MultiProps, MultiSelect } from "./multi-select";
import { Select, SelectProps } from "./single-select";

import styles from "./select.module.css";

type EntityTypeOption = SelectOption & { type: EntityType };

type EntitySelectProps = Omit<
  SelectProps<EntityTypeOption>,
  "value" | "options" | "onChange"
> & {
  value: Maybe<EntityType>;
  onChange: Fn<EntityType, void>;
  additional?: EntityType[];
  plural?: boolean;
  allowed?: EntityType[];
  exclude?: EntityType[];
  scope: string;
};

type EntityMultiSelectProps = Omit<
  MultiProps<EntityTypeOption>,
  "value" | "options" | "onChange"
> & {
  value: Maybe<EntityType[]>;
  onChange: Fn<EntityType[], void>;
  additional?: EntityType[];
  allowed?: EntityType[];
  exclude?: EntityType[];
  scope: string;
};

export const EntityTypeSelect = ({
  value,
  onChange,
  scope,
  placeholder,
  children,
  allowed,
  additional,
  exclude,
  portal = true,
  plural = true,
  ...props
}: EntitySelectProps) => {
  const entities = useInstalledEntities(
    useMemo(() => toBaseScope(scope), [scope])
  );
  const toTypeLabel = useEntityLabels(scope, { plural });
  const toTypeOption = useCallback(
    (t: EntityType) => ({
      id: t,
      name: toTypeLabel(t),
      type: t,
    }),
    [toTypeLabel]
  );

  const allowedRelations = useMemo(
    () =>
      map(
        allowed
          ? intersection(allowed, entities)
          : ([
              ...(additional || []),
              ...without(entities || [], ...(exclude || [])),
            ] as EntityType[]),
        toTypeOption
      ),
    [entities, toTypeOption]
  );

  return (
    <Select
      searchable={false}
      value={when(value, toTypeOption)}
      options={allowedRelations}
      toIcon={() => Box}
      portal={portal}
      {...props}
      onChange={(t) => t && onChange?.(t.type)}
    >
      {children || (
        <MenuItem icon={Box} className={styles.control}>
          {when(value, (t) => toTypeOption(t)?.name) ||
            placeholder ||
            "Choose relation..."}
        </MenuItem>
      )}
    </Select>
  );
};

export const EntityTypeMultiSelect = ({
  value,
  onChange,
  scope,
  children,
  additional,
  portal = true,
  exclude,
  ...props
}: EntityMultiSelectProps) => {
  const entities = useInstalledEntities(
    useMemo(() => toBaseScope(scope), [scope])
  );
  const toTypeLabel = useEntityLabels(scope, { plural: true });
  const toTypeOption = useCallback(
    (t: EntityType) => ({
      id: t,
      name: toTypeLabel(t),
      type: t,
    }),
    [toTypeLabel]
  );

  const allowedRelations = useMemo(
    () =>
      map(
        [
          ...(additional || []),
          ...without(entities || [], ...(exclude || [])),
        ] as EntityType[],
        toTypeOption
      ),
    [entities, toTypeOption]
  );

  return (
    <MultiSelect
      searchable={false}
      value={map(value, toTypeOption)}
      options={allowedRelations}
      toIcon={() => Box}
      portal={portal}
      {...props}
      onChange={(ts) => onChange?.(maybeMap(ts, (t) => t.type))}
    >
      {children || (
        <MenuItem
          icon={Box}
          className={
            isString(props?.className)
              ? props.className
              : props?.className?.trigger
          }
        >
          {((value?.length || 0) <= 3
            ? map(value, (t) => toTypeOption(t)?.name)?.join(", ")
            : `${value?.length} packages`) || "Choose relation..."}
        </MenuItem>
      )}
    </MultiSelect>
  );
};
